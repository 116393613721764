import authActions from "../auth/authActionCreator";
import appQuestionsActions from "../appQuestions/appQuestionsActionCreator";
import type { ApiResponse } from "../../../apiServices/request";
import type { SignUpData } from "../../../apiServices/endpoints/signUp";

// the outside "thunk creator" function
const signUp = (email: string,
	password: string) => {
	// the inside "thunk function"
	return async (dispatch: any, getState: any) => {
		const response: ApiResponse = await dispatch(authActions.signUp(
			email,
			password));
		const auth: SignUpData | null = response.data;
		// if not auth return
		if (!auth) return;

		// Load auth data.
		await dispatch(appQuestionsActions.signUp(auth.accessToken));
	}
};

export default signUp;