import { request }  from '../request';
import type { CoverLetterAIDraft } from '../dataTypes/ApiDataTypes';


interface Response {
  data: CoverLetterAIDraft | null;
  status: number;
  statusMessage: string;
};


const getCoverLetterAIDrafts = async function(draftId:number, accessToken: string): Promise<Response> {
  const endpoint = 'coverletteraidrafts/';
  const method = 'GET';
  const body = {};
  const headers = {
    'Authorization': `Token ${accessToken}`,
    'id': draftId // Quick fix: add id to CORS_ALLOW_HEADERS
  }
  const response: Response = await request(method, endpoint, body, headers);
  return response
};

const postCoverLetterAIDrafts = async function(coverLetterId:number, accessToken: string): Promise<Response> {
  const endpoint = 'coverletteraidrafts/';
  const method = 'POST';
  const headers = {
    'Authorization': `Token ${accessToken}`
  }
  const body = {
    'cover-letter-id': coverLetterId
  };

  const response: Response = await request(method, endpoint, body, headers);
  return response
};

const CoverLetterAIDrafts = {
  get: getCoverLetterAIDrafts,
  post: postCoverLetterAIDrafts
  // post
  // put
  // delete
}

export default CoverLetterAIDrafts;