import { request }  from '../request';
import type { JobDescription } from '../dataTypes/ApiDataTypes';


interface Response {
  data: JobDescription | null;
  status: number;
  statusMessage: string;
};


const postJobDescription = async function(
    orgName: string,
    titleName: string,
    requirements: string,
    responsibilities: string
): Promise<Response> {
  const endpoint = 'jobdescriptions/';
  const method = 'POST';
  const body = {
    'title-name':titleName,
    'org-name':orgName,
    'requirements':requirements,
    'responsibilities': responsibilities
  }
  const headers = {};

  const response: Response = await request(method, endpoint, body, headers);
  return getResponseWithUserFriendlyErrorMessage(response);
};

const getMissingFieldsMessage = (statusMessage: string): string => {
  // Translate API param names to user-facing form field names.
  let missingFields = []
  if (statusMessage.includes('title-name')) missingFields.push('Job Title');
  if (statusMessage.includes('org-name')) missingFields.push('Organization');
  if (statusMessage.includes('requirements')) missingFields.push('Requirements');
  if (statusMessage.includes('responsibilities')) missingFields.push('Responsibilities');

  // Create user-facing error message,
  // including all the missing form field names.
  let missingFieldsMessage = 'Please check that you have filled out the following fields:'
  missingFields.map((field, currentIndex):void => {
    if (currentIndex !== 0) {
      missingFieldsMessage = `${missingFieldsMessage},`;
    }
    missingFieldsMessage = `${missingFieldsMessage} ${field}`;
  });
  return missingFieldsMessage;
}

const getResponseWithUserFriendlyErrorMessage = function (response: Response): Response {
  let userFriendlyError = '';
  // If no error.
  userFriendlyError = (
      response.status === 200 ?
      '' :
      userFriendlyError);
  // If bearer token not set under Authorization in the request header.
  userFriendlyError = (
      response.status === 401 ?
      'Oops, we hit a snag! Please try that again. If the issue persists, contact casey@crystallogiclabs.com with Resumakr Error Code: 550.' :
      userFriendlyError);
  // If API endpoint not found
  userFriendlyError = (
      response.status === 404 ?
      'Oops, we hit a snag! Please try that again. If the issue persists, contact casey@crystallogiclabs.com with Resumakr Error Code: 551.' :
      userFriendlyError);
  // If API endpoint not found
  userFriendlyError = (
      response.status === 500 ?
      'Oops, network error! Please check your internet connection and try again. If the issue persists, contact casey@crystallogiclabs.com with Resumakr Error Code: 552.' :
      userFriendlyError);
  // If missing required field in form.
  userFriendlyError = (
      response.status === 400 ?
      getMissingFieldsMessage(response.statusMessage):
      userFriendlyError);
  const userFriendlyResponse = {
    data: response.data,
    status: response.status,
    statusMessage: userFriendlyError
  };
  return userFriendlyResponse;
};

const JobDescriptions = {
  post: postJobDescription
  // post
  // put
  // delete
}

export default JobDescriptions;