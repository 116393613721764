import React from 'react';
import { Function } from '../../../typescriptTypes';
import './textButton.css';

interface TextButtonProps {
    fontClass: string;
    textColorClass: string;
    label: string;
    onClick: Function;
  }
  
const TextButton = (props: TextButtonProps) => {
      const {
        fontClass,
        textColorClass,
        label,
        onClick
      } = props;


		return (
			<div className={`${fontClass} ${textColorClass} textButton`} onClick={onClick} >{label}</div>
		);

};


export default TextButton;
