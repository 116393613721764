import { useRef, useEffect, useState } from 'react';
import BasicButton from '../../common/BasicButton/BasicButton';
import CLLShortTextBox from '../CLLShortTextBox/CLLShortTextBox';
import CLLLongTextBox from '../CLLLongTextBox/CLLLongTextBox';
import CLLSearchableDropdown, { Option } from '../CLLSearchableDropdown/CLLSearchableDropdown';
import CLLErrorMessage from '../CLLErrorMessage/CLLErrorMessage';
import { Function } from '../../../typescriptTypes';
import { useAppDispatch, useAppSelector } from '../../../state/redux/hooks';
import currentJDActions from '../../../state/redux/data/currentJD/currentJDActionCreator';
import type { Title } from '../../../state/apiServices/dataTypes/Title';
import type { Org } from '../../../state/apiServices/dataTypes/Org';
import type { JobDescription } from '../../../state/apiServices/dataTypes/JobDescription';
import "./jdForm.css";

interface OnChangeFunction {
	/* return errorMessage. if no errorMessage return empty string '' */
    (value: any, hasActiveError?: boolean): any;
}

interface OnSubmitFunction {
	/* return errorMessage. if no errorMessage return empty string '' */
    (): any;
}

interface InputValues {
	onSubmit: OnSubmitFunction;
	submitLabel?: string;
}

const JDForm = (props: InputValues) => {
	const titlesToOptions = (titles: Title[]): Option[] => {
		const titleOptions: Option[] = titles.map((title) => {
			return {
				id: title.pk,
				label: title.fields.name
			};
		});
		return titleOptions;
	}
	const orgsToOptions = (orgs: Org[]): Option[] => {
		const orgOptions: Option[] = orgs.map((org) => {
			return {
				id: org.pk,
				label: org.fields.name
			};
		});
		return orgOptions;
	}

	// const counter = useRef(0)
	// console.log(`JDForm rerenders:${counter.current++}`)

	useEffect(() => {

	}, [ ])

	const dispatch = useAppDispatch();
	// props
	const { submitLabel, onSubmit } = props;
	// GLOBAL STATE
	// redux
	const currentJD = useAppSelector(state => state.currentJD);
	const titles: Title[] = useAppSelector(state => state.titles.data);
	const orgs: Org[] = useAppSelector(state => state.orgs.data);
	// LOCAL state (component)
	// component state - FORM DROP DOWNS
	const initTitleOptions: Option[] = [];
	const [titleOptions, setTitleOptions] = useState(
		initTitleOptions);
	const initOrgOptions: Option[] = [];
	const [orgOptions, setOrgOptions] = useState(
		initOrgOptions);
	// component state - FORM FIELDS
	// const [niceToHaves, setNiceToHaves] = useState('');
	const [requirements, setRequirements] = useState('');
	const [responsibilities, setResponsibilities] = useState('');
	const [org, setOrg] = useState('');
	const [title, setTitle] = useState('');
	// component state - ERROR MESSAGING & SUBMIT FUNCTIONALITY
	const [errorMessage, setErrorMessage] = useState('');
	const [submitClicked, setSubmitClicked] = useState(false);
	const submitDisabled = !(
		requirements &&
		responsibilities &&
		org &&
		title);
	// const [submitDisabled, setSubmitDisabled] = useState(true);
	// const initFieldsWithErrors: string[] = [];
	// const [fieldsWithErrors, setFieldsWithErrors] = useState(initFieldsWithErrors);
	// const [missingRequiredFields, setMissingRequiredFields] = useState(true);


	// useEffect(() => {
	// 	const jdUpdatePending = currentJD.status === 'pending';
	// 	const hasActiveErrors = fieldsWithErrors.length > 0;
	// 	const updatedSubmitDisabled = (
	// 		missingRequiredFields ||
	// 		jdUpdatePending ||
	// 		hasActiveErrors
	// 	)
	// 	console.log(`submitDisabled: ${submitDisabled}`);
	// 	console.log(missingRequiredFields);
	// 	console.log(jdUpdatePending);
	// 	console.log(hasActiveErrors);
	// 	console.log(fieldsWithErrors)
	// 	console.log(responsibilities);
	// 	setSubmitDisabled(updatedSubmitDisabled);
	// }, [missingRequiredFields, fieldsWithErrors])

	// lifecycle methods:
	useEffect(() => {
		// Update local dropdown from global title state
		if (!titles) return;
		const newTitleOptions: Option[] = titlesToOptions(titles);
		setTitleOptions(newTitleOptions);
	}, [ titles ])

	useEffect(() => {
		// Update local dropdown from global org state
		if (!orgs) return;
		const newOrgOptions: Option[] = orgsToOptions(orgs);
		setOrgOptions(newOrgOptions);

	}, [ orgs ])

	useEffect(() => {
		// Update local state when global jd state updates

		// display error message on failure.
		if (currentJD.status === 'failure') {
			if (currentJD.errorMessage === 'Failed to connect to server.'){
				setErrorMessage("We're sorry about that! Submission failed, please try again.");
			}
			setErrorMessage(currentJD.errorMessage || '');
		}
		// execute onSubmit if submitClicked and update is successful.
		if (submitClicked && currentJD.status === 'success') {
			setSubmitClicked(false);
			onSubmit();
		}
		// if global state update is successful and data is populated,
		// update local state to match.
		const updateLocalFromGlobalState = (currentJD: JobDescription) => {
			// Update local form data from global state
			setTitle(currentJD.title.name);
			setOrg(currentJD.org.name);
			setRequirements(currentJD.requirements);
			setResponsibilities(currentJD.responsibilities);
			// // if existing jd, should not be missing required fields
			// setMissingRequiredFields(false);

		}

		if (currentJD.data && currentJD.status === 'success') {
			updateLocalFromGlobalState(currentJD.data);

		}

	}, [currentJD])


	// const onChangeHook = (updateState: Function) => {
	// 	const onChange: OnChangeFunction = (value: string, hasActiveError?: boolean) => {
	// 		if (!hasActiveError) updateState(value);
	// 		// const fieldIdentifier = updateState.name;

	// 		// updateFieldsWithErrorsState(fieldIdentifier, hasActiveError);
	// 		// updateMissingRequiredFieldsState();
	// 	}
	// 	return onChange;
	// }
	// const onSelectHook = (updateState: Function) => {
	// 	// CASEY - THIS MIGHT NOT BE SETTING VALUES
	// 	// WHICH IS PREVENTING SUBMIT BUTTON FROM BEING ENABLED
	// 	const onChange: OnChangeFunction = (options: Option[], hasActiveError?: boolean) => {
	// 		if (!hasActiveError) updateState(options);
	// 		// const fieldIdentifier = updateState.name;
	// 		// updateState(options);
	// 		// updateFieldsWithErrorsState(fieldIdentifier, hasActiveError);
	// 		// updateMissingRequiredFieldsState();
	// 	}
	// 	return onChange;
	// }
	const handleSubmit = () => {
		if (submitDisabled || currentJD.status === 'pending') return;
		setSubmitClicked(true);
		setErrorMessage('');
		dispatch(currentJDActions.post(
			org,
			title,
			requirements,
			responsibilities
		))
		// useEffect will handle from here.

	};


	return (
		<div className='signInFormWrapper'>
			<div className='signInFormHeader headlineLarge'>Cover Letter Generator</div>
			<div className='signInFormSubheader bodyLarge'>Enter the job posting information below to get started.</div>
			<br></br>
			<CLLSearchableDropdown
				label='Organization'
				subLabel='Enter the name of the employer'
				input={org}
				onChange={setOrg}
				addOptions={true}
				options={orgOptions}
			/>
			<br></br>
			<CLLSearchableDropdown
				label='Job title'
				subLabel='Enter the job title'
				input={title}
				onChange={setTitle}
				addOptions={true}
				options={titleOptions}
			/>
			<br></br>
			<CLLLongTextBox
				label='Job requirements'
				subLabel='Copy & paste text from the job description'
				onChange={setRequirements}
				// minLength={100}
				maxLength={100000}
				rows={5}
				initValue={requirements}
			/>
			<br></br>
			<CLLLongTextBox
				label='Job responsibilities'
				subLabel='Copy & paste text from the job description'
				onChange={setResponsibilities}
				// minLength={100}
				maxLength={100000}
				rows={5}
				initValue={responsibilities}
			/>
			<br></br>
			<BasicButton
				label={submitLabel || 'Submit'}
				onClick={handleSubmit}
				className=""
				type="primary"
				disabled={submitDisabled || currentJD.status === 'pending'}/>

			<CLLErrorMessage errorMessage={errorMessage} />
		</div>
	);
};

export default JDForm;