import orgsSlice from "./orgsSlice";
import { updateStateByAPI } from "../actionCreatorUtils";
import Orgs from "../../../apiServices/endpoints/orgs";


// the outside "thunk creator" function
const getOrgs = () => {
  // the inside "thunk function"
  return async (dispatch: any, getState: any) => {
    // update status to pending so loaders start.
    updateStateByAPI(
      dispatch,
      // get orgs (will be updated often so don't save locally)
      async() => await Orgs.get(),
      orgsSlice.actions.setOrgsStarted,
      orgsSlice.actions.setOrgsSucceeded,
      orgsSlice.actions.setOrgsFailed
    );
  }
};

const orgsActions = {
    get: getOrgs
}

export default orgsActions;