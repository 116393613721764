import authActions from "../auth/authActionCreator";
import appQuestionsActions from "../appQuestions/appQuestionsActionCreator";
import currentCoverLetterActions from "../currentCoverLetter/currentCoverLetterActionCreator";
import currentJDActions from "../currentJD/currentJDActionCreator";

// the outside "thunk creator" function
const signOut = () => {
	// the inside "thunk function"
	return async (dispatch: any, getState: any) => {
		dispatch(authActions.signOut());
		dispatch(appQuestionsActions.signOut());
		dispatch(currentCoverLetterActions.signOut());
		dispatch(currentJDActions.signOut());
	}
};

export default signOut;