import React, { useState } from 'react';
import { Function } from '../../../typescriptTypes';
import CLLDropdown, { Option } from '../CLLDropdown/CLLDropdown';
import '../../globalStyles/responsiveLayout/spacers.css';
import '../../globalStyles/colors/classes.css';
import '../../globalStyles/fonts/classes.css';
import './experiencesForm.css';
import PositionsModal from '../PositionsModal/PositionsModal';
import CLLShortTextBox from '../CLLShortTextBox/CLLShortTextBox';
import SuccessModal from '../PositionsModal/SuccessModal';
import BasicButton from '../../common/BasicButton/BasicButton';

interface OnChangeFunction {
    /* return errorMessage. if no errorMessage return empty string '' */
    (value: any, hasActiveError?: boolean): any;
}

interface OnSubmitFunction {
    /* return errorMessage. if no errorMessage return empty string '' */
    (): any;
}

interface ExperienceFormProps {
    onSubmit?: OnSubmitFunction;
    submitLabel?: string;
    selectedSkills: any[];
    onBackButtonClick: () => void; // Add the prop for back button click handler
    onNextButtonClick: () => void; // Add the prop for next button click handler
}

interface OptionWithFormattedStartDate extends Option {
    formattedStartDate: Date;
}

const ExperiencesForm: React.FC<ExperienceFormProps> = (props) => {
    const [positionsModalVisible, setPositionsModalVisible] = useState(false);
    const [selectedPosition, setSelectedPosition] = useState('');
    const [options, setOptions] = useState<OptionWithFormattedStartDate[]>([]);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [deletedPositions, setDeletedPositions] = useState<Option[]>([]);
    const [description, setDescription] = useState('');
    const [submitDisabled, setSubmitDisabled] = useState(true);


    const onChangeHook = (updateState: Function, hasActiveError?: boolean) => {
        const onChange: OnChangeFunction = (value: string) => {
            if (!hasActiveError) updateState(value);
            updateState(value);
            setSubmitDisabled(value.trim() === '');
        };
        return onChange;
    };

    const togglePositionsModal = () => {
        setPositionsModalVisible(!positionsModalVisible);
    };

    const handleAddPosition = () => {
        setPositionsModalVisible(true);
    };

    const handleDescriptionChange = (value: string) => {
        setDescription(value);
        setSubmitDisabled(value.trim() === '');
    };
      

    const handleSavePosition = (
        position: string,
        organization: string,
        startDate: string,
        endDate: string
    ) => {
        const formattedStartDate = new Date(startDate);
        let formattedEndDate: Date | "Present" = endDate === "" ? "Present" : new Date(endDate);

        const newOption: OptionWithFormattedStartDate = {
            id: position,
            label: `${position} at ${organization}  —  ${formattedStartDate.toLocaleDateString(
                "en-US",
                {
                    month: "2-digit",
                    year: "numeric",
                }
            )} to ${formattedEndDate === "Present"
                ? "Present"
                : formattedEndDate.toLocaleDateString("en-US", {
                    month: "2-digit",
                    year: "numeric",
                })}`,
            formattedStartDate: formattedStartDate,
        };

        setOptions([...options, newOption]);
        setSelectedPosition(position);

        // Show success modal 
        setShowSuccessModal(true);

        setTimeout(() => {
            setShowSuccessModal(false);
        }, 10000);

        togglePositionsModal();
    };

    const handleDeletePosition = (option: Option) => {
        if (selectedPosition === option.label) {
            setSelectedPosition('');
        }

        setDeletedPositions((prevPositions) => [...prevPositions, option]);
        setOptions((prevOptions) => prevOptions.filter((opt) => opt.id !== option.id));
    };
      
    const handleSuccessModalClose = () => {
        // Close the success modal
        setShowSuccessModal(false);
    };
    
    const handleReopenPositionsModal = () => {
        // Re-open the positions modal
        setPositionsModalVisible(true);
        setShowSuccessModal(false);
    };
      

    return (
        <>
            <div className='signInFormWrapper'>
                <div className='signInFormHeader headlineLarge'>Professional Experience</div>
                <div className='signInFormSubheader bodyLarge'>Qualify the skills selected on the previous page by matching them to your professional experiences</div>
                <br></br>
                {props.selectedSkills.map((selectedSkill, index, maxLength) => (
                    <div className='skillsWrapper'>
                        <React.Fragment key={index}>
                            <div className='subheader'>{selectedSkill}</div>
                            <br></br>
                            <CLLDropdown
                                label={`Position`}
                                subLabel={`Select the position in which you best demonstrated your experience in ${selectedSkill}.`}
                                options={[
                                ...options.sort(
                                    (a, b) => b.formattedStartDate.getTime() - a.formattedStartDate.getTime()
                                ),
                                ]}
                                selectedOption={selectedPosition}
                                onChange={(option) => setSelectedPosition(option.label)}
                                showAddPositionsButton={true}
                                onAddPosition={handleAddPosition}
                                onDeletePosition={handleDeletePosition}
                            />
                            <br></br>
                            <CLLShortTextBox
                                label={`Experience`}
                                subLabel={`Describe your ${selectedSkill} experience in 90 characters or less.`}
                                onChange={onChangeHook(handleDescriptionChange)}
                                placeholder={`e.g. I used ${selectedSkill} practices to...`}
                                autoCapitalize='none'
                                maxLength={90}
                                minLength={12}
                            />
                            {index !== props.selectedSkills.length - 1 && <br></br>} {/* Add the condition to insert <br></br> between fragments */}
                        </React.Fragment>
                    </div>
                ))}
                <br></br>
                <br></br>
                <div className='buttonWrapper'>
                    <BasicButton
                        label='Back'
                        onClick={props.onBackButtonClick}
                        className='grow'
                        type='secondary'
                    />
                    <BasicButton
                        label='Next'
                        onClick={props.onNextButtonClick}
                        className='grow'
                        type='primary'
                        disabled={submitDisabled}
                    />
                </div>
            </div>
            {positionsModalVisible && (
                <div className='positionsModalOverlay visible'>
                    <PositionsModal onSubmit={handleSavePosition} onCancel={togglePositionsModal} />
                </div>
            )}
            {showSuccessModal && (
                <div className='positionsModalOverlay visible'>
                    <SuccessModal 
                        onClose={handleSuccessModalClose}
                        onReopenPositionsModal={handleReopenPositionsModal}
                    />
                </div>
            )}
        </>
    );
};

export default ExperiencesForm;
