import React, { useState, useEffect } from 'react';
import BasicButton from '../../common/BasicButton/BasicButton';
import CLLEmailBox from '../CLLEmailBox';
import CLLPasswordBox from '../CLLPasswordBox';
// import CLLShortTextBox from '../CLLShortTextBox/CLLShortTextBox';
import CLLCheckbox from '../CLLCheckbox/CLLCheckbox';
import { Function } from '../../../typescriptTypes';
import { useAppDispatch, useAppSelector } from '../../../state/redux/hooks';
import signUp from '../../../state/redux/data/globalActionCreators/signUp';
import CLLErrorMessage from '../CLLErrorMessage/CLLErrorMessage';
import '../../globalStyles/responsiveLayout/spacers.css';
import '../../globalStyles/colors/classes.css';
import '../../globalStyles/fonts/classes.css';
import './signUpForm.css';
// import { request } from '../../state/apiServices/request';

interface OnChangeFunction {
	/* return errorMessage. if no errorMessage return empty string '' */
    (value: any, hasActiveError?: boolean): any;
}


const SignUpForm = () => {
	const dispatch = useAppDispatch();
	const auth = useAppSelector(state => state.auth);

	const [email, setEmail] = useState('');
	const [password1, setPassword1] = useState('');
	const [password2, setPassword2] = useState('');
	const [agreedTerms, setAgreedTerms] = useState(false);
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	/* submit should be disabled until all required fields are filled */
	const submitDisabled = !(email && password1 && password2 && password1 === password2 && agreedTerms);

	useEffect(() => {
		if (auth.status === 'pending') {
			return setLoading(true);
		}
		if (auth.status === 'failure') {
			setLoading(false);
			if (auth.errorMessage === 'Failed to connect to server.'){
				return setErrorMessage("We're sorry about that! Submission failed, please try again.");
			}
			return setErrorMessage(auth.errorMessage || '');
		}
		if (auth.status === 'success') {
			return setLoading(false);
		}
		return;
		// useEffect is called when auth redux state changes.
	}, [ auth ])

	const onChangeHook = (setStateFunc: Function) => {
		const onChange: OnChangeFunction = (value: string, hasActiveError?: boolean) => {
			const validValueEmpty = (hasActiveError || value.length === 0) ? '' : value;
			setStateFunc(validValueEmpty);
		}
		return onChange;
	}
	const handleSubmit = async function() {
		if (submitDisabled) return;
		dispatch(signUp(email, password1))
		// useEffect will handle error messages etc. from here.
	};
	return (
		<div className='signInFormWrapper'>
			{/* <CLLShortTextBox
				label='First Name'
				placeholder='Tony'
				onChange={onChangeHook(setFirstName)}
				oneWord={true}
				minLength={2}
				maxLength={50}
				autoCapitalize='words'
			/>
			<br></br>
			<CLLShortTextBox
				label='Last Name'
				placeholder='Soprano'
				onChange={onChangeHook(setLastName)}
				oneWord={true}
				minLength={2}
				maxLength={50}
				autoCapitalize='words'
			/>
			<br></br>
			<CLLShortTextBox
				label='LinkedIn'
				placeholder='https://www.linkedin.com/in/your-name'
				onChange={onChangeHook(setLinkedIn)}
				oneWord={true}
				minLength={'https://www.linkedin.com/in/'.length + 3}
				maxLength={100}
				autoCapitalize='none'
				initValue='https://www.linkedin.com/in/'
				includedValues={['https://www.linkedin.com/in/',]}
			/> */}
			<br></br>
			<CLLEmailBox onChange={onChangeHook(setEmail)} />
			<br></br>
			{/* CASEY: ADD ANOTHER PASSWORD BOX QND CHECK THAT THE TWO ARE EQUAL BEFORE SUBMIT IS ENABLED*/}
			<CLLPasswordBox
				isError={password1 !== password2}
				label="Password"
				onChange={setPassword1} />
			<br></br>
			<CLLPasswordBox
				isError={password1 !== password2}
				label="Re-Enter Password"
				onChange={setPassword2} />
			<br></br>
			<CLLCheckbox
				label={<>By checking this box you are confirming that you are over 18 years of age, a citizen of the USA or Canada, and consent to Resumakr storing your data.</>}
				onChange={(value) => setAgreedTerms(value)}
				id='signUpForTermsCheckbox'
			/>
			{/* <CLLCheckbox
				label={<>By checking this box you are agreeing to our <a className='signUpFormTermsLink txtColor--brand' href="/">terms & conditions</a>.</>}
				onChange={(value) => setAgreedTerms(value)}
				id='signUpForTermsCheckbox'
			/> */}
			<CLLErrorMessage errorMessage={
				errorMessage || password1 === password2 ? "" : "Passwords must match."}  />
			<BasicButton
				label="Sign Up"
				onClick={handleSubmit}
				className=""
				type="primary"
				disabled={submitDisabled || loading} />

		</div>
	);
};

export default SignUpForm;