import { configureStore } from '@reduxjs/toolkit';
import authSlice from './data/auth/authSlice';
import colorModeSlice from './data/colorMode/colorModeSlice';
import appQuestionsSlice from './data/appQuestions/appQuestionsSlice';
import currentCoverLetterSlice from './data/currentCoverLetter/currentCoverLetterSlice';
import currentJDSlice from './data/currentJD/currentJDSlice';
import titlesSlice from './data/titles/titlesSlice';
import orgsSlice from './data/orgs/orgsSlice';

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    colorMode: colorModeSlice.reducer,
    appQuestions: appQuestionsSlice.reducer,
    currentCoverLetter: currentCoverLetterSlice.reducer,
    currentJD: currentJDSlice.reducer,
    titles: titlesSlice.reducer,
    orgs: orgsSlice.reducer
  }
});
export default store;

// Used for hooks, etc.
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {auth: AuthState, etc.}
export type AppDispatch = typeof store.dispatch;

