import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../state/redux/hooks';
import signIn from '../../../state/redux/data/globalActionCreators/signIn';
import BasicButton from '../../common/BasicButton/BasicButton';
import CLLEmailBox from '../../forms/CLLEmailBox';
import CLLPasswordBox from '../../forms/CLLPasswordBox';
import CLLErrorMessage from '../CLLErrorMessage/CLLErrorMessage';
import './signInForm.css';
// import { request } from '../../state/apiServices/request';

interface OnChangeFunction {
	/* return errorMessage. if no errorMessage return empty string '' */
    (value: any, hasActiveError?: boolean): any;
}


const SignInForm = () => {
    const dispatch = useAppDispatch();
	const auth = useAppSelector(state => state.auth);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const submitDisabled = !(email && password);

    useEffect(() => {
		if (auth.status === 'pending') {
			return setLoading(true);
		}
		if (auth.status === 'failure') {
			setLoading(false);
			if (auth.errorMessage === 'Failed to connect to server.'){
				return setErrorMessage("We're sorry about that! Submission failed, please try again.");
			}
			return setErrorMessage(auth.errorMessage || '');
		}
		if (auth.status === 'success') {
			return setLoading(false);
		}
		return;
		// useEffect is called when auth redux state changes.
	}, [ auth ])
    
    const onEmailChange: OnChangeFunction = (value: string, hasActiveError?: boolean) => {
        const validEmailOrEmpty = (hasActiveError || value.length === 0) ? '' : value;
        setEmail(validEmailOrEmpty);
    }
    const onPasswordChange: OnChangeFunction = (value: string, hasActiveError?: boolean) => {
        const validPasswordOrEmpty = (hasActiveError || value.length === 0) ? '' : value;
        setPassword(validPasswordOrEmpty);
    }
    const handleSubmit = async function() {
        if (submitDisabled) return;
        await dispatch(signIn(
			email,
			password
		))
        // handle error messages etc in useEffect method.
    };
    return (
        <div className='signInFormWrapper'>  
             <CLLEmailBox 
              onChange={onEmailChange}
              hideErrors={true}/>
            <br></br>
            <CLLPasswordBox 
              onChange={onPasswordChange}
              hideErrors={true}/>
            <br></br>
            <BasicButton label="Sign In" onClick={handleSubmit} className="" type="primary" disabled={submitDisabled || loading}/>
            <CLLErrorMessage errorMessage={errorMessage} />
        </div>
    );
};

export default SignInForm;