import React, { useState, useEffect } from 'react';
import './skillsSelector.css';
import '../../globalStyles/responsiveLayout/spacers.css';
import '../../globalStyles/colors/classes.css';
import '../../globalStyles/fonts/classes.css';
import '../../globalStyles/forms/classes.css';

interface Option {
    id: number | string | null;
    value: string;
    label: string;
}

interface SkillsSelectorProps extends InputValues {
    maxOptions?: number;
    minOptions?: number;
    disabledOptions?: string[];
    selectedOptions?: string[];
}

interface InputValues {
    onChange: (selectedValues: string[]) => void;
    id: string;
    label: string;
    subLabel: string;
    options: Option[]; // Array of options for selection
    defaultValue?: string[];
}

const SkillsSelector: React.FC<SkillsSelectorProps> = ({
    onChange,
    label,
    subLabel,
    id,
    options,
    defaultValue = [],
    maxOptions,
    minOptions,
    disabledOptions,
    selectedOptions,
}) => {
    const [selectedValues, setSelectedValues] = useState<string[]>(selectedOptions || defaultValue);
    

    const handleChange = (value: string) => {
        const isSelected = selectedValues.includes(value);
        let updatedValues: string[];

        if (isSelected) {
            updatedValues = selectedValues.filter((item) => item !== value);
        } else {
            updatedValues = [...selectedValues, value];
        }

        if (
            (maxOptions && updatedValues.length > maxOptions) ||
            (minOptions && updatedValues.length < minOptions)
        ) {
            return; // Do not update the state if the conditions are not met
        }

        setSelectedValues(updatedValues);
        onChange(updatedValues);
    };

    const isOptionDisabled = (value: string) => {
        return (
          maxOptions !== undefined &&
          selectedValues.length >= maxOptions &&
          !selectedValues.includes(value)
        );
    };

    useEffect(() => {
        if (maxOptions && selectedValues.length > maxOptions) {
          const remainingOptions = selectedValues.slice(maxOptions);
          setSelectedValues((prevSelectedValues) =>
            prevSelectedValues.filter((value) => !remainingOptions.includes(value))
          );
        }
    }, [maxOptions, selectedValues]);
    

    return (
        <>
            <div className="formsTextInputWrapper">
                <div className="labelSmall txtColor--textPrimary">{label || ''}</div>
                <div className="formsQuestionSubLabel footnote txtColor--textSecondary">
                    {subLabel || ''}
                </div>
            </div>
            <div className="skillsSelectorWrapper">
                {options.map((option) => (
                    <div
                        key={option.value}
                        className={`skillsSelectorOption ${selectedValues.includes(option.value) ? 'active' : ''}`}
                    >
                        <label
                            htmlFor={`${id}-${option.value}`}
                            className={`skillsLabel bodyLarge ${
                            disabledOptions?.includes(option.value) ? 'disable' : ''} ${isOptionDisabled(option.value) ? 'disable' : ''}`}
                        >
                            <input
                                id={`${id}-${option.value}`} // Update the id attribute to include the option value
                                type="checkbox"
                                checked={selectedValues.includes(option.value)}
                                onChange={() => handleChange(option.value)}
                                disabled={isOptionDisabled(option.value)}
                            />
                            {option.label}
                        </label>
                    </div>
                ))}
            </div>
        </>
    );
};

export default SkillsSelector;
