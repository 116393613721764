import './satisfactionButtonImg.css';

// bad, neutral, good

function BadImg() {
  
  return (
    <div className="satisfactionButtonImg--wrapper">
      <svg
      width="48" 
      height="48" 
      viewBox="0 0 48 48" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      className="satisfactionButtonImg--svg"
      >
          <path
            className="satisfactionButtonImg--path"
            d="M32.5 32C32.5 32 29.5 28 24.5 28C19.5 28 16.5 32 16.5 32M34.5 18.48C33.71 19.45 32.63 20 31.5 20C30.37 20 29.32 19.45 28.5 18.48M20.5 18.48C19.71 19.45 18.63 20 17.5 20C16.37 20 15.32 19.45 14.5 18.48M44.5 24C44.5 35.0457 35.5457 44 24.5 44C13.4543 44 4.5 35.0457 4.5 24C4.5 12.9543 13.4543 4 24.5 4C35.5457 4 44.5 12.9543 44.5 24Z" 
            stroke="black" 
            stroke-width="4" 
            stroke-linecap="round" 
            stroke-linejoin="round"
          />
      </svg>
    </div>
  );

};

export default BadImg;
