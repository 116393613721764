import React, { useState, useRef, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../state/redux/hooks';
import signOut from '../../../state/redux/data/globalActionCreators/signOut';
import '../../globalStyles/fonts/classes.css';
import '../../globalStyles/colors/classes.css';
import './hamburgerMenu.css';
import ModeToggler from '../ModeToggler/ModeToggler';
import '../ModeToggler/modeToggler.css';
import TextButton from '../TextButton/TextButton';
import HamburgerMenuIcon from './HamburgerMenuIcon';

interface HamburgerMenuProps {
    style?: React.CSSProperties;
}

const HamburgerMenuNew: React.FC<HamburgerMenuProps> = ({ style }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.auth.data?.accessToken);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => {
    setIsAnimating(true);
    setTimeout(() => setIsOpen(!isOpen), 200);
    setTimeout(() => setIsAnimating(false), 200);
  };

  const handleAuthButtonClick = () => {
    if (accessToken) dispatch(signOut());
  };

  const getMenuClassNames = () => {
    let classNames = ["hamburger-menu-menu"];
    if (isOpen) {
      classNames.push("hamburger-menu-menu--open");
    }
    if (isAnimating) {
      classNames.push("hamburger-menu-menu--animating");
    }
    return classNames.join(" ");
  };


  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className="hamburger-menu" style={style}>
    <div className='hamburger-icon-container'>
        <HamburgerMenuIcon isOpen={isOpen} isAnimating={isAnimating} toggleMenu={toggleMenu} />
    </div>
      <div ref={dropdownRef} className={getMenuClassNames()}>
        <ul className="hamburger links labelLarge">
          <li className="navbar linkWrapper">
            <a href="#home-cover-letter-generator" onClick={toggleMenu}>
              Get Started
            </a>
          </li>
          <li className="navbar linkWrapper">
            <a href="#home-why-resumakr" onClick={toggleMenu}>
              Learn More
            </a>
          </li>
          <li className="navbar linkWrapper">
            <a href="https://forms.gle/gUtJ9Q9k8kiTFZLP6" target='_blank' onClick={toggleMenu}>
              Contact Us
            </a>
          </li>
          <li className="navbar linkWrapper">
            <TextButton
              fontClass="labelLarge"
              textColorClass="txtColor--textPrimary"
              onClick={handleAuthButtonClick}
              label={accessToken ? 'Sign Out' : ''}
            />
          </li>
          <li className="navbar linkWrapper">
            <ModeToggler />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default HamburgerMenuNew;
