import React, {useState} from 'react';
import { useAppDispatch, useAppSelector } from '../../../state/redux/hooks';
import signOut from '../../../state/redux/data/globalActionCreators/signOut';
import '../../globalStyles/fonts/classes.css';
import '../../globalStyles/colors/classes.css';
import './hamburgerMenu.css';
import ModeToggler from '../ModeToggler/ModeToggler';
import TextButton from '../TextButton/TextButton';

const HamburgerMenu = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);
    const dispatch = useAppDispatch();
	const accessToken = useAppSelector(state => state.auth.data?.accessToken);

    const toggleMenu = () => {
        setIsAnimating(true);
            setTimeout(() => setIsOpen(!isOpen), 200);
            setTimeout(() => setIsAnimating(false), 200);
    };

    const handleAuthButtonClick = () => {
		if (accessToken) dispatch(signOut());
	}

    const getMenuIconClassNames = () => {
        let classNames = ["hamburger-menu-icon"];
        if (isOpen) {
          classNames.push("hamburger-menu-icon--open");
        }
        return classNames.join(" ");
    };

    const getMenuLineClassNames = () => {
        let classNames = ["hamburger-menu-icon-line"];
        if (isOpen) {
          classNames.push("hamburger-menu-icon-line--open");
        }
        if (isAnimating) {
          classNames.push("hamburger-menu-icon-line--animating");
        }
        return classNames.join(" ");
    };

    const getMenuClassNames = () => {
        let classNames = ["hamburger-menu-menu"];
        if (isOpen) {
          classNames.push("hamburger-menu-menu--open");
        }
        if (isAnimating) {
          classNames.push("hamburger-menu-menu--animating");
        }
        return classNames.join(" ");
    };

    return (
        <div className="hamburger-menu">
            <div className={getMenuIconClassNames()} onClick={toggleMenu}>
                <div className={getMenuLineClassNames()}></div>
                <div className={getMenuLineClassNames()}></div>
                <div className={getMenuLineClassNames()}></div>
            </div>
            <div
                className={`hamburger-menu-menu ${
                    isOpen? 'hamburger-menu-menu--open' : ''
                } ${isAnimating? 'hamburger-menu-menu--animating' : ''}`}
            >
                <ul className='hamburger links labelLarge'>
					<li className='navbar linkWrapper'>
						<a href='#home-cover-letter-generator' onClick={toggleMenu}>
							Get Started
						</a>
					</li>
					<li className='navbar linkWrapper'>
						<a href='#home-why-resumakr' onClick={toggleMenu}>
							Learn More
						</a>
					</li>
					<li className='navbar linkWrapper'>
						<a href="https://forms.gle/gUtJ9Q9k8kiTFZLP6" target='_blank' onClick={toggleMenu}>
							Contact Us
						</a>
					</li>
                    <li className='navbar linkWrapper'>
					    <ModeToggler />
                    </li>
                    <li className='navbar linkWrapper'>
                        <TextButton
                            // fontClass='labelLarge'
                            // textColorClass='txtColor--brand'
                            fontClass='labelLarge'
                            textColorClass='txtColor--textPrimary'
                            onClick={handleAuthButtonClick}
                            label={accessToken? 'Sign Out' : 'test'}
                        />
                    </li>
					{/* <LinkButton
						className='navbar buttons item'
						label={'Login'}
						type='primary'
						href=''
					/> */}
				</ul>
            </div>
        </div>
    )
};

export default HamburgerMenu;