import { request }  from '../request';
import type { Org } from '../dataTypes/ApiDataTypes';


interface GetOrgsApiResponse {
  data: Org[] | null;
  status: number;
  statusMessage: string;
};


const getOrgs = async function(): Promise<GetOrgsApiResponse> {
  const endpoint = 'organizations/';
  const method = 'GET';
  const body = {};
  const headers = {};
  const response: GetOrgsApiResponse = await request(method, endpoint, body, headers);
  return response
};

const Orgs = {
  get: getOrgs,
  // post
  // put
  // delete
}

export default Orgs;


