import { useRef, useState, useEffect, useLayoutEffect } from 'react';
import ReviewTextEditor from '../../../../common/ReviewTextEditor/ReviewTextEditor';
import { useAppDispatch, useAppSelector } from '../../../../../state/redux/hooks';
import currentCoverLetterActions from '../../../../../state/redux/data/currentCoverLetter/currentCoverLetterActionCreator';
import '../../../../globalStyles/responsiveLayout/grids.css';
import '../../../../globalStyles/fonts/classes.css';
import '../../../../globalStyles/colors/classes.css';
import '../../../../globalStyles/responsiveLayout/spacers.css'
import './coverLetterEditor.css';
import TypewriterLoader from '../../../../common/TypewriterLoader/TypewriterLoader';
import CLLRequirementsChecklist from '../../../../forms/CLLRequirementsChecklist/CLLRequirementsChecklist';


interface FunctionNoInputs {
  (): any;
}

const CoverLetterEditor = (props: {
  onNewCoverLetter: FunctionNoInputs,
  onRegenerate: FunctionNoInputs,
}) => {
  // const counter = useRef(0)
  // console.log(`CoverLetterEditor rerenders:${counter.current++}`)

  const { onNewCoverLetter, onRegenerate } = props;

  // Global state:
  const dispatch = useAppDispatch();
  const auth = useAppSelector(state => state.auth);
  const currentCoverLetter = useAppSelector(state => state.currentCoverLetter);

  // Component state:
  // current screen is just for testing. final version will use stepNum.
  // const [hideSatisfactionRating, setHideSatisfactionRating] = useState(false);
  const [editorContent, setEditorContent] = useState('');

  useEffect(() => {
      const newEditorContent = ((
          currentCoverLetter.data &&
          currentCoverLetter.data.ai_final_draft) ||
          "We're sorry, something funky is going on! Please try generating your cover letter again."
      ).replaceAll('\n', '<br>');
      setEditorContent(newEditorContent);
  }, [ currentCoverLetter.data?.ai_final_draft ])


  useEffect(() => {
      return () => {
          // on unmount save user edited draft
          saveLocalEditsToDB();
      };
  }, []);


  const onSelectedRating = async (type: 'bad' | 'neutral' | 'good') => {
      // CASEY: DISABLING RATING FOR NOW -- REMOVING ANY FRICTION
      // TO USER FLOW.

      // setHideSatisfactionRating(true);
      // let rating: 'GOOD' | 'BAD' | 'OK' = type === 'bad' ? 'BAD' : 'OK';
      // rating = type === 'good' ? 'GOOD' : rating;
      // const userEditedDraft = undefined;
      // // TODO dispatch action creator to POST rating
      // if (!auth.data || !currentCoverLetter.data) return;
      // await dispatch(currentCoverLetterActions.updateCoverLetter(
      //     auth.data.accessToken,
      //     currentCoverLetter.data.id,
      //     userEditedDraft,
      //     rating
      // ))
  }

  const onContentChange = (userEditedDraft: string) => {
      saveUserEditedDraftLocally(userEditedDraft);
  }


  const onContentDownload = () => {
      saveLocalEditsToDB();
  }


  const saveUserEditedDraftLocally = async(content: string) => {
      // update local storage
      const coverLetter = currentCoverLetter.data;
      if (!coverLetter) return;
      await dispatch(currentCoverLetterActions.saveUserEditsLocally(
          coverLetter,
          content
      ));
  }

  const saveLocalEditsToDB = () => {
      // save to db
      const authData = auth.data;
      if (!authData ) return;
      dispatch(currentCoverLetterActions.updateUserEditedDraft(
          authData.accessToken
      ));
  }

  return (
    <>
      {currentCoverLetter.status === 'pending' ?
        <div className="coverLetterEditor--loader">
            <TypewriterLoader isLoading={true} label={"Great news! Your cover letter should be complete in 3 minutes. Please don't refresh the page."} />
        </div> :
        <>

          {// Don't show requirements checklist if
            // error message is displayed as editor content.
            editorContent.length > 150 ?
            <>
              <CLLRequirementsChecklist
                category='CoverLetter'
              />
              <br />
            </>  : <></>
          }

          <ReviewTextEditor
            initContent={editorContent}
            hideSatisfactionRating={true}
            // hideSatisfactionRating={hideSatisfactionRating}
            onSelectedRating={onSelectedRating}
            onNewCoverLetter={onNewCoverLetter}
            onContentChange={onContentChange}
            onContentDownload={onContentDownload}
            onRegenerate={onRegenerate}
          />
        </>
      }
    </>
  );
};

export default CoverLetterEditor;