import React from 'react';
import '../../globalStyles/responsiveLayout/spacers.css';
import '../../globalStyles/colors/classes.css';
import '../../globalStyles/fonts/classes.css';
import '../../globalStyles/forms/classes.css';
import './cLLRequirementsChecklist.css';
import '../../globalStyles/responsiveLayout/containers.css';
import '../../globalStyles/responsiveLayout/grids.css';
import { CheckVerified, CheckVerifiedFill } from '../../common/IconLibrary/IconLibrary';

interface InputValues {
    category: 'CoverLetter' | 'Resume';
}

const CLLRequirementsChecklist = (props: InputValues) => {
    const { category } = props;
    const coverLetterChecklist = {
        header: 'Edit and submit with confidence!',
        subheader: 'Your cover letter meets expert best practices.',
        labels:
            [
                'Strong Opening Statement',
                'Succinct & Skimmable',
                'Addresses Employer Goals',
                'Shows Enthusiasm & Culture Fit',
                'Compliments Resume Content',
                'Sells Your Unique Value'
            ]
    }
    const resumeChecklist = {
        header: 'Edit and submit with confidence!',
        subheader: 'Your resume meets expert best practices.',
        labels:
            [
                'Includes Top Skills',
                'Exemplifies Applied Skills',
                'ATS-Optimized Keywords',
                'Contains Measurable Accomplishments',
                'Under 1000 Words',
                'ATS-Optimized Formatting'
            ]
    }
    const selectedCategory = category === 'CoverLetter' ? coverLetterChecklist : resumeChecklist;
    const shuffledLabels = shuffleArray(selectedCategory.labels);

    function shuffleArray(array: string[]) {
        const shuffled = [...array];
        for (let i = shuffled.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
        }
        return shuffled;
    }

    return (
        <>
            <div className='signInFormHeader headlineLarge'>{selectedCategory.header}</div>
            <div className='signInFormSubheader bodyLarge'>{selectedCategory.subheader}</div>
            <div className='requirementsChecklist--wrapper' >
                <div className='gridContainer'>
                    {selectedCategory.labels.map((label, index) => (
                        <div className='gridItem oneHalf'>
                            <div className='requirementsChecklist--item' key={index}>
                                <div className='requirementsChecklist--icon'>
                                    <CheckVerifiedFill
                                        iconFill='var(--brand)'
                                        iconColor='var(--bgPrimary)'
                                    />
                                </div>
                                <div className='labelSmall'>
                                    {label}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default CLLRequirementsChecklist;


