
import React, { useEffect, useState } from 'react';
import TextEditor, {
  OnContentChangeFunction,
  OnContentDownloadFunction } from '../TextEditor/TextEditor';
import BasicButton from '../BasicButton/BasicButton';
import SatisfactionRating from '../SatisfactionRating/SatisfactionRating';
import './reviewTextEditor.css';


interface OnClickFunction {
  (type: 'bad' | 'neutral' | 'good'): any;
}

interface FunctionNoInputs {
  (): any;
}

interface EditorProps {
  hideSatisfactionRating: boolean;
  onSelectedRating: OnClickFunction;
  onContentChange: OnContentChangeFunction;
  onContentDownload?: OnContentDownloadFunction;
  className?: string;
  initContent?: string;
  ratingLabel?: string;
  onNewCoverLetter?: FunctionNoInputs;
  onRegenerate?: FunctionNoInputs;
}

const ReviewTextEditor = (props: EditorProps ) => {
  const {
    initContent,
    onSelectedRating,
    onContentChange,
    onContentDownload,
    ratingLabel,
    className,
    hideSatisfactionRating,
    onNewCoverLetter,
    onRegenerate
  } = props;



  const doNothing = () => { };


  return (
    <div className={className || ''}>
      <TextEditor
        readOnly={hideSatisfactionRating ? false : true}
        initContent={initContent}
        onContentChange={onContentChange || doNothing}
        onContentDownload={onContentDownload || doNothing}/>
      <br />
      {hideSatisfactionRating ?
        <div className='reviewTextEditor--buttons'>

          <BasicButton
            className='grow'
            label='New Cover Letter'
            onClick={onNewCoverLetter || doNothing}
            type='primary' />
          <BasicButton
            className='grow'
            label='Re-Generate This Cover Letter'
            onClick={onRegenerate || doNothing}
            type='secondary' />
        </div> :
        <>
          <SatisfactionRating
            label={ratingLabel || "How satisfied are you with your results?"}
            onClick={onSelectedRating}
            className='reviewTextEditor--satisfactionRating'
          />
        </>
      }
    </div>
  );
};

export default ReviewTextEditor;

// className: string;
//   label: any;
//   onClick: any;
//   disabled?: boolean;
//   type: 'primary' | 'secondary' | 'tertiary';