import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { Org } from '../../../apiServices/dataTypes/Org';
// import { RootState } from '../../store';


// Define intiial state
interface OrgsState {
  data: Org[]; // changing this from 'data: Org[] | null' might cause errors.
  status: 'pending' | 'failure' | 'success';
  errorMessage: string | null;
};
const initOrgsData: Org[] = [];
const initialState: OrgsState = {
  data: initOrgsData,
  errorMessage: null,
  status: 'success'
};

// Define slice using intitial state
const orgsSlice = createSlice({
  name: 'orgs',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setOrgsStarted: (prevState) => {
      let state = prevState;
      state.errorMessage = null;
      state.status = 'pending';
      return state;
    },
    setOrgsFailed: (prevState) => {
      let state = prevState;
      state.errorMessage = 'Failed to connect to server.';
      state.status = 'failure';
      return state;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    setOrgsSucceeded: (prevState, action: PayloadAction<OrgsState>) => {
      return action.payload;
    },
  },
});

export default orgsSlice;

// Other code such as selectors can use the imported `RootState` type
// export const selectAuthState = (state: RootState) => state.auth;