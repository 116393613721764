import React, { useState } from 'react';
import SignInForm from '../../forms/SignInForm/SignInForm';
import SignUpForm from '../../forms/SignUpForm/SignUpForm';
import TextButton from '../../common/TextButton/TextButton';
import '../../globalStyles/responsiveLayout/grids.css';
import '../../globalStyles/fonts/classes.css';
import '../../globalStyles/colors/classes.css';
import '../../globalStyles/responsiveLayout/spacers.css'
import './signInUpForm.css';
// import { request } from '../../state/apiServices/request';


const SignInUpForm = () => {
    const [showSignUp, setShowSignUp] = useState(true);
    // current screen is just for testing. final version will use stepNum.

    return (
        <div >
            { showSignUp  === true ? <SignUpForm /> : <SignInForm /> }
            <div className='spacerVertical' />
            <div className='signInUpFormButton--wrapper'>
                <div className='labelLarge'>
                    {showSignUp ? 'Already have an account?' : "Don't have an account?"}
                </div>
                <TextButton
                    fontClass='labelLarge'
                    textColorClass='txtColor--brand'
                    onClick={() => setShowSignUp(!showSignUp)}
                    label={showSignUp? 'Sign In' : 'Sign Up'}
                />
            </div>
        </div>
    );
};

export default SignInUpForm;