import titlesSlice from "./titlesSlice";
import { updateStateByAPI } from "../actionCreatorUtils";
import Titles from "../../../apiServices/endpoints/titles";


// the outside "thunk creator" function
const getTitles = () => {
  // the inside "thunk function"
  return async (dispatch: any, getState: any) => {
    // update status to pending so loaders start.
    updateStateByAPI(
      dispatch,
      // get titles (will be updated often so don't save locally)
      async() => await Titles.get(),
      titlesSlice.actions.setTitlesStarted,
      titlesSlice.actions.setTitlesSucceeded,
      titlesSlice.actions.setTitlesFailed
    );
  }
};

const titlesActions = {
    get: getTitles
}

export default titlesActions;