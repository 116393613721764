import titlesActions from "../titles/titlesActionCreator";
import orgsActions from "../orgs/orgsActionCreator";
import authActions from "../auth/authActionCreator";
import appQuestionsActions from "../appQuestions/appQuestionsActionCreator";
import currentJDActions from "../currentJD/currentJDActionCreator";
import currentCoverLetterActions from "../currentCoverLetter/currentCoverLetterActionCreator";
import colorModeActions from "../colorMode/colorModeActionCreator";
import type { ApiResponse } from "../../../apiServices/request";


// the outside "thunk creator" function
const loadWebApp = () => {
	// the inside "thunk function"
	return async (dispatch: any, getState: any) => {
		// 1. GET NON-AUTH DATA.
		dispatch(colorModeActions.initIsLightMode());
		dispatch(titlesActions.get());
		dispatch(orgsActions.get());

		// 2. GET AUTH.
		// get /set auth -> TODO: reload if expired once expiration functionality added...
		const response: ApiResponse = await dispatch(authActions.initAuth());
		dispatch(currentJDActions.loadWebApp());
		// if not auth return
		if (response.status !== 200) return;

		// 3. GET AUTH DATA IF AUTH SET.
		// get or set appQuestions - reload every 24 hours
		dispatch(appQuestionsActions.initAppQuestions(response.data.accessToken));
		dispatch(currentCoverLetterActions.loadWebApp());

	}
};

export default loadWebApp;