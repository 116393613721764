import React from 'react';
import './iconLibrary.css';


interface IconProps {
    iconColor?: 'primary' | 'secondary' | 'tertiary' | 'error' | 'success' | string;
    iconFill?: string;
}

export const PlusCircle: React.FC<IconProps> = ({ iconColor }) => (
    <svg className='icon' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 8V16M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke={iconColor ? `var(--${iconColor})` : 'currentColor'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export const Plus: React.FC<IconProps> = ({ iconColor }) => (
    <svg className='icon' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 5V19M5 12H19" stroke={iconColor ? `var(--${iconColor})` : 'currentColor'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export const Save: React.FC<IconProps> = ({ iconColor }) => (
    <svg className='icon' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 3V6.4C7 6.96005 7 7.24008 7.10899 7.45399C7.20487 7.64215 7.35785 7.79513 7.54601 7.89101C7.75992 8 8.03995 8 8.6 8H15.4C15.9601 8 16.2401 8 16.454 7.89101C16.6422 7.79513 16.7951 7.64215 16.891 7.45399C17 7.24008 17 6.96005 17 6.4V4M17 21V14.6C17 14.0399 17 13.7599 16.891 13.546C16.7951 13.3578 16.6422 13.2049 16.454 13.109C16.2401 13 15.9601 13 15.4 13H8.6C8.03995 13 7.75992 13 7.54601 13.109C7.35785 13.2049 7.20487 13.3578 7.10899 13.546C7 13.7599 7 14.0399 7 14.6V21M21 9.32548V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3H14.6745C15.1637 3 15.4083 3 15.6385 3.05526C15.8425 3.10425 16.0376 3.18506 16.2166 3.29472C16.4184 3.4184 16.5914 3.59135 16.9373 3.93726L20.0627 7.06274C20.4086 7.40865 20.5816 7.5816 20.7053 7.78343C20.8149 7.96237 20.8957 8.15746 20.9447 8.36154C21 8.59171 21 8.8363 21 9.32548Z" stroke={iconColor ? `var(--${iconColor})` : 'currentColor'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export const Search: React.FC<IconProps> = ({ iconColor }) => (
    <svg className="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21 21L15.0001 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z" stroke={iconColor ? `var(--${iconColor})` : 'currentColor'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export const Trash: React.FC<IconProps> = ({ iconColor }) => (
    <svg className='icon' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 3H15M3 6H21M19 6L18.2987 16.5193C18.1935 18.0975 18.1409 18.8867 17.8 19.485C17.4999 20.0118 17.0472 20.4353 16.5017 20.6997C15.882 21 15.0911 21 13.5093 21H10.4907C8.90891 21 8.11803 21 7.49834 20.6997C6.95276 20.4353 6.50009 20.0118 6.19998 19.485C5.85911 18.8867 5.8065 18.0975 5.70129 16.5193L5 6M10 10.5V15.5M14 10.5V15.5" stroke={iconColor ? `var(--${iconColor})` : 'currentColor'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export const ArrowRight: React.FC<IconProps> = ({ iconColor }) => (
    <svg className='icon' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke={iconColor ? `var(--${iconColor})` : 'currentColor'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export const ArrowLeft: React.FC<IconProps> = ({ iconColor }) => (
    <svg className='icon' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 12H4M4 12L10 18M4 12L10 6" stroke={iconColor ? `var(--${iconColor})` : 'currentColor'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export const ArrowUp: React.FC<IconProps> = ({ iconColor }) => (
    <svg className='icon' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 20V4M12 4L6 10M12 4L18 10" stroke={iconColor ? `var(--${iconColor})` : 'currentColor'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export const ArrowDown: React.FC<IconProps> = ({ iconColor }) => (
    <svg className='icon' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 4V20M12 20L18 14M12 20L6 14" stroke={iconColor ? `var(--${iconColor})` : 'currentColor'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export const AlertTriangle: React.FC<IconProps> = ({ iconColor }) => (
    <svg className='icon' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.9998 8.99999V13M11.9998 17H12.0098M10.6151 3.89171L2.39019 18.0983C1.93398 18.8863 1.70588 19.2803 1.73959 19.6037C1.769 19.8857 1.91677 20.142 2.14613 20.3088C2.40908 20.5 2.86435 20.5 3.77487 20.5H20.2246C21.1352 20.5 21.5904 20.5 21.8534 20.3088C22.0827 20.142 22.2305 19.8857 22.2599 19.6037C22.2936 19.2803 22.0655 18.8863 21.6093 18.0983L13.3844 3.89171C12.9299 3.10654 12.7026 2.71396 12.4061 2.58211C12.1474 2.4671 11.8521 2.4671 11.5935 2.58211C11.2969 2.71396 11.0696 3.10655 10.6151 3.89171Z" stroke={iconColor ? `var(--${iconColor})` : 'currentColor'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

export const CheckVerified: React.FC<IconProps> = ({ iconColor }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 12L11 14L15.5 9.5M9.2019 20.6009C9.52965 20.5575 9.86073 20.6464 10.1218 20.8475L11.3251 21.7708C11.7228 22.0764 12.2761 22.0764 12.6727 21.7708L13.9215 20.812C14.1548 20.6331 14.4492 20.5542 14.7403 20.5931L16.3024 20.7986C16.799 20.8642 17.2779 20.5875 17.4701 20.1242L18.0712 18.6709C18.1834 18.3987 18.3989 18.1832 18.6711 18.0709L20.1243 17.4698C20.5876 17.2787 20.8643 16.7988 20.7987 16.3021L20.601 14.7966C20.5576 14.4688 20.6465 14.1377 20.8476 13.8766L21.7709 12.6733C22.0764 12.2755 22.0764 11.7222 21.7709 11.3256L20.812 10.0767C20.6332 9.84339 20.5543 9.54896 20.5932 9.25785L20.7987 7.69568C20.8643 7.19902 20.5876 6.72015 20.1243 6.52793L18.6711 5.92684C18.3989 5.81462 18.1834 5.59907 18.0712 5.32685L17.4701 3.87356C17.279 3.41024 16.799 3.13358 16.3024 3.19913L14.7403 3.40468C14.4492 3.44468 14.1548 3.36579 13.9226 3.18802L12.6738 2.22916C12.2761 1.92361 11.7228 1.92361 11.3262 2.22916L10.0774 3.18802C9.84407 3.36579 9.54965 3.44468 9.25856 3.40691L7.69647 3.20136C7.19984 3.1358 6.721 3.41246 6.52879 3.87578L5.92884 5.32907C5.81552 5.60018 5.59998 5.81573 5.32889 5.92906L3.87568 6.52904C3.41238 6.72126 3.13574 7.20013 3.20129 7.69679L3.40683 9.25897C3.4446 9.55007 3.36572 9.8445 3.18796 10.0767L2.22915 11.3256C1.92362 11.7233 1.92362 12.2767 2.22915 12.6733L3.18796 13.9222C3.36683 14.1555 3.44571 14.4499 3.40683 14.741L3.20129 16.3032C3.13574 16.7999 3.41238 17.2787 3.87568 17.471L5.32889 18.0721C5.60109 18.1843 5.81663 18.3998 5.92884 18.672L6.5299 20.1253C6.721 20.5887 7.20096 20.8653 7.69758 20.7998L9.2019 20.6009Z" stroke={iconColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
)

export const CheckVerifiedFill: React.FC<IconProps> = ({ iconColor, iconFill }) => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.2019 19.6009C8.52965 19.5575 8.86073 19.6464 9.12182 19.8475L10.3251 20.7708C10.7228 21.0764 11.2761 21.0764 11.6727 20.7708L12.9215 19.812C13.1548 19.6331 13.4492 19.5542 13.7403 19.5931L15.3024 19.7986C15.799 19.8642 16.2779 19.5875 16.4701 19.1242L17.0712 17.6709C17.1834 17.3987 17.3989 17.1832 17.6711 17.0709L19.1243 16.4698C19.5876 16.2787 19.8643 15.7988 19.7987 15.3021L19.601 13.7966C19.5576 13.4688 19.6465 13.1377 19.8476 12.8766L20.7709 11.6733C21.0764 11.2755 21.0764 10.7222 20.7709 10.3256L19.812 9.07672C19.6332 8.84339 19.5543 8.54896 19.5932 8.25785L19.7987 6.69568C19.8643 6.19902 19.5876 5.72015 19.1243 5.52793L17.6711 4.92684C17.3989 4.81462 17.1834 4.59907 17.0712 4.32685L16.4701 2.87356C16.279 2.41024 15.799 2.13358 15.3024 2.19913L13.7403 2.40468C13.4492 2.44468 13.1548 2.36579 12.9226 2.18802L11.6738 1.22916C11.2761 0.923613 10.7228 0.923613 10.3262 1.22916L9.07738 2.18802C8.84407 2.36579 8.54965 2.44468 8.25856 2.40691L6.69647 2.20136C6.19984 2.1358 5.721 2.41246 5.52879 2.87578L4.92884 4.32907C4.81552 4.60018 4.59998 4.81573 4.32889 4.92906L2.87568 5.52904C2.41238 5.72126 2.13574 6.20013 2.20129 6.69679L2.40683 8.25897C2.4446 8.55007 2.36572 8.8445 2.18796 9.07672L1.22915 10.3256C0.923617 10.7233 0.923617 11.2767 1.22915 11.6733L2.18796 12.9222C2.36683 13.1555 2.44571 13.4499 2.40683 13.741L2.20129 15.3032C2.13574 15.7999 2.41238 16.2787 2.87568 16.471L4.32889 17.0721C4.60109 17.1843 4.81663 17.3998 4.92884 17.672L5.5299 19.1253C5.721 19.5887 6.20096 19.8653 6.69758 19.7998L8.2019 19.6009Z" fill={iconFill}/>
        <path d="M8 11L10 13L14.5 8.5M8.2019 19.6009C8.52965 19.5575 8.86073 19.6464 9.12182 19.8475L10.3251 20.7708C10.7228 21.0764 11.2761 21.0764 11.6727 20.7708L12.9215 19.812C13.1548 19.6331 13.4492 19.5542 13.7403 19.5931L15.3024 19.7986C15.799 19.8642 16.2779 19.5875 16.4701 19.1242L17.0712 17.6709C17.1834 17.3987 17.3989 17.1832 17.6711 17.0709L19.1243 16.4698C19.5876 16.2787 19.8643 15.7988 19.7987 15.3021L19.601 13.7966C19.5576 13.4688 19.6465 13.1377 19.8476 12.8766L20.7709 11.6733C21.0764 11.2755 21.0764 10.7222 20.7709 10.3256L19.812 9.07672C19.6332 8.84339 19.5543 8.54896 19.5932 8.25785L19.7987 6.69568C19.8643 6.19902 19.5876 5.72015 19.1243 5.52793L17.6711 4.92684C17.3989 4.81462 17.1834 4.59907 17.0712 4.32685L16.4701 2.87356C16.279 2.41024 15.799 2.13358 15.3024 2.19913L13.7403 2.40468C13.4492 2.44468 13.1548 2.36579 12.9226 2.18802L11.6738 1.22916C11.2761 0.923613 10.7228 0.923613 10.3262 1.22916L9.07738 2.18802C8.84407 2.36579 8.54965 2.44468 8.25856 2.40691L6.69647 2.20136C6.19984 2.1358 5.721 2.41246 5.52879 2.87578L4.92884 4.32907C4.81552 4.60018 4.59998 4.81573 4.32889 4.92906L2.87568 5.52904C2.41238 5.72126 2.13574 6.20013 2.20129 6.69679L2.40683 8.25897C2.4446 8.55007 2.36572 8.8445 2.18796 9.07672L1.22915 10.3256C0.923617 10.7233 0.923617 11.2767 1.22915 11.6733L2.18796 12.9222C2.36683 13.1555 2.44571 13.4499 2.40683 13.741L2.20129 15.3032C2.13574 15.7999 2.41238 16.2787 2.87568 16.471L4.32889 17.0721C4.60109 17.1843 4.81663 17.3998 4.92884 17.672L5.5299 19.1253C5.721 19.5887 6.20096 19.8653 6.69758 19.7998L8.2019 19.6009Z" stroke={iconColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
)


export default IconProps;