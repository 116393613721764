import React from 'react';
import { Helmet } from 'react-helmet';
import { useLayoutEffect } from 'react';
import '../globalStyles/responsiveLayout/containers.css';
import { useAppDispatch } from '../../state/redux/hooks';
import loadWebApp from '../../state/redux/data/globalActionCreators/loadWebApp';
// import loadWebApp from '../../state/redux/data/globalActionCreators/loadWebApp';
import Home from '../pages/Home/Home';
import './app.css';
import '../globalStyles/colors/vars.css';
import DesignSystem from '../pages/DesignSystem/DesignSystem';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/design-system",
    element: <DesignSystem />
  },
  {
    path: "/",
    element: <Home />

  },
]);


const App = () => {
  const dispatch = useAppDispatch();
  // use hooks to dispatch from useLayoutEffect function:
  const dispatchLoadWebApp = () => dispatch(loadWebApp());

  useLayoutEffect(() => {
    // runs before first render
    // pull data from local storage on app load.
    dispatchLoadWebApp();
  }, [])

  return (
    <>
      <Helmet>
        <title>Resumakr</title>
        <meta
          name='description'
          content="Generate optimized job applications in minutes with Resumakr. Match your work experience with job descriptions to get hired faster."
        />  
        <meta property='og:url' content='https://www.resumakr.com/' />
        <meta property='og:type' content='website' />
        <meta property='og:site_name' content='Resumakr' />
        <meta name='twitter:card' content='summary' />
        <meta name='twitter:site' content='@resumakr' />
        <meta name='twitter:title' content='Resumakr' />
        <meta 
          name='twitter:description'
          content='Generate optimized job applications in minutes with Resumakr. Match your work experience with job descriptions to get hired faster.'
        />
      </Helmet>
      <RouterProvider router={router} />
    </>
  );

}

export default App;






