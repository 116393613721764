import React, { useState, useEffect } from 'react';
import './satisfactionButton.css';
import BadImg from './assets/BadImg';
import NeutralImg from './assets/NeutralImg';
import GoodImg from './assets/GoodImg';


interface OnClickFunction {
	(type: 'bad' | 'neutral' | 'good'): any;
}


// bad, neutral, good

function SatisfactionButton(props: {onClick: OnClickFunction, type: 'bad' | 'neutral' | 'good'}) {
	const [isAnimating, setIsAnimating] = useState(false);

	const handleClick = () => {
		setIsAnimating(true);
		setTimeout(() => {
			setIsAnimating(false);
			props.onClick(props.type);
		}, 1000);
	};

	const {
		type
	} = props;
  
	return (
		<div 
			className={`satisfactionButton--wrapper ${isAnimating ? 'animate' : ''}`}
			onClick={handleClick}
		>
			<div className='satisfactionButton--content'>
				{(type==='bad') ? <BadImg/> : <></>}
				{(type==='neutral') ? <NeutralImg/> : <></>}
				{(type==='good') ? <GoodImg/> : <></>}
			</div>
		</div>
	);

};

export default SatisfactionButton;