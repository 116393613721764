import { useRef, useState, useEffect } from 'react';
import SignInUpForm from '../../../forms/SignInUpForm/SignInUpForm';
import StepLoader from '../../../common/StepLoader/StepLoader';
import CoverLetterInfoForm from '../../../forms/CoverLetterInfoForm/CoverLetterInfoForm';
import { useAppDispatch, useAppSelector } from '../../../../state/redux/hooks';
import newApplication from '../../../../state/redux/data/globalActionCreators/newApplication';
import JDForm from '../../../forms/JDForm/JDForm';
import '../../../globalStyles/responsiveLayout/grids.css';
import '../../../globalStyles/fonts/classes.css';
import '../../../globalStyles/colors/classes.css';
import '../../../globalStyles/responsiveLayout/spacers.css';
import './coverLetterModal.css';
import CoverLetterEditor from './CoverLetterEditor/CoverLetterEditor';


const CoverLetterModal = () => {

    // Global state:
    const dispatch = useAppDispatch();
    const auth = useAppSelector(state => state.auth);
    const currentCoverLetter = useAppSelector(state => state.currentCoverLetter);

    // Component state:
    // current screen is just for testing. final version will use stepNum.
    const [currentStep, setCurrentStep] = useState(0);
    const [isRegenerating, setIsRegenerating] = useState(false);
    const [componentDidMount, setComponentDidMount] = useState(false);

    // Set steps for step loader:
    const steps = ['Enter job description', 'Input your experience', 'Review, edit, and send!'];

    // // Test content
    // const exContent: string = `Dear Hiring Team at TED,\n\nI have the experience and skills necessary to help create high-quality, user-focused products that capitalize on user and business value. Excited to hone my product management, communication, user-centered design, strategic thinking, analytical skills and technology proficiency with this role as a Product Manager or Senior Product Manager of Growth at TED, I'm looking forward to making a tangible impact on real user and business value.\n\nDrawing on my 10+ years of experience in product design and development, I can bring excellent communication skills and an executive presence to the role, as well as a proven track record of building exceptional products that have delighted users and produced clear business impact. My proficiency with the product development lifecycle and tools of the trade, combined with my strategic and analytical mindset and user-centered approach, allow me to learn new technologies and product spaces quickly. Additionally, I am passionate about TED's mission and values, while my flexible current location allows me to work U.S. East Coast hours and travel to TED's Manhattan office as needed. My goal is to build high-quality, user-focused products that deliver real user and business value.\n\nI'm excited to discuss how my 10+ years of product design and development experience can help TED achieve this goal.\n\nSincerely,\nTony Soprano\n`;

    // const counter = useRef(0)
    // console.log(`CoverLetterModal rerenders:${counter.current++}`)

	useEffect(() => {

				if (componentDidMount) {
						// If component has mounted and current step has changed,
						// scroll to top of component.
						scrollToTop();

				} else {
						// If component mounting for the first time:

						// If cover letter exists, show cover letter editor.
						if (currentCoverLetter.data &&
							currentCoverLetter.data.ai_final_draft) {
							setCurrentStep(2);

						} else {
							// else record that component has mounted for the first time.
							setComponentDidMount(true);
						}
				}
    }, [ currentStep, currentCoverLetter ])

    const setNextStep = () => {
        const nextStepIndex = currentStep + 1;
        setCurrentStep(nextStepIndex);

        // TODO NAVIGATE TO TOP OF SECTION;
    }

    const scrollToTop = () => {
        // console.log("<<<<<<<<<<<<<< SCROLL TO TOP >>>>>>>>>>>>>>>>>>");
        window.location.assign("/#cover-letter-modal");
    }

    const onNewCoverLetter = () => {
        // scrollToTop();
        const authData = auth.data;
        const accessToken = authData ? authData.accessToken : undefined;
        dispatch(newApplication(accessToken))
        setCurrentStep(3);
        setIsRegenerating(false);
        // TODO NAVIGATE TO TOP OF SECTION;
    }
    const onRegenerate = () => {
        // scrollToTop();
        setCurrentStep(1);
        setIsRegenerating(true);
    }
    const onGenerate = () => {
        // scrollToTop();
        setNextStep();
        setIsRegenerating(false);
    }
    const onSubmitJDForm = () => {
        // scrollToTop();
        setNextStep();
    }
    return (
        <div className='coverLetterModalWrapper'>
            <div className='coverLetterModal'>
                    <div className='coverLetterHeader displayLarge'>
                        Generate an optimized cover letter <span className="coverLetterHighlightedText">  in minutes!</span>
                    </div>
                <div className='coverLetterSubheader subheadingLarge'>
                    Get ready, <span className="coverLetterHighlightedText"> competitive resumes </span> coming soon.
                </div>
                <div id='cover-letter-modal'></div>
                <br />
                <StepLoader currentStepIndex={currentStep % 3} steps={steps} />
                <br />
                <br />
                { currentStep % 3 === 0?
                    <JDForm
                        submitLabel='Next'
                        onSubmit={onSubmitJDForm}
                    />
                : <></> }
                { currentStep % 3 === 1?
                    <>
                    { !auth.data ?
                    <SignInUpForm /> :
                    <CoverLetterInfoForm
                        submitLabel='Generate Cover Letter'
                        instructions={
                            isRegenerating?
                            'Update your work history to improve the quality of your cover letter':
                            ''}
                        onSubmit={onGenerate}/>}
                    </>
                : <></> }
                {currentStep % 3 === 2?
                    <CoverLetterEditor
                        onNewCoverLetter={onNewCoverLetter}
                        onRegenerate={onRegenerate} />
                : <></> }

            </div>
        </div>
    );
};

export default CoverLetterModal;