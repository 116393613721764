import React from 'react';
import CLLInput from '../CLLInput/CLLInput';
import '../../globalStyles/fonts/classes.css';
import '../../globalStyles/forms/classes.css';
import '../../globalStyles/colors/vars.css';


interface OnChangeFunction {
	/* return errorMessage. if no errorMessage return empty string '' */
    (value: any, hasActiveError?: boolean): any;
}
interface InputValues {
	onChange: OnChangeFunction;
  placeholder?: string;
  label?: string;
  subLabel?: string;
  /* optional, very specific params: */
  initValue?: string;
  oneWord?: boolean;
  includedValues?: string[];
  minLength?: number;
  maxLength?: number;
  autoCapitalize?: 'none' | 'sentences'| 'words' | 'characters';
  errorMessageOverride?: string;
}

const CLLShortTextBox = (props: InputValues) => {
	const {
		onChange,
		placeholder,
    label,
    subLabel,
    minLength,
    maxLength,
    oneWord,
    initValue,
    includedValues,
    autoCapitalize,
    errorMessageOverride
	} = props;
  const max = maxLength || 100;
  const min = minLength || 0;

  // console.log(initValue);

  const validate = (value: string, initialValue: string):string => {
    /* don't show error message if user has not yet input anything. */
    if (value === initialValue) return '';
    let errorMessage = '';
    let missingValues: string = '';
    (includedValues || []).map(
      (requiredValue) => {
        const valueLower = value.toLowerCase()
        const requiredValueLower = requiredValue.toLowerCase()
        if (!valueLower.includes(requiredValueLower)) {
          missingValues = `${missingValues}, ${requiredValue}`;
        }
      })
    missingValues = missingValues.substring(1);
    // errorMessage = (value.length > max) ? `Must be under ${max} characters in length.` : errorMessage;
    errorMessage = (value.length < min) ? `Must be over ${min} characters in length.` : errorMessage;
    errorMessage = (oneWord && value.includes(' ')) ? `Cannot include spaces.` : errorMessage;
    errorMessage = (
      missingValues?
      `Must include: ${missingValues}` :
      errorMessage);
    // if error override is passed and error is triggered, return error override.
    errorMessage = ((errorMessage.length > 0) && errorMessageOverride) ? errorMessageOverride : errorMessage
    return errorMessage;
  };

  return (
    <>
      <CLLInput
        label={label || ''}
        subLabel={subLabel || ''}
        onChange={onChange}
        validate={validate}
        placeholder={placeholder || 'Start typing here...'}
        rows={1}
        maxLength={max}
        minLength={min}
        autoCapitalize={autoCapitalize || 'sentences'}
        initValue={initValue || ''}
      />
    </>
  );

}

export default CLLShortTextBox;
