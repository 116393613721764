import React, { Component} from 'react';
// import PenAndPaperImg from './PenAndPaperImg/PenAndPaperImg';
import PenAndPaperImg from './PenAndPaperImg/PenAndPaperImg';
import BasicButton from '../../../common/BasicButton/BasicButton';
import LinkButton from '../../../common/LinkButton/LinkButton';
import './aboutSection.css';
import '../../../globalStyles/fonts/classes.css';
import '../../../globalStyles/responsiveLayout/grids.css';



class AboutSection extends Component <any, any> {
  render() {
    return (
			<div className='gridContainer aboutSection container'>
				<div className='gridItem oneHalf aboutSection text'>
					<div className='aboutSectionHeading displayLarge'>
						Finally, a generated job application as
						<span className='aboutSection--textEmphasis'> obsessed with your career success as you are!</span>
					</div>
					<div className='aboutSection text subheader subheadingLarge'>
						Pass ATS filters, impress hiring managers, and save time <span className='aboutSection--textEmphasis'> so that you can keep crushing it at work</span>.
					{/* Beat the bots, impress hiring managers, and save time with Resumakr's highly tailored, ATS-optimized applications. */}
					</div>
					<br />
					<div className='aboutSection buttons'>
						<LinkButton
							className='aboutSection buttons item'
							label={'Get Started'}
							href='#home-cover-letter-generator'
							type="primary"
						/>
						<LinkButton
							className='aboutSection buttons item'
							label={'Learn More'}
							href='#home-why-resumakr'
							type="secondary"
						/>
					</div>
				</div>
				<div className='gridItem oneHalf aboutSection imgContainer'>
					<PenAndPaperImg className='aboutSection img'/>
				</div>
			</div>
		);
  };
};

export default AboutSection;
