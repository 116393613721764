import React, { useState } from 'react';
import './CLLmonthYearInput.css';
import '../../globalStyles/responsiveLayout/spacers.css';
import '../../globalStyles/colors/classes.css';
import '../../globalStyles/fonts/classes.css';
import '../../globalStyles/forms/classes.css';

interface CLLMonthYearInputProps {
    onDateChange: (date: Date | null) => void;
    label?: string;
    subLabel?: string;
    showCheckbox: boolean; // New prop for checkbox
    checkboxLabel?: any;
    id: string;
    checkboxChecked?: boolean;
}


const CLLMonthYearInput: React.FC<CLLMonthYearInputProps> = ({
    onDateChange,
    label,
    subLabel,
    showCheckbox,
    checkboxLabel,
    id,
    checkboxChecked
}) => {
    const [inputValue, setInputValue] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isDisabled, setIsDisabled] = useState(showCheckbox && checkboxChecked);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const sanitizedValue = value.replace(/\D/g, ''); // Remove non-digit characters

        if (sanitizedValue.length <= 2) {
            // Handle month input
            const truncatedValue = sanitizedValue.slice(0, 2); // Keep only the first two digits

            if (truncatedValue) {
                const monthValue = parseInt(truncatedValue, 10); // Parse the month value
                const clampedValue = Math.min(Math.max(monthValue, 0), 12); // Clamp the value between 0 and 12
                const paddedValue = truncatedValue.startsWith('0') ? truncatedValue : clampedValue.toString(); // Pad the value with leading zeros if it starts with 0
                setInputValue(paddedValue);
                setErrorMessage('');
            } else {
                setInputValue('');
                setErrorMessage('');
            }
        } else {
            // Handle year input
            const yearInput = parseInt(sanitizedValue.slice(2, 6), 10); // Parse the year value

            if (yearInput < 1940 || yearInput > 2035) {
                setErrorMessage('Must be between 1940 and 2035');
            } else {
                setErrorMessage('');
            }

            const monthInput = sanitizedValue.slice(0, 2);
            setInputValue(`${monthInput}/${yearInput}`);
        }
    };



    const handleInputBlur = () => {
        const [monthInput, yearInput] = inputValue.split('/').map((value) => parseInt(value, 10));

        if (!isNaN(monthInput) && !isNaN(yearInput)) {
            const selectedDate = new Date(yearInput, monthInput - 1);
            onDateChange(selectedDate);
        } else {
            onDateChange(null);
        }
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        setIsDisabled(isChecked);

        // Clear input value and error message when disabled
        if (isChecked) {
            setInputValue('');
            setErrorMessage('');
        }
    };


    const renderSubLabel = (): any => {

        return (
            <div className={`formsQuestionSubLabel ${errorMessage ? 'error' : ''} footnote`}>
                {errorMessage || subLabel || ''}
            </div>
        );
    }

    return (
        <div className='mainWrapper'>
            <div className='formsTextInputWrapper CLLMonthYearInputWrapper'>
                <div className='formsTextInputWrapper'>
                    <div className="labelSmall txtColor--textPrimary">
                        {label}
                    </div>
                    {renderSubLabel()}
                </div>
                <div className='halfInputWrapper'>
                    <input
                        className={`formsTextInput growInput labelLarge ${isDisabled ? 'disabled' : ''}`}
                        id={id}
                        type='text'
                        placeholder='MM/YYYY'
                        value={inputValue}

                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        maxLength={7}
                        pattern="\d{2}/\d{4}"
                        autoComplete='off'
                        disabled={isDisabled} //Set disabled attribute based on state
                    />
                </div>
            </div>
            {showCheckbox && (
                <div className='checkboxWrapper'>
                    <input
                        className='cllMonthYearInput checkboxInput'
                        type='checkbox'
                        checked={isDisabled}
                        onChange={handleCheckboxChange}
                    />
                    <label className='checkboxLabel footnote'>{checkboxLabel}</label>
                </div>
            )}
        </div>
    );
};

export default CLLMonthYearInput;