import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { User } from '../../../apiServices/dataTypes/User';
// import { RootState } from '../../store';

interface DataState {
  user: User;
  accessToken: string;
}

// Define intiial state
export interface AuthState {
  data: DataState | null;
  status: 'pending' | 'failure' | 'success';
  errorMessage: string | null;
};
const initialState: AuthState = {
  data: null,
  errorMessage: null,
  status: 'success'
};

// Define slice using intitial state
const authSlice = createSlice({
  name: 'auth',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    signOut: (prevState) => {
      return initialState;
    },
    setAuthStarted: (prevState) => {
      let state = prevState;
      state.errorMessage = null;
      state.status = 'pending';
      return state;
    },
    setAuthFailed: (prevState) => {
      let state = prevState;
      state.errorMessage = 'Failed to connect to server.';
      state.status = 'failure';
      return state;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    setAuthSucceeded: (prevState, action: PayloadAction<AuthState>) => {
      return action.payload;
    },
  },
});

export default authSlice;

// Other code such as selectors can use the imported `RootState` type
// export const selectAuthState = (state: RootState) => state.auth;


