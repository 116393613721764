import React from 'react';
import CLLInput from './CLLInput/CLLInput';


interface OnChangeFunction {
	/* return errorMessage. if no errorMessage return empty string '' */
    (value: any, hasActiveError?: boolean): any;
}

interface EmailValues {
	placeholder?: string;
	onChange: OnChangeFunction;
  hideErrors?: Boolean;
}

const CLLEmailBox = (props: EmailValues) => {
	const {
		onChange,
		placeholder,
    hideErrors
	} = props;
  const getValidationRules = (value: string):Boolean[] => {
    /* Define validation rules. if value is true, the rule has been met. */
    const hasAtSymbol: boolean = value.includes('@');

    const hasPeriodSymbol: boolean = value.includes('.');

    const hasSufficientChars: boolean = value.length > 5;

    let hasCharBeforeAt: boolean = false;
    if (hasAtSymbol) {
      hasCharBeforeAt = value.split('@')[0].length > 0;
    }

    let hasValidTopLvlDomain: boolean = false;
    if (hasPeriodSymbol) {
      hasValidTopLvlDomain = value.split('.')[1].length > 1;
    }

    // let hasCharsBtwnAtAndPeriod: boolean = false;
    // if (hasPeriodSymbol && hasAtSymbol) {
    //   const atIndex = value.indexOf('@') + 1;
    //   const periodIndex = value.indexOf('.');
    //   if (atIndex < periodIndex) {
    //     hasCharsBtwnAtAndPeriod = value.substring(atIndex, periodIndex).length > 1;
    //   }
    // }

    return [
      hasAtSymbol,
      hasPeriodSymbol,
      hasSufficientChars,
      hasCharBeforeAt,
      hasValidTopLvlDomain
      // hasCharsBtwnAtAndPeriod
    ];
  }
  const validate = (value: string, initialValue: any):string => {
    if (hideErrors === true) return '';
    /* if value is initial value, do not show apply rules / show error message */
    const rules: Boolean[] = (value === initialValue) ? [] : getValidationRules(value);
    const errorMessage = 'Email must be valid.';
    /* if one or more rule is not met and is thus assigned a
    value of false return the error message */
    return rules.includes(false) ? errorMessage : '';
  };
  return (
    <>
      <CLLInput
	  		label='Email'
				subLabel=''
        validate={validate}
        onChange={onChange}
        placeholder={placeholder || 'tony.soprano@hbo.com'}
        maxLength={50}
        autoCapitalize={'none'}
        contentType={'email'}
      />
    </>
  );

}

export default CLLEmailBox;
