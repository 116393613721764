import { request }  from '../request';
import type { User } from '../dataTypes/ApiDataTypes';

export interface SignInData {
  user: User;
  accessToken: string;
}

// All api responses should be shaped like this but with request specific data structure.
interface SignInApiResponse {
  data: SignInData | null;
  status: number;
  statusMessage: string;
};


const signIn = async function(
  email: string | null,
  password: string  | null
): Promise<SignInApiResponse> {
  const endpoint = 'signin/';
  const method = 'POST';
  const body = {
    'email': email ? email : "",
    'password': password ? password : ""
  };
  const headers = {};
  // CASEY TODO:
  // COULD ADD PASSWORD AND EMAIL VALIDATION HERE
  // TO AVOID MAKING UNNECESSARY REQUESTS
  const response = await request(method, endpoint, body, headers);
  return {
    data: response.data,
    status: response.status,
    statusMessage: _getSignInError(response)
  };
};

interface GetSignInErrorFunc {
  (signIndata: SignInApiResponse): string;
}
let _getSignInError: GetSignInErrorFunc;
_getSignInError = function(signInResponse: SignInApiResponse) {
  if (signInResponse.status >= 500)
    return 'Network error. Please make sure you are connected to the internet.';
  if (signInResponse.status === 401)
    return 'Email and/or password are incorrect.';
  if (signInResponse.status === 404)
    return 'No account with this email. Please check for typos and try again.';
  if (signInResponse.status !== 200)
    return 'Email and/or password are incorrect.';
  return signInResponse.statusMessage;
}

export default signIn;


