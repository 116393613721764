import React, { useState } from 'react';
import SatisfactionButton from './SatisfactionButton/SatisfactionButton';
import './satisfactionRating.css';
import '../../globalStyles/fonts/classes.css';
import '../../globalStyles/colors/classes.css';

interface OnClickFunction {
  (type: 'bad' | 'neutral' | 'good'): any;
}

function SatisfactionRating(props: { label: string; onClick: OnClickFunction, className?: string }) {
	const { onClick, label, className } = props;

	const [isAnimating, setIsAnimating] = useState(false);

	const handleButtonClick = (type: 'bad' | 'neutral' | 'good') => {
		setIsAnimating(true);
		onClick(type);
		setTimeout(() => {
			setIsAnimating(false);
		}, 1000);
	};

	return (
		<div className={`satisfactionRating--wrapper ${className || ''} ${isAnimating ? 'fade-out' : ''}`}>
		{isAnimating ? null : (
			<>
				<div className="satisfactionRating--label headlineSmall txtColor--textPrimary">
					{label}
				</div>
				<div className="satisfactionRating--buttonsContainer">
					<SatisfactionButton onClick={handleButtonClick} type="bad" />
					<SatisfactionButton onClick={handleButtonClick} type="neutral" />
					<SatisfactionButton onClick={handleButtonClick} type="good" />
				</div>
			</>
		)}
		</div>
	);
}

export default SatisfactionRating;
