import React, { useState } from 'react';
import CLLSearchableDropdown, { Option } from '../CLLSearchableDropdown/CLLSearchableDropdown';
import CLLMonthYearInput from '../CLLMonthYearInput/CLLMonthYearInput';
import BasicButton from '../../common/BasicButton/BasicButton';
import './positionsModal.css';
import '../../globalStyles/forms/classes.css';

interface InputValues {
    onSubmit?: (position: string, organization: string, startDate: string, endDate: string) => void;
    onCancel?: () => void;
}

const PositionsModal: React.FC<InputValues> = (props) => {
    const [organization, setOrganization] = useState<Option[]>([]);
    const [jobTitle, setJobTitle] = useState<Option[]>([]);
    const [selectedStartDate, setSelectedStartDate] = useState<string | null>(null);
    const [selectedEndDate, setSelectedEndDate] = useState<string | null>(null);

    const togglePositionsModal = () => {
        if (props.onCancel) {
            props.onCancel();
        }
    };

    const handleSave = () => {
        if (props.onSubmit) {
            const selectedJobTitle = jobTitle[0]?.label || '';
            const selectedOrganization = organization[0]?.label || '';
            props.onSubmit(selectedJobTitle, selectedOrganization, selectedStartDate || '', selectedEndDate || '');
        }
    };

    const handleStartDateChange = (date: Date | null) => {
        setSelectedStartDate(date ? date.toISOString() : null);
    };

    const handleEndDateChange = (date: Date | null) => {
        setSelectedEndDate(date ? date.toISOString() : null);
    };


    const submitDisabled =
        jobTitle.length === 0 ||
        organization.length === 0 ||
        !selectedStartDate ||
        (!selectedEndDate && selectedEndDate !== null);

    return (
        <>
            <div className="positionsModalWrapper">
                <div className="exitIconWrapper" onClick={props.onCancel}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M17 7L7 17M7 7L17 17"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
                <div className="positionsModalHeader headlineLarge">Add a Position</div>
                <div className="positionsModalSubheader bodyLarge">Fill in the information about your position below</div>
                <br />
                <CLLSearchableDropdown
                    label="Job Title"
                    subLabel="You can choose up to 2 job titles"
                    input="fix later"
                    onChange={setJobTitle}
                    addOptions={true}
                    options={[
                        { id: 'product-manager', label: 'Product Manager' },
                        { id: 'copywriter', label: 'Copywriter' },
                        { id: 'recruiter', label: 'Recruiter' },
                        { id: 'ux-designer', label: 'UX Designer' },
                        { id: 'product-designer', label: 'Product Designer' },
                        { id: 'engineer', label: 'Engineer' },
                        { id: 'front-end-developer', label: 'Front-end Developer' },
                        { id: 'social-media-manager', label: 'Social Media Manager' },
                    ]}
                />
                <br />
                <CLLSearchableDropdown
                    label="Organization"
                    subLabel="Enter your company name"
                    input="fix later"
                    onChange={setOrganization}
                    addOptions={true}
                    options={[
                        { id: 'google', label: 'Google' },
                        { id: 'strategypoint', label: 'Strategypoint' },
                        { id: 'figma', label: 'Figma' },
                        { id: 'spotify', label: 'Spotify' },
                        { id: 'meta', label: 'Meta' },
                        { id: 'shopify', label: 'Shopify' },
                        { id: 'reddit', label: 'Reddit' },
                        { id: 'quora', label: 'Quora' },
                    ]}
                />
                <br />
                <div className="buttonWrapper">
                    <CLLMonthYearInput
                        id="start-date"
                        onDateChange={handleStartDateChange}
                        label="Start Date"
                        subLabel="Enter the month and year"
                        showCheckbox={false}
                    />
                    <CLLMonthYearInput
                        id="end-date"
                        onDateChange={handleEndDateChange}
                        label="End Date"
                        subLabel="Enter the month and year"
                        showCheckbox={true}
                        checkboxLabel="I am currently in this role"
                    />
                </div>
                <br />
                <br />
                <div className="buttonWrapper">
                    <BasicButton label="Cancel" className="grow" type="secondary" onClick={togglePositionsModal} />
                    <BasicButton
                        label="Save"
                        className="grow"
                        type="primary"
                        onClick={handleSave}
                        disabled={submitDisabled}
                    />
                </div>
            </div>
        </>
    );
};

export default PositionsModal;
