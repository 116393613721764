import currentJDActions from "../currentJD/currentJDActionCreator";
import currentCoverLetterActions from "../currentCoverLetter/currentCoverLetterActionCreator";

// the outside "thunk creator" function
const newApplication = (accessToken?: string) => {
	// the inside "thunk function"
	return async (dispatch: any, getState: any) => {
		dispatch(currentCoverLetterActions.newApplication(
			accessToken || undefined));
		dispatch(currentJDActions.newApplication());

	}
};

export default newApplication;