import currentJDSlice from './currentJDSlice';
import { updateStateByAPI } from "../actionCreatorUtils";
import JobDescriptions from '../../../apiServices/endpoints/jobDescriptions';
import {
  setLocalAPIData,
  getLocalAPIData,
  clearLocalAPIData } from "../../../localStorage/apiData";
import type { ApiResponse } from '../../../apiServices/request';


// the outside "thunk creator" function
const postJD = (
  orgName: string,
  titleName: string,
  requirements: string,
  responsibilities: string
  // niceToHaves?: string
) => {
  // the inside "thunk function"
  return async (dispatch: any, getState: any) => {
    // update status to pending so loaders start.
    updateStateByAPI(
      dispatch,
      async() => await setLocalAPIData(
        'currentJD',
        async() => await JobDescriptions.post(
          orgName,
          titleName,
          requirements,
          responsibilities
        )),
      currentJDSlice.actions.setCurrentJDStarted,
      currentJDSlice.actions.setCurrentJDSucceeded,
      currentJDSlice.actions.setCurrentJDFailed
    );
  }
};

// the outside "thunk creator" function
const loadCurrentJDAction = () => {
  // the inside "thunk function"
  return async (dispatch: any, getState: any) => {
    // update status to pending so loaders start.
    const response: ApiResponse = await getLocalAPIData(
      'currentJD');
    if (!response.data) return;
    await dispatch(currentJDSlice.actions.setCurrentJDSucceeded({
      data: response.data,
      errorMessage: null,
      status: 'success'
    }));
  }
};

const clearCurrentJDAction = () => {
  // the inside "thunk function"
  return async (dispatch: any, getState: any) => {
    await dispatch(currentJDSlice.actions.clearCurrentJD());
    await clearLocalAPIData('currentJD');
  }
};

const currentJDActions = {
    post: postJD,
    newApplication: clearCurrentJDAction,
    signOut: clearCurrentJDAction,
    loadWebApp: loadCurrentJDAction
}

export default currentJDActions;