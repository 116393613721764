import React, { useState } from 'react';
import './cllCheckbox.css';
import '../../globalStyles/responsiveLayout/spacers.css';
import '../../globalStyles/colors/classes.css';
import '../../globalStyles/fonts/classes.css';


interface OnChangeFunction {
	/* return errorMessage. if no errorMessage return empty string '' */
    (value: any, hasActiveError?: boolean): any;
}
interface InputValues {
	onChange: OnChangeFunction;
  id: string;
  label: any;
  defaultValue?: boolean;
  
}

const CLLCheckbox = (props: InputValues) => {
	const {
		onChange,
    id,
    label,
    defaultValue
	} = props;

  const [checked, setChecked] = useState(defaultValue || false);

  const handleChange = (value: boolean) => {
    setChecked(value);
    onChange(value);
  }

  return (
    <div className='cllCheckBoxWapper caption1 txtColor--textTertiary'>
      <input id={id} type="checkbox" checked={checked} onChange={() => handleChange(!checked)} />
      <div className='spacerHorizontal' />
      <label htmlFor={id} >
        {label}
      </label>
    </div>

  );

}

export default CLLCheckbox;
