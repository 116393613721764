import appQuestionsSlice from './appQuestionsSlice';
import AppQuestions, { PostAppQuestionData } from '../../../apiServices/endpoints/appQuestions'
import { updateStateByAPI } from "../actionCreatorUtils";
import {
  getOrSetLocalAPIData,
  setLocalAPIData,
  clearLocalAPIData } from "../../../localStorage/apiData";
import JobDescriptions from '../../../apiServices/endpoints/jobDescriptions';


// the outside "thunk creator" function
const loadAppQuestionsAction = (accessToken: string) => {
  // the inside "thunk function"
  return async (dispatch: any, getState: any) => {
    // update status to pending so loaders start.
    updateStateByAPI(
      dispatch,
      async() => await getOrSetLocalAPIData(
        'appQuestions',
        async() => await AppQuestions.get(accessToken)),
      appQuestionsSlice.actions.setQuestionsStarted,
      appQuestionsSlice.actions.setQuestionsSucceeded,
      appQuestionsSlice.actions.setQuestionsFailed
    );
  }
};

// the outside "thunk creator" function
const postAppQuestionsAction = (
  questionData: PostAppQuestionData[],
  accessToken: string) => {
  // the inside "thunk function"
  return async (dispatch: any, getState: any) => {
    // update status to pending so loaders start.
    updateStateByAPI(
      dispatch,
      async() => await setLocalAPIData(
        'appQuestions',
        async() => await AppQuestions.post(
          questionData,
          accessToken)),
      appQuestionsSlice.actions.setQuestionsStarted,
      appQuestionsSlice.actions.setQuestionsSucceeded,
      appQuestionsSlice.actions.setQuestionsFailed
    );
  }
};

const postSampleAnswersAction = (
  accessToken: string,
  jobDescriptionId: number) => {
  // the inside "thunk function"
  return async (dispatch: any, getState: any) => {
    // update status to pending so loaders start.
    updateStateByAPI(
      dispatch,
      async() => await setLocalAPIData(
        'appQuestions',
        async() => await AppQuestions.postSampleAnswers(
          accessToken,
          jobDescriptionId)),
      appQuestionsSlice.actions.setQuestionsStarted,
      appQuestionsSlice.actions.setQuestionsSucceeded,
      appQuestionsSlice.actions.setQuestionsFailed
    );
  }
};

// the outside "thunk creator" function
const signOutAction = () => {
  // the inside "thunk function"
  return async (dispatch: any, getState: any) => {
    dispatch(appQuestionsSlice.actions.signOut());
    clearLocalAPIData('appQuestions');
  }
};

const appQuestionsActions = {
  signIn: loadAppQuestionsAction,
  signUp: loadAppQuestionsAction,
  initAppQuestions: loadAppQuestionsAction,
  post: postAppQuestionsAction,
  postSampleAnswers: postSampleAnswersAction,
  // save: saveAppQuestions, # TODO
  signOut: signOutAction
}

export default appQuestionsActions;