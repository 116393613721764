import React from 'react';
import ImgSaveTime from './ImgSaveTime';
import './valueProps.css';
import '../../../globalStyles/fonts/classes.css';
import '../../../globalStyles/colors/classes.css';
import '../../../globalStyles/responsiveLayout/grids.css';
import '../../../globalStyles/responsiveLayout/spacers.css';


const PropSaveTime = () => {
    return (
		<div className=''>
			<div className='valueProps--imgContainer'>
				<ImgSaveTime className='valueProps--img saveTime--img'/>
			</div>
			<div className='txtColor--textPrimary valueProps--textContainer'>
				<div className='txtColor--brand displayLarge valueProps--header'>
					Save Time
				</div>
				<div className='spacerVertical' />
				<div className='subheadingLarge valueProps--subHeader'>
					Resumakr saves your work history so that you can generate <span className='txtColor--brand'> high quality job applications optimized for specific job requirements in minutes</span>.
				</div>
			</div>
		</div>
	);
};

export default PropSaveTime;
