import './stepLoader.css';
import '../../globalStyles/fonts/classes.css';
import '../../globalStyles/colors/classes.css';
import { useRef, useEffect } from 'react';

/* kind of horrible work in progress until dyl can tell me how he wants it styled */

function Step(props: {currentIndex: number, stepLabel: string, isLastStep: boolean, isCurrentStep: boolean, setStepRef: any}) {
	const {
		currentIndex,
		stepLabel,
		isLastStep,
		isCurrentStep,
		setStepRef
	} = props;
	const ref: any = useRef(currentIndex);
	setStepRef(currentIndex, ref);
	
	// if not first step: render stepline before steo
	// const isFirstStep = currentIndex === 0;
	// // if current step: render as :current
	// const currentId=getStepId(currentIndex);
	const state = isCurrentStep ? 'current' : '';
	const indexFont = isCurrentStep ? 'labelLarge' : 'labelLarge';
	const labelFont = isCurrentStep ? 'labelSmall' : 'labelSmall';
	return (
		<div ref={ref} className='stepLoader--stepWrapper' >
			
				<div className={`stepLoader--stepContainer ${state} ${indexFont}`}>
					<div className={`stepLoader--indexCircle ${state} ${indexFont}`}>
						{currentIndex + 1}
					</div>
					<div className={`stepLoader--label ${state} ${labelFont}`}>
						{stepLabel}
					</div>
					{isLastStep ? <></> : <div className={`stepLoader--stepLine ${state}`}/>}
				</div>
			
			
		</div>
	);

}

function StepLoader(props: {steps: string[], currentStepIndex: number}) {
	const {
		steps,
    currentStepIndex
	} = props;

	const stepRefs = new Map();

	const setStepRef = (index: number, ref: any) => {
		stepRefs.set(index, ref);
	}

	useEffect(() => {
		let currentStepElement = stepRefs.get(currentStepIndex);
		currentStepElement.current.focus();
	}, [currentStepIndex, ]);
	const lastStepIndex = steps.length - 1;
	
  return (
    <div 
      className='stepLoader--wrapper'
    > 
			{steps.map((stepLabel, currentIndex) => {
				const isLastStep = currentIndex === lastStepIndex;
				const isCurrentStep = currentStepIndex === currentIndex;
				// const isCurrentStep = false;
				return <Step 
					currentIndex={currentIndex} 
					stepLabel={stepLabel} 
					setStepRef={setStepRef} 
					isLastStep={isLastStep}
					isCurrentStep={isCurrentStep}
					key={`stepLoader--step${currentIndex}`} 
				/>
			})}
  </div>
  );

};

export default StepLoader;