///////////////////////////////////////////////////////////////////////////////////////
// Return Value Types
///////////////////////////////////////////////////////////////////////////////////////

export interface GetOrSetDataResponse {
	error: boolean;
	detail: string | null;
	data: any;
}

export interface StoreDataResponse {
	error: boolean;
	detail: string;
	data: any;
}

export interface RetrieveDataResponse {
	error: boolean;
	detail: string | null;
	data: any;
}

export interface ClearDataResponse {
	error: boolean;
	detail: string | null;
}

///////////////////////////////////////////////////////////////////////////////////////
// Get or Set Local Storage
///////////////////////////////////////////////////////////////////////////////////////



const _getOrSetData = async (key: string, data: string): Promise<GetOrSetDataResponse> =>{
	// If data stored, return it.
	const storage = await _retrieveData(key);
	if (storage.data) return storage;

	// Else set data
	const storeDataResp  = await _storeData(key, JSON.stringify(data));
	return {
		error: storeDataResp.error,
		detail: storeDataResp.error ? "Successfully got data but failed to store it in local storage." : "success!",
		data: data
	};
};

///////////////////////////////////////////////////////////////////////////////////////
// Store Data in Local Storage
///////////////////////////////////////////////////////////////////////////////////////



const _storeData = async (key: string, value: string): Promise<StoreDataResponse> => {
	// Note: to make json value string before passing it:
	// JSON.stringify(value)
	try {
		await localStorage.setItem(key, value);
		return {
			data: null,
			error: false,
			detail: "success!",
		};
	} catch (error) {
		const errorMessage = `Failed to save the data to local storage. Error: ${error}`;
		console.log(errorMessage);
		return {
			data: null,
			error: true,
			detail: errorMessage,
		}
	}
};

///////////////////////////////////////////////////////////////////////////////////////
// Retrieve Data from Local Storage
///////////////////////////////////////////////////////////////////////////////////////



const _retrieveData = async (key: string): Promise<RetrieveDataResponse> => {
	let errorMessage = `Failed to fetch the data from storage for key ${key}.`;
	try {
		const value = await localStorage.getItem(key);
		if (value !== null) {
			return {
				error: false,
				detail: "success!",
				data: value
			};
		}
	} catch (error) {
		errorMessage = `Failed to fetch the data from storage for key ${key}. Error: ${error}`;
	}
	console.log(errorMessage);
	return {
		error: true,
		detail: errorMessage,
		data: null
	}
};

///////////////////////////////////////////////////////////////////////////////////////
// Clear Data from Local Storage
///////////////////////////////////////////////////////////////////////////////////////

const _clearData = async (key: string): Promise<ClearDataResponse> => {
	let errorMessage = 'Error clearing app data.';
	try {
		localStorage.removeItem(key);
		return {
			error: false,
			detail: 'success!'
		};
	} catch (error) {
		errorMessage = `Error clearing app data. Error: ${error}`;
	}
	return {
		error: true,
		detail: errorMessage
	};
};

const _clearAllData = async (): Promise<ClearDataResponse> => {
	let errorMessage = 'Error clearing app data.';
	try {
		const keys = await localStorage.getAllKeys();
		await localStorage.multiRemove(keys);
		return {
			error: false,
			detail: 'success!'
		};
	} catch (error) {
		errorMessage = `Error clearing app data. Error: ${error}`;
	}
	return {
		error: true,
		detail: errorMessage
	};
};

///////////////////////////////////////////////////////////////////////////////////////
// Export Functions
///////////////////////////////////////////////////////////////////////////////////////

const services = {
    _getOrSetData: _getOrSetData,
    _storeData: _storeData,
    _retrieveData: _retrieveData,
	_clearData: _clearData,
    _clearAllData: _clearAllData
};

export default services;