import React, {useState} from 'react';
import { useAppSelector } from '../../../state/redux/hooks';
import './basicButton.css';
import '../../globalStyles/fonts/classes.css';
import '../../globalStyles/colors/classes.css';
import IconProps, { PlusCircle, Plus, Save, Search, Trash, ArrowRight, ArrowLeft, ArrowUp, ArrowDown, AlertTriangle } from '../IconLibrary/IconLibrary';

interface BasicButtonState {
  className: string;
  label: any;
  onClick: any;
  disabled?: boolean;
  type: 'primary' | 'secondary' | 'tertiary' | 'error' | 'success';
  icon?: string;
}

const getIconComponent = (iconName: string, buttonType: 'primary' | 'secondary' | 'tertiary' | 'error' | 'success'): React.ReactElement<React.SVGProps<SVGSVGElement>> | null => {
  let iconComponent = null;

  switch (iconName) {
    case 'PlusCircle':
      iconComponent = <PlusCircle />;
      break;
    case 'Plus':
      iconComponent = <Plus />;
      break;
    case 'Save':
      iconComponent = <Save />;
      break;
    case 'Search':
      iconComponent = <Search />;
      break;
    case 'Trash':
      iconComponent = <Trash />;
      break;
    case 'ArrowRight':
      iconComponent = <ArrowRight />;
      break;
    case 'ArrowLeft':
      iconComponent = <ArrowLeft />;
      break;
    case 'ArrowUp':
      iconComponent = <ArrowUp />;
      break;
    case 'ArrowDown':
      iconComponent = <ArrowDown />;
      break;
    case 'AlertTriangle':
      iconComponent = <AlertTriangle />;
      break;
    default:
      break;
  }

  if (iconComponent) {
    // Modify the stroke color based on button type
    let strokeColor = '';
    if (buttonType === 'primary') {
      strokeColor = 'var(--neutralWhite)'; // Use currentColor to inherit the color
    } else if (buttonType === 'secondary') {
      strokeColor = 'var(--textPrimary)'; // Set the stroke color to a specific color
    } else if (buttonType === 'tertiary') {
      strokeColor = 'var(--textPrimary)';
    } else if (buttonType === 'error') {
      strokeColor = 'var(--error)';
    } else if (buttonType === 'success') {
      strokeColor = 'var(--success)';
    }

    // Clone the icon component and update the stroke color
    iconComponent = React.cloneElement(iconComponent, {
      stroke: strokeColor
    });
  }

  return iconComponent;
};

const BasicButton = (props: BasicButtonState) => {
	const {
    className,
		label,
		onClick,
    disabled,
    type,
    icon: iconName
	} = props;
  let buttonColor = type === 'primary' ? 'buColor--brand' : '';
  buttonColor = type === 'secondary' ? 'buColor--secondary' : buttonColor;
  buttonColor = type === 'tertiary' ? 'buColor--brandBorder' : buttonColor;
  buttonColor = type === 'error' ? 'buColor--errorBorder' : buttonColor;
  buttonColor = type === 'success' ? 'buColor--success' : buttonColor;

  const iconComponent = iconName ? getIconComponent(iconName, type) : null;
  
  return (
    <div className={className}>
      <button
        className={`${buttonColor} basicButton buColor--shadow labelLarge`}
        onClick={onClick}
        disabled={disabled}
      >
        {iconComponent}
        {label}
      </button>
    </div>
  );
};

export default BasicButton;


