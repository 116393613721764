import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { JobDescription } from '../../../apiServices/dataTypes/JobDescription';
// import { RootState } from '../../store';


// Define intiial state
interface CurrentJDState {
  data: JobDescription | null;
  status: 'pending' | 'failure' | 'success';
  errorMessage: string | null;
};
const initialState: CurrentJDState = {
  data: null,
  errorMessage: null,
  status: 'success'
};

// Define slice using intitial state
const currentJDSlice = createSlice({
  name: 'currentJD',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    clearCurrentJD: (prevState) => {
      return initialState;
    },
    setCurrentJDStarted: (prevState) => {
      let state = prevState;
      state.errorMessage = null;
      state.status = 'pending';
      return state;
    },
    setCurrentJDFailed: (prevState, action: PayloadAction<CurrentJDState>) => {
      let state = prevState;
      state.errorMessage = action.payload.errorMessage;
      state.status = 'failure';
      return state;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    setCurrentJDSucceeded: (prevState, action: PayloadAction<CurrentJDState>) => {
      return action.payload;
    },
  },
});

export default currentJDSlice;

// Other code such as selectors can use the imported `RootState` type
// export const selectAuthState = (state: RootState) => state.auth;