import React from 'react';
import './hamburgerMenu.css';

type Props = {
  isOpen: boolean;
  isAnimating: boolean;
  toggleMenu: () => void;
};

const HamburgerMenuIcon: React.FC<Props> = ({ isOpen, isAnimating, toggleMenu }) => {
  const getMenuIconClassNames = () => {
    let classNames = ['hamburger-menu-icon'];
    if (isOpen) {
      classNames.push('hamburger-menu-icon--open');
    }
    return classNames.join(' ');
  };

  const getMenuLineClassNames = () => {
    let classNames = ['hamburger-menu-icon-line'];
    if (isOpen) {
      classNames.push('hamburger-menu-icon-line--open');
    }
    if (isAnimating) {
      classNames.push('hamburger-menu-icon-line--animating');
    }
    return classNames.join(' ');
  };

  return (
    <div className={getMenuIconClassNames()} onClick={toggleMenu}>
      <div className={getMenuLineClassNames()}></div>
      <div className={getMenuLineClassNames()}></div>
      <div className={getMenuLineClassNames()}></div>
    </div>
  );
};

export default HamburgerMenuIcon;
