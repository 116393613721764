import React, { useState } from 'react';
import './cllInput.css';
import '../../globalStyles/fonts/classes.css';
import '../../globalStyles/forms/classes.css';
import { Function } from '../../../typescriptTypes';

interface ValidationFunction {
	/* return errorMessage. if no errorMessage return empty string '' */
    (value: any, initialValue: any): string;
}
interface OnChangeFunction {
	/* return errorMessage. if no errorMessage return empty string '' */
    (value: any, hasActiveError?: boolean): any;
}

interface InputValues {
	onChange: OnChangeFunction;
	label?: string;
	subLabel?: string;
	placeholder?: string;
	/* super optional/specitic settings: */
	initValue?: string;
	validate?: ValidationFunction;
	autoComplete?: boolean;
	autoCapitalize?: 'none' | 'sentences'| 'words' | 'characters'; //only applies to virtual keyboards like on mobile or voice to text.
	rows?: number;
	maxLength?: number;
	minLength?: number;
	contentType?: string; // all possible content types listed here: https://www.w3schools.com/html/html_form_input_types.asp
	// If isError = true, the input box border is red.
	isError?: boolean;
	// className: string;
	// name: string;
	// acceptCharset=char encodings
}

const CLLInput = (props: InputValues) => {
	const {
		label,
		subLabel,
		placeholder,
		onChange,
		maxLength,
		minLength,
		autoComplete,
		initValue,
		rows,
		autoCapitalize,
		contentType,
		// If isError = true, the input box border is red.
		isError,
		validate
	} = props;
	const [errorMessage, setErrorMessage] = useState('');
	const handleChange = (event: any) => {
		const value = event.target.value;

		let error = '';
		if (validate) {
			error = validate(value, initValue || '');
		}
		setErrorMessage(error);
		const hasActiveError = (error !== '') ;
		onChange(value, hasActiveError);
	};
	const isTextBlock = rows && rows > 1;
	const renderSubLabel = ():any => {
		// Don't show max items notice if user can only select 1 option or
		// they have not reached max item qty.

		return (
			<div className={`formsQuestionSubLabel ${errorMessage ? 'error' : ''} footnote`}>
			  {errorMessage || subLabel || ''}
			</div>
		);
	  }
	return (
		<div className='formsTextInputWrapper'>
			<div className='formsQuestionLabel labelSmall'>
				{label || ''}
			</div>
			{renderSubLabel()}
			{ isTextBlock ?
				/* LONG TEXT FIELD */
				<textarea
					className={`formsTextInput ${isError ? 'error' : ''} longForm labelLarge`}
					// value={initValue || undefined} // <- this added unnecessary complexity, use defaultValue instead
					defaultValue={initValue || undefined}
					onChange={handleChange}
					placeholder={placeholder}
					maxLength={maxLength || 500}
					minLength={minLength || 0}
					autoComplete={
						autoComplete ? 'on' : 'off'
					}
					autoCapitalize={autoCapitalize || 'sentences'}
					rows={rows}
				/> :
				/* SHORT TEXT FIELD */
				<input
					className={`formsTextInput ${isError ? 'error' : ''}  labelLarge`}
					// value={initValue || undefined}  //  <- this added unnecessary complexity, use defaultValue instead
					defaultValue={initValue || undefined}
					onChange={handleChange}
					placeholder={placeholder}
					autoCapitalize={autoCapitalize || 'sentences'}
					maxLength={maxLength || 100}
					minLength={minLength || 0}
					autoComplete={
						autoComplete ? 'on' : 'off'
					}
					type={
						contentType ? contentType : 'text'
					}
				/>

			}
		</div>

	);
};

export default CLLInput;