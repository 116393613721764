import './satisfactionButtonImg.css';

// bad, neutral, good

function GoodImg() {
  
  return (
    <div className="satisfactionButtonImg--wrapper">
      <svg
      width="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="satisfactionButtonImg--svg"
      >
          <path
            className="satisfactionButtonImg--path"
            d="M30.5 18H30.52M18.5 18H18.52M44.5 24C44.5 35.0457 35.5457 44 24.5 44C13.4543 44 4.5 35.0457 4.5 24C4.5 12.9543 13.4543 4 24.5 4C35.5457 4 44.5 12.9543 44.5 24ZM31.5 18C31.5 18.5523 31.0523 19 30.5 19C29.9477 19 29.5 18.5523 29.5 18C29.5 17.4477 29.9477 17 30.5 17C31.0523 17 31.5 17.4477 31.5 18ZM19.5 18C19.5 18.5523 19.0523 19 18.5 19C17.9477 19 17.5 18.5523 17.5 18C17.5 17.4477 17.9477 17 18.5 17C19.0523 17 19.5 17.4477 19.5 18ZM24.5 35C29.501 35 33.5 31.334 33.5 28H15.5C15.5 31.334 19.499 35 24.5 35Z"
            stroke="black" 
            stroke-width="4" 
            stroke-linecap="round" 
            stroke-linejoin="round"
          />
      </svg>
    </div>
  );

};

export default GoodImg;




