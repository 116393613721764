import React from 'react';
import './valueProps.css';
import '../../../globalStyles/fonts/classes.css';
import '../../../globalStyles/colors/classes.css';
import '../../../globalStyles/responsiveLayout/grids.css';
import '../../../globalStyles/responsiveLayout/spacers.css';
import ImgBeatBots from './ImgBeatBots';



const PropBeatBots = () => {

    return (
		<div className='valueProps--wrapper'>
			<div className='valueProps--imgContainer'>
				<ImgBeatBots className='valueProps--img'/>
			</div>
			<div className='txtColor--textPrimary valueProps--textContainer'>
				<div className='txtColor--brand displayLarge valueProps--header'>
					Get Through Filters
				</div>
				<div className='spacerVertical' />
				<div className='subheadingLarge valueProps--subHeader'>
					97% of Fortune 500 companies use ATS (applicant tracking systems) known to filter out qualified candidates. Resumakr uses ATS keywords to <span className='txtColor--brand'> get through the filters </span>to the hiring team.
				</div>
			</div>
		</div>
	);

};

export default PropBeatBots;
