import colorModeSlice from "./colorModeSlice";
import localColorMode from "../../../localStorage/colorMode";


// the outside "thunk creator" function
const updateIsLightMode = (newIsLightMode: boolean) => {
  return async (dispatch: any) => {
    // Create and dispatch actions
    // Update state:
    dispatch(colorModeSlice.actions.updateColorMode({
      isLightMode: newIsLightMode
    }));
    // Update css color theme & save setting to local storage:
    localColorMode.setDataTheme(newIsLightMode);
  }
};

const initIsLightMode = () => {
  // Make sure isLightMode is set to reflect values in local storage.
  return async (dispatch: any) => {
    const dataTheme = await localColorMode.getOrSetDataTheme(true);
    let isLightMode = true;
    if (dataTheme.data) {
      isLightMode = (dataTheme.data === 'light') ? true : false;
    }
    dispatch(colorModeSlice.actions.updateColorMode({
      isLightMode: isLightMode
    }));
  }
};



const colorModeActions = {
  updateIsLightMode: updateIsLightMode,
  initIsLightMode: initIsLightMode
}


export default colorModeActions;