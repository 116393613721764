import React, { useState, useEffect } from 'react';
import BasicButton from '../../common/BasicButton/BasicButton';
import CLLSearchableDropdown, { Option } from '../CLLSearchableDropdown/CLLSearchableDropdown';
import CLLMonthYearInput from '../CLLMonthYearInput/CLLMonthYearInput';
import './educationForm.css';
import '../../globalStyles/responsiveLayout/spacers.css';
import '../../globalStyles/colors/classes.css';
import '../../globalStyles/fonts/classes.css';
import '../../globalStyles/forms/classes.css';
import CLLShortTextBox from '../CLLShortTextBox/CLLShortTextBox';

interface OnChangeFunction {
    /* return errorMessage. if no errorMessage return empty string '' */
    (value: any, hasActiveError?: boolean): any;
}

interface OnSubmitFunction {
    /* return errorMessage. if no errorMessage return empty string '' */
    (): any;
}

interface EducationFormProps {
    institution: Option[];
    degree: string;
    specialization: string;
    startDate: string;
    endDate: string;
    submitLabel?: string;
    value?: string;
}

interface InputProps {
    onBackButtonClick: () => void;
    onSubmit: OnSubmitFunction;
    submitLabel?: string;
}


const EducationForm: React.FC<InputProps> = ({
    onBackButtonClick,
    onSubmit,
    submitLabel
}) => {
    const [educationList, setEducationList] = useState<EducationFormProps[]>([
        {
            institution: [],
            degree: '',
            specialization: '',
            startDate: '',
            endDate: '',
        },
    ]);

    const isFormFilled = educationList.every(
        (education) =>
        education.institution.length > 0 &&
        education.degree.trim() !== '' &&
        education.specialization.trim() !== '' &&
        education.startDate.trim() !== '' &&
        education.endDate.trim() !== ''
    );

    const submitDisabled = !isFormFilled;

    useEffect(() => {
        const onKeyDown = (event: KeyboardEvent) => {
          if (event.key === 'Enter' && !submitDisabled) {
            onSubmit();
          }
        };

        document.addEventListener('keydown', onKeyDown);

        return () => {
            document.removeEventListener('keydown', onKeyDown);
        };
    }, [submitDisabled, onSubmit]);

    const handleAddEducation = () => {
        setEducationList([...educationList, { institution: [], degree: '', specialization: '', startDate: '', endDate: '' }]);
    };

    const handleChangeInstitution = (index: number, value: Option[]) => {
        const updatedEducationList = [...educationList];
        updatedEducationList[index].institution = value;
        setEducationList(updatedEducationList);
    };

    const handleChangeDegree = (index: number, value: string) => {
        const updatedEducationList = [...educationList];
        updatedEducationList[index].degree = value;
        setEducationList(updatedEducationList);
    };

    const handleChangeSpecialization = (index: number, value: string) => {
        const updatedEducationList = [...educationList];
        updatedEducationList[index].specialization = value;
        setEducationList(updatedEducationList);
    };

    const handleChangeStartDate = (index: number, value: string) => {
        const updatedEducationList = [...educationList];
        updatedEducationList[index].startDate = value;
        setEducationList(updatedEducationList);
    };

    const handleChangeEndDate = (index: number, value: string) => {
        const updatedEducationList = [...educationList];
        updatedEducationList[index].endDate = value;
        setEducationList(updatedEducationList);
    };


    const handleRemoveEducation = (index: number) => {
        const updatedEducationList = [...educationList];
        updatedEducationList.splice(index, 1);
        setEducationList(updatedEducationList);
    };

    const renderEducationForms = () => {
        return educationList.map((education, index) => (
            <div key={index}>
                <div className='subheader'>School {index + 1}</div>
                <br></br>
                <CLLSearchableDropdown
                    label="Institution"
                    subLabel='Enter the name of your school'
                    input={"gotta fix this"}
                    // addOptions={true}
                    // selectedOptions={education.institution}
                    options={[
                        { id: 'New York University', label: 'New York University' },
                        { id: 'Springboard', label: 'Springboard' },
                        { id: 'CUNY', label: 'CUNY'},
                        { id: 'Columbia-University', label: 'Columbia University' },
                        { id: 'Concordia', label: 'Concordia' },
                    ].sort((a, b) => a.label.localeCompare(b.label))} // Sort options alphabetically
                    onChange={(value) => handleChangeInstitution(index, value)}
                />
                <br></br>
                <div className='buttonWrapper'>
                    <CLLShortTextBox
                        label='Degree'
                        subLabel='Enter the type of degree or certification'
                        placeholder="e.g. Bachelors"
                        autoCapitalize='words'
                        onChange={(value) => handleChangeDegree(index, value)}
                    />
                    <CLLShortTextBox
                        label='Discipline'
                        subLabel='Enter the focus of your studies'
                        placeholder="e.g. UX Design"
                        autoCapitalize='words'
                        onChange={(value) => handleChangeSpecialization(index, value)}
                    />
                </div>
                <br></br>
                <div className="buttonWrapper">
                    <CLLMonthYearInput
                        id={`start-date-${index}`}
                        onDateChange={(date) => handleChangeStartDate(index, date ? date.toISOString() : '')}
                        label="Start Date"
                        subLabel="Enter the month and year"
                        showCheckbox={false}
                    />
                    <CLLMonthYearInput
                        id={`end-date-${index}`}
                        onDateChange={(date) => handleChangeEndDate(index, date ? date.toISOString() : '')}
                        label="End Date"
                        subLabel="Enter the month and year"
                        showCheckbox={true}
                        checkboxLabel="I am currently studying here"
                    />
                </div>
                <br></br>
                <br></br>
                <br></br>
                <div className='buttonWrapper'>
                    {index > 0 && (
                        <BasicButton
                            type='error'
                            className='grow'
                            label='Remove Education'
                            onClick={() => handleRemoveEducation(index)}
                            icon='Trash'
                        />
                    )}
                    <BasicButton
                        type='tertiary'
                        className='grow'
                        label='Add Education'
                        onClick={handleAddEducation}
                        icon='Plus'
                    />
                </div>
                <br></br>
                <br></br>
            </div>
        ));
    };

    return (
        <div className='signInFormWrapper'>
            <div className='signInFormHeader headlineLarge'>Education and Certification</div>
            <div className='signInFormSubheader bodyLarge'>Qualify your experience by adding any formal education that you've had</div>
            <br></br>
            {renderEducationForms()}
            <br></br>
            <div className='buttonWrapper'>
                <BasicButton
                    label='Back'
                    onClick={onBackButtonClick}
                    className='grow'
                    type='secondary'
                />
                <BasicButton
                    label={submitLabel || 'Submit'}
                    onClick={onSubmit}
                    className='grow'
                    type='primary'
                    disabled={submitDisabled}
                />
            </div>
        </div>
    )
};

export default EducationForm;