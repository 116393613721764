import { useRef, useState, useEffect, useLayoutEffect } from 'react';
import '../../globalStyles/fonts/classes.css';
import '../../globalStyles/colors/classes.css';
import BasicButton from '../../common/BasicButton/BasicButton';
import TypewriterLoader from '../../common/TypewriterLoader/TypewriterLoader';
import CLLLongTextBox from '../CLLLongTextBox/CLLLongTextBox';
import { useAppDispatch, useAppSelector } from '../../../state/redux/hooks';
import currentCoverLetterActions from '../../../state/redux/data/currentCoverLetter/currentCoverLetterActionCreator';
import appQuestionsActions from '../../../state/redux/data/appQuestions/appQuestionsActionCreator';
import { Function } from '../../../typescriptTypes';
import './coverLetterInfoForm.css';
// import BackArrow from '../../common/BackArrow/BackArrow';


interface OnChangeFunction {
	/* return errorMessage. if no errorMessage return empty string '' */
    (value: any, hasActiveError?: boolean): any;
}
interface OnSubmitFunction {
	/* return errorMessage. if no errorMessage return empty string '' */
    (): any;
}
interface InputValues {
	submitLabel?: string;
	instructions?: string;
	onSubmit: OnSubmitFunction;
}

interface QuestionValue {
	questionLabel: string;
	questionSublabel: string;
	questionPlaceholder: string;
	answer: string | null;
}


const CoverLetterInfoForm = (props: InputValues) => {
	const { submitLabel, instructions, onSubmit } = props;
	const dispatch = useAppDispatch();
	const appQuestions = useAppSelector(state => state.appQuestions);
	const currentCoverLetter = useAppSelector(state => state.currentCoverLetter);
	const accessToken = useAppSelector(state => state.auth.data?.accessToken);
	const jobDescriptionId = useAppSelector(state => state.currentJD.data?.id);
	const [appQuestionHash, setAppQuestionsHash] = useState(new Map());
	const appQuestionIdsInit: string[] = []
	const [appQuestionIds, setAppQuestionIds] = useState(appQuestionIdsInit);
	const [submitClicked, setSubmitClicked] = useState(false);
	const [generateAnswersClicked, setGenerateAnswersClicked] = useState(false);

	// const counter = useRef(0)
  // console.log(`CoverLetterInfoForm rerenders:${counter.current++}`)

	const dispatchAppQuestions = () => accessToken && dispatch(
		appQuestionsActions.initAppQuestions(accessToken));

	useLayoutEffect(() => {
		if (appQuestions.data.length > 0) return;
		dispatchAppQuestions();
	}, [])

	useEffect(() => {
		if (!submitClicked || !accessToken || !jobDescriptionId) return;
		if (appQuestions.status === 'pending') return;
		onSubmit();
		dispatch(
			currentCoverLetterActions.createCoverLetterWithAIDraft(
				accessToken,
				jobDescriptionId,
				currentCoverLetter.data || undefined));
			// CASEY TODO: add failure status steps that are distinct from success status steps.
			// Once question answers either fail or succeed to update
			// dispatch create cover letter
		}

	, [ submitClicked, appQuestions, jobDescriptionId, accessToken ])

	// Set up questions state and pre-populate with user's answers:
	const achievementsQuestion = (
		appQuestionIds.includes('achievements') ?
		appQuestionHash.get('achievements') : null);
	const [achievements, setAchievements] = useState('');

	const learningGoalsQuestion = (
		appQuestionIds.includes('learning_goals') ?
		appQuestionHash.get('learning_goals') : null);
	const [learningGoals, setLearningGoals] = useState('');

	const differentiatingQualitiesQuestion = (
		appQuestionIds.includes('differentiating_qualities') ?
		appQuestionHash.get('differentiating_qualities') :
		null);
	const [differentiatingQualities, setDifferentiatingQualities] = useState('');

	const missionQuestion = (
		appQuestionIds.includes('mission') ?
		appQuestionHash.get('mission') : null);
	const [mission, setMission] = useState('');

	const valuesQuestion = (
		appQuestionIds.includes('values') ?
		appQuestionHash.get('values') : null);
	const [values, setValues] = useState('');

	const employmentGapsQuestion = (
		appQuestionIds.includes('employment_gaps') ?
		appQuestionHash.get('employment_gaps') : null);
	const [employmentGaps, setEmploymentGaps] = useState('');

	const additionalInfoQuestion =(
		appQuestionIds.includes('additional_info') ?
		appQuestionHash.get('additional_info') : null);
	const [additionalInfo, setAdditionalInfo] = useState('');

	// Allow user to submit changes if all questions are answered,
	// except employment gaps.
	const requiredQuestionsAnswered = !!(
		achievements &&
		learningGoals &&
		differentiatingQualities &&
		mission &&
		values);
	const submitDisabled = !requiredQuestionsAnswered;


	useEffect(() => {
		setAchievements(achievementsQuestion ? achievementsQuestion.answer : '');
		setLearningGoals(learningGoalsQuestion ? learningGoalsQuestion.answer : '');
		setDifferentiatingQualities(differentiatingQualitiesQuestion ? differentiatingQualitiesQuestion.answer : '');
		setMission(missionQuestion ? missionQuestion.answer : '');
		setValues(valuesQuestion ? valuesQuestion.answer : '');
		setEmploymentGaps(employmentGapsQuestion ? employmentGapsQuestion.answer : '');
		setAdditionalInfo(additionalInfoQuestion ? additionalInfoQuestion.answer : '');
	}, [
		achievementsQuestion,
		learningGoalsQuestion,
		differentiatingQualitiesQuestion,
		missionQuestion,
		valuesQuestion,
		employmentGapsQuestion,
		additionalInfoQuestion ])

	// trigger cover letter generation & render next steo
	// once question answers are finished updating.
	useEffect(() => {
		// runs when app questions are updated
		const QAs = appQuestions.data || [];
		const newAppQuestionsHash = new Map();
		let newAppQuestionIds: string[] = [];
		QAs.map((QA) => {
			const { question, answer } = QA;
			// add question id to list
			newAppQuestionIds.push(question.id);

			// Add QA to hash
			const hash = question.id;
			const value: QuestionValue = {
				questionLabel: question.label,
				questionSublabel: question.sublabel,
				questionPlaceholder: question.placeholder,
				answer: answer ? answer.text : null,
			};
			newAppQuestionsHash.set(hash, value);
			return null;
		});
		setAppQuestionsHash(newAppQuestionsHash);
		setAppQuestionIds(newAppQuestionIds);
		// useEffect runs when appQuestions state changes.
		// it doesn't update when appQuestionsHash or appQuestionIds state changes
		// because that would create an infinite loop where useEffects keeps invoking itself.
	}, [ appQuestions ])

    /* Later: pull active questions from db as well as user's responses to those questions
    & dynamically render them based on question type */

	const onChangeHook = (updateState: Function) => {
		const onChange: OnChangeFunction = (value: string, hasActiveError?: boolean) => {
			const validValueEmpty = (hasActiveError || value.length === 0) ? '' : value;
			updateState(validValueEmpty);
		}
		return onChange;
	}
	const handleSubmit = function () {
		if (appQuestions.status === 'pending') return;
		if (accessToken && jobDescriptionId) {
			setSubmitClicked(true);
			dispatch(appQuestionsActions.post([{
					question_id: 'achievements',
					text: achievements
				},{
					question_id: 'learning_goals',
					text: learningGoals
				},{
					question_id: 'mission',
					text: mission
				},{
					question_id: 'values',
					text: values
				},{
					question_id: 'differentiating_qualities',
					text: differentiatingQualities
				},{
					question_id: 'employment_gaps',
					text: employmentGaps
				},{
					question_id: 'additional_info',
					text: additionalInfo
				}
			], accessToken))
			// in useEffect, trigger cover letter creation and render next step (with onSubmit)
		}
		// TODO: if no jd, send back to jdform page.
		// TODO: think thru how to handle errors...
	};
	const renderQuestion = (
		question: QuestionValue | null,
		setStateFunc: typeof setAchievements,
		stateValue: any,
		rows?: number) => {
		return (
			question ?
			<>
				<CLLLongTextBox
					label={question.questionLabel}
					subLabel={question.questionSublabel}
					placeholder={question.questionPlaceholder}
					initValue={stateValue}
					onChange={onChangeHook(setStateFunc)}
					minLength={0}
					maxLength={100000}
					rows={rows || 5}
				/>
				<br />
				<br />
			</> : <></>
		);
	}

	const handleGenerateSampleAnswers = () => {
		// trigger generate
		// 1. make api endpoint
		// 2. test in postman
		// 3. add action creator
		if (!accessToken || !jobDescriptionId) return;
		dispatch(appQuestionsActions.postSampleAnswers(
			accessToken,
			jobDescriptionId));
		setGenerateAnswersClicked(true);
	}

	const renderGenerateSampleAnswers = () => {
		if (requiredQuestionsAnswered) return;
		return (
			<>
				<div className='txtColor--textPrimary labelLarge'>
					Feeling stuck?
				</div>
				<div className='txtColor--textSecondary labelSmall'>
					Generate sample answers for remaining questions.
				</div>
				<br />
				<BasicButton
				label={'Generate Sample Answers'}
				onClick={handleGenerateSampleAnswers}
				className=""
				type="primary"
				disabled={false}/>
				<br />
				<br />
			</>
		);
	}
	const renderLoader = () => {
		const isLoadingQuestionAnswers = (
			generateAnswersClicked && appQuestions.status === 'pending');
		// const isLoadingQuestionAnswers = true;
		return (
			<TypewriterLoader
				label={"Great! Your answers will be ready in a few minutes. Do not refresh the page."}
				isLoading={isLoadingQuestionAnswers} />
		)
	}

	// const handleBackClick = () => {
	// 	console.log("handle back click");
	// 	// history.back();
	// }
	// const handleViewCoverLetter = () => {
	// 	console.log("handle view cover letter click");
	// }

	const renderQuestions = () => {
		const isLoadingQuestionAnswers = (
			generateAnswersClicked && appQuestions.status === 'pending');
		// const isLoadingQuestionAnswers = true;
		if (isLoadingQuestionAnswers) return;
		return (
			<>
				{ renderQuestion(achievementsQuestion, setAchievements, achievements) }
				{ renderQuestion(learningGoalsQuestion, setLearningGoals, learningGoals) }
				{ renderQuestion(differentiatingQualitiesQuestion, setDifferentiatingQualities, differentiatingQualities) }
				{ renderQuestion(missionQuestion, setMission, mission) }
				{ renderQuestion(valuesQuestion, setValues, values) }
				{ renderQuestion(employmentGapsQuestion, setEmploymentGaps, employmentGaps) }
				{ renderQuestion(additionalInfoQuestion, setAdditionalInfo, additionalInfo) }
				<div className='buttonWrapper'>
					{/* <BasicButton
						type='secondary'
						className='grow'
						label='Edit Job Description'
						onClick={handleBackClick}
					/> */}
					<BasicButton
						label={submitLabel || 'Generate Cover Letter'}
						onClick={handleSubmit}
						className="grow"
						type="primary"
						disabled={appQuestions.status === 'pending'}
					/>
				</div>
				<br></br>
				{/* {currentCoverLetter.data && currentCoverLetter.data.ai_final_draft && (
					<BasicButton
						label='View Cover Letter'
						onClick={handleViewCoverLetter}
						className='grow'
						type='secondary'
					/>
				)} */}
			</>
		)
	}

	return (
		<div className=''>
			<div className='txtColor--textPrimary labelLarge'>
				{instructions || ''}
			</div>
			{ renderGenerateSampleAnswers() }
			{ renderLoader() }
			{ renderQuestions() }
		</div>
	);
};

export default CoverLetterInfoForm;