import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// import localStorage from "../../../localStorage";

// Define intiial state
interface ColorModeState {
  isLightMode: boolean;
};


const initialState: ColorModeState = {
  isLightMode: true,
};

// Define slice using intitial state
export const colorModeSlice = createSlice({
  name: 'colorMode',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateColorMode: (prevState, action: PayloadAction<ColorModeState>) => {
      return action.payload;
    },
  },
});

export default colorModeSlice;

// export const selectColorMode = (state: RootState) => state.colorMode;


