import './typewriterLoader.css';
import '../../globalStyles/fonts/classConstants';

const TypewriterLoader = (props: {isLoading : boolean, label : string}) => {
    const {isLoading, label} = props;

    return (
        <>
        {isLoading?
            <div className='typewriterLoaderWrapper'>
                <div className="typewriterLoader--header headlineLarge">{label}</div>
                <div className="typewriter">
                    <div className="slide"><i></i></div>
                    <div className="paper"></div>
                    <div className="keyboard"></div>
                </div>
            </div>:<></>
        }
        </>
    )
}

export default TypewriterLoader;