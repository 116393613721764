import { useEffect, useState } from 'react';
import { Function } from '../../../typescriptTypes';
import { useAppSelector, useAppDispatch } from '../../../state/redux/hooks';
import '../../globalStyles/responsiveLayout/spacers.css';
import '../../globalStyles/colors/classes.css';
import '../../globalStyles/fonts/classes.css';
import './resumeBuilderModal.css';
import ExperiencesForm from '../ExperiencesForm/ExperiencesForm';
import SkillsForm from '../SkillsForm/SkillsForm';
import StepLoader from '../../common/StepLoader/StepLoader';
import EducationForm from '../EducationForm/EducationForm';

interface OnChangeFunction {
    // return errorMessage. If no errorMessage return empty string ''
    (value: any, hasActiveError?: boolean): any;
}

interface OnSubmitFunction {
    (): any;
}

interface InputValues {
    onSubmit: OnSubmitFunction;
    submitLabel?: string;
    selectedSkills: any[];
}

const ResumeBuilderModal = (props: InputValues) => {
    const steps = ['Select top skills', 'Match your experience', 'Add your education'];

    // Global state:
    const dispatch = useAppDispatch();
    const auth = useAppSelector(state => state.auth);

    // Component state:

    const [currentStep, setCurrentStep] = useState(0);
    const [selectedSkills, setSelectedSkills] = useState<any[]>(props.selectedSkills);

    useEffect(() => {
        // start at top of component section everytime component mounts 
        if (currentStep > 0) {
            scrollToTop();
        }
    }, [currentStep]);

    const setNextStep = () => {
        const nextStepIndex = currentStep + 1;
        setCurrentStep(nextStepIndex);
    }

    const setPreviousStep = () => {
        const previousStepIndex = currentStep - 1;
        setCurrentStep(previousStepIndex);
    }

    const scrollToTop = () => {
        window.location.assign("#resume-builder-modal")
    }

    const handleSkillsChange = (selectedSkills: any[]) => {
        setSelectedSkills(selectedSkills);
    };

    const onSubmitSkillsForm = () => {
        scrollToTop();
        setNextStep();
    }

    const onSubmitExperiencesForm = () => {
        scrollToTop();
        setNextStep();
    }

    const onSubmitEducationForm = () => {
        scrollToTop();
        setNextStep();
    }

    const onBackButton = () => {
        scrollToTop();
        setPreviousStep();
    }
    

    return (
        <div className="coverLetterModalWrapper">
            <div className='resumeBuilderModal'>
                <div className='resumeBuilderHeader displayLarge'>
                    Try our first <span className="resumeBuilderHighlightedText">beta</span> feature!
                </div>
                <div className='resumeBuilderSubheader subheadingLarge'>
                    Generate a <span className="resumeBuilderHighlightedText">customized resume</span> in minutes!
                </div>
                <div id='resume-builder-modal'></div>
                    <br></br>
                    <StepLoader currentStepIndex={currentStep % 3} steps={steps} />
                    <br></br>
                    { currentStep % 3 === 0?
                        <SkillsForm 
                            selectedSkills={selectedSkills}
                            onSkillsChange={handleSkillsChange}
                            submitLabel='Next'
                            onSubmit={onSubmitSkillsForm}
                            onBackButtonClick={onBackButton}
                            onNextButtonClick={onSubmitSkillsForm}
                            maxOptions={3}
                            minOptions={0}
                        />
                    : <></> }
                    { currentStep % 3 === 1?
                        <ExperiencesForm
                            selectedSkills={selectedSkills}
                            onSubmit={onSubmitExperiencesForm}
                            onBackButtonClick={onBackButton}
                            onNextButtonClick={onSubmitExperiencesForm}
                        />
                    : <></> }
                    { currentStep % 3 === 2?
                        <EducationForm 
                            onBackButtonClick={onBackButton}
                            onSubmit={onSubmitEducationForm}
                        />
                    : <></> }  

            </div>
            <br></br>
            <br></br>
        </div>
    )
}

export default ResumeBuilderModal;