import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { Title } from '../../../apiServices/dataTypes/Title';
// import { RootState } from '../../store';


// Define intiial state
interface TitlesState {
  data: Title[]; // changing this from 'data: Title[] | null' might cause errors.
  status: 'pending' | 'failure' | 'success';
  errorMessage: string | null;
};
const initTitlesData: Title[] = [];
const initialState: TitlesState = {
  data: initTitlesData,
  errorMessage: null,
  status: 'success'
};

// Define slice using intitial state
const titlesSlice = createSlice({
  name: 'titles',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setTitlesStarted: (prevState) => {
      let state = prevState;
      state.errorMessage = null;
      state.status = 'pending';
      return state;
    },
    setTitlesFailed: (prevState) => {
      let state = prevState;
      state.errorMessage = 'Failed to connect to server.';
      state.status = 'failure';
      return state;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    setTitlesSucceeded: (prevState, action: PayloadAction<TitlesState>) => {
      return action.payload;
    },
  },
});

export default titlesSlice;

// Other code such as selectors can use the imported `RootState` type
// export const selectAuthState = (state: RootState) => state.auth;