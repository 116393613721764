import services, { 
    ClearDataResponse, 
    RetrieveDataResponse,
    StoreDataResponse,
    GetOrSetDataResponse } from "./services";

// Use these functions to save state objects when API requests aren't required.


export const setLocalData = async (
    key: string, 
    data: any): Promise<StoreDataResponse> => {
    const response: StoreDataResponse = await services._storeData(
        key, JSON.stringify(data));
    return response;
}

export const getLocalData = async (
    key: string): Promise<RetrieveDataResponse> => {
    const response: RetrieveDataResponse = await services._retrieveData(
        key);
    return response;
}

export const getOrSetLocalData = async (
    key: string, 
    data: any): Promise<GetOrSetDataResponse> => {
    const response: GetOrSetDataResponse = await services._getOrSetData(
        key, JSON.stringify(data));
    return response;
}

export const clearLocalData = async (key: string): Promise<ClearDataResponse> => {
	return await services._clearData(key);
}