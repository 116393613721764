import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { RootState, AppDispatch } from './store'
import store from './store'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;


// WHY HOOKS?
// 1. without hooks you must import RootState to define state type:
// const deviceType = useSelector((state: RootState) => state.device.type); 
// 2. with hooks, you don't need to define state type:
// const deviceType = useAppSelector(state => state.device.type);