import React from 'react';
import ImgWowHiring from './ImgWowHiring';
import './valueProps.css';
import '../../../globalStyles/fonts/classes.css';
import '../../../globalStyles/colors/classes.css';
import '../../../globalStyles/responsiveLayout/grids.css';
import '../../../globalStyles/responsiveLayout/spacers.css';



const PropWowHiring = () => {

    return (
		<div className=''>
			<div className='oneHalf valueProps--imgContainer'>
				<ImgWowHiring className='valueProps--img'/>
			</div>
			<div className='txtColor--textPrimary valueProps--textContainer'>
				<div className='txtColor--brand displayLarge valueProps--header'>
					Wow Hiring Teams
				</div>
				<div className='spacerVertical' />
				<div className='subheadingLarge valueProps--subHeader'>
					Resumakr uses a proprietary algorithm to <span className='txtColor--brand'> select the most relevant skills and experience</span> to match ATS filters, demonstrate your unique qualifications, and impress hiring teams.
				</div>
				<br />
			</div>

		</div>
	);

};

export default PropWowHiring;
