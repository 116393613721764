import React, {useState} from 'react';
// import { useAppSelector, useAppDispatch } from '../../../state/redux/hooks';
import './linkButton.css';
import '../../globalStyles/fonts/classes.css';
import '../../globalStyles/colors/classes.css';

interface LinkButtonState {
  className: string;
  label: any;
  href: string;
  openTab?: boolean;
  type: 'primary' | 'secondary' | 'tertiary';
}

const LinkButton = (props: LinkButtonState) => {
	const {
    className,
		label,
		href,
    openTab,
    type
	} = props;
  let buttonColor = type === 'primary' ? 'buColor--brand' : '';
  buttonColor = type === 'secondary' ? 'buColor--secondary' : buttonColor;
  buttonColor = type === 'tertiary' ? 'buColor--tertiary' : buttonColor;
  const target = openTab === true? "_blank" : "";
  return (
    <div className={className}>
      <a
        className={`linkButton ${buttonColor} labelLarge`}
        href={href}
        target={target}
      >
        {label}
      </a>
    </div>
  );
};

export default LinkButton;


