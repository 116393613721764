import currentCoverLetterSlice from './currentCoverLetterSlice';
import CoverLetters from '../../../apiServices/endpoints/coverLetters';
import CoverLetterAIDrafts from '../../../apiServices/endpoints/coverLetterAIDrafts';
import { updateStateByAPI } from "../actionCreatorUtils";
import { CoverLetter } from '../../../apiServices/dataTypes/CoverLetter';
import type { CoverLetterResponse } from '../../../apiServices/endpoints/coverLetters';
import {
  setLocalAPIData,
  getLocalAPIData,
  clearLocalAPIData } from "../../../localStorage/apiData";
import {
  setLocalData
} from "../../../localStorage/stateData";
import type { ApiResponse } from '../../../apiServices/request';
import { doesNotMatch } from 'assert';

///////////////////////////////////////////////////////////////////////////////////
/* API REQUEST IMITATION FUNCTIONS BELOW                                         */
/* What are they?  async funcs that imitate state/apiServices/endpoints requests */
/* Why use them? To perform more than one API request and still easily plug      */
/* into updateStateByAPI hook                                                    */
///////////////////////////////////////////////////////////////////////////////////


// CASEY NOTE: polling function could be simplified with recursion
// see:
// https://www.sitepoint.com/delay-sleep-pause-wait/#:~:text=function%20pollDOM%20()%20%7B

function sleep(milliseconds: number) {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
}


// Mimics cover letter api request response type.
const createAndPollAIDraft = async(
  coverLetter: CoverLetter,
  accessToken: string
): Promise<CoverLetterResponse> => {
  // Don't bother making request if max generation attempts has been reached.
  if (coverLetter.generation_attempts > 3) {
    return {
      data: coverLetter,
      status: 401,
      statusMessage: "Max Generation Attempts Reached."
    }
  }
  // function should only return response when done polling.

  // Trigger AI draft Creation
  const postResponse = await CoverLetterAIDrafts.post(coverLetter.id, accessToken)

  // if fails to trigger, return error and old cover letter:
  let aiDraft = postResponse.data;
  let data = coverLetter;
  let status = postResponse.status;
  let statusMessage = postResponse.statusMessage
  if (postResponse.status !== 200 || !aiDraft) {
    // status and status message from API request
    // prev state cover letter data.
    return {
      data: data,
      status: status,
      statusMessage: statusMessage
    }
  }

  // Get id to poll for complete draft.
  const aiDraftId = aiDraft.id;

  // Poll endpoint for completed cover letter:
  // Settings:
  const maxDurationMinutes = 3
  const maxServerErrors = 2;
  const timeBetweenPollingSecs = 5

  // Math and vars to support settings & while loop:
  let draftStatus = 'PENDING';
  let serverErrorCount = 0;
  let attempts = 0;
  const maxAttempts = (maxDurationMinutes * 60) / timeBetweenPollingSecs;

  // Actual polling code:
  while(draftStatus === 'PENDING' && attempts < maxAttempts){
    attempts++; // <-- DELETING/MOVING WILL CREATE INFINITE LOOP
    sleep(timeBetweenPollingSecs * 1000);
    // Check on draft status after waiting for "time between polling" to elapse
    const getResponse = await CoverLetterAIDrafts.get(aiDraftId, accessToken);
    // If returns server error, try up to 2 times.
    if (getResponse.status === 500 && serverErrorCount <= maxServerErrors) {
      serverErrorCount++;
      continue
    }
    aiDraft = getResponse.data;
    // if returns other error or no draft record,
    // return error and OG cover letter.
    if (getResponse.status !== 200 || !aiDraft) {
      return {
        data: coverLetter,
        status: getResponse.status,
        statusMessage: postResponse.statusMessage
      }
    }
    // At this point status is 200 and draft record exists
    // set latest draft status (PENDING, ERROR, SUCCESS)
    draftStatus = aiDraft.status; // <-- DELETING/MOVING WILL CREATE INFINITE LOOP
  }
  // at this point
  // status = PENDING (meaning reached max attempts), ERROR, or SUCCESS
  if (draftStatus === 'ERROR' || draftStatus === 'SUCCESS') {
    const editedCoverLetter: CoverLetter = {
      id: coverLetter.id,
      generation_attempts: coverLetter.generation_attempts,
      job_description: coverLetter.job_description,
      ai_final_draft: aiDraft.text,
      user_edited_draft: coverLetter.user_edited_draft
    }

    // Note: even if error, a partial draft might have been returned.
    return {
      data: editedCoverLetter,
      status: 200,
      statusMessage: draftStatus
    }
  }
  // If status===PENDING, we hit max attempts
  // in this case return og coverletter
  return {
    data: coverLetter,
    status: postResponse.status,
    statusMessage: "Polling timed out."
  }

}

// Mimics cover letter api request response type.
const createCoverLetterThenPollAIDraft = async (
  accessToken: string,
  jobDescriptionId: number,
  coverLetter?: CoverLetter): Promise<CoverLetterResponse> => {
  let coverLetterToUpdate: CoverLetter | null = null;
  if (!coverLetter) {
    const clResponse = await CoverLetters.post(jobDescriptionId, accessToken);
    const newCoverLetter = clResponse.data;
    if (clResponse.status !== 200 || !newCoverLetter) return clResponse;
    coverLetterToUpdate = newCoverLetter
  } else {
    coverLetterToUpdate = coverLetter;
  }

  const coverLetterWithAIDraft = await createAndPollAIDraft(
    coverLetterToUpdate, accessToken);
  return coverLetterWithAIDraft;

}

//////////////////////////////////////////////////////////////////////////////////
/* ACTION CREATORS DEFINED BELOW                                                */
//////////////////////////////////////////////////////////////////////////////////

// the outside "thunk creator" function
const createCoverLetter = (accessToken: string, jobDescriptionId: number) => {
  // the inside "thunk function"
  return async (dispatch: any, getState: any) => {
    // update status to pending so loaders start.
    updateStateByAPI(
      dispatch,
      async() => await setLocalAPIData(
        'currentCoverLetter',
        async() => await CoverLetters.post(
          jobDescriptionId,
          accessToken)),
      currentCoverLetterSlice.actions.setCoverLetterStarted,
      currentCoverLetterSlice.actions.setCoverLetterSucceeded,
      currentCoverLetterSlice.actions.setCoverLetterFailed
    );
  }
};

const updateCoverLetter = (
  accessToken: string,
  id: number,
  userEditedDraft?: string,
  satisfactionRating?: 'BAD' | 'OK' | 'GOOD'
  ) => {
  // the inside "thunk function"
  return async (dispatch: any, getState: any) => {
    // update status to pending so loaders start.
    updateStateByAPI(
      dispatch,
      async() => await setLocalAPIData(
        'currentCoverLetter',
        async() => await CoverLetters.put(
          accessToken,
          id,
          userEditedDraft,
          satisfactionRating)),
      currentCoverLetterSlice.actions.setCoverLetterStarted,
      currentCoverLetterSlice.actions.setCoverLetterSucceeded,
      currentCoverLetterSlice.actions.setCoverLetterFailed
    );
  }
};

const updateAIDraft = (
  accessToken: string,
  coverLetter: CoverLetter,
  ) => {
  // the inside "thunk function"
  return async (dispatch: any, getState: any) => {
    // update status to pending so loaders start.
    updateStateByAPI(
      dispatch,
      async() => await setLocalAPIData(
        'currentCoverLetter',
        async() => await createAndPollAIDraft(
          coverLetter,
          accessToken)),
      currentCoverLetterSlice.actions.setCoverLetterStarted,
      currentCoverLetterSlice.actions.setCoverLetterSucceeded,
      currentCoverLetterSlice.actions.setCoverLetterFailed
    );
  }
};

const createCoverLetterThenAIDraft = (
  accessToken: string,
  jobDescriptionId: number,
  coverLetter?: CoverLetter
  ) => {
  // the inside "thunk function"
  return async (dispatch: any, getState: any) => {
    // update status to pending so loaders start.
    updateStateByAPI(
      dispatch,
      async() => await setLocalAPIData(
        'currentCoverLetter',
        async() => await createCoverLetterThenPollAIDraft(
          accessToken,
          jobDescriptionId,
          coverLetter)),
      currentCoverLetterSlice.actions.setCoverLetterStarted,
      currentCoverLetterSlice.actions.setCoverLetterSucceeded,
      currentCoverLetterSlice.actions.setCoverLetterFailed
    );
  }
};


const loadCurrentCoverLetterAction = () => {
  // the inside "thunk function"
  return async (dispatch: any, getState: any) => {
    // update status to pending so loaders start.
    const response: ApiResponse = await getLocalAPIData(
      'currentCoverLetter');
    if (!response.data) return;
    await dispatch(currentCoverLetterSlice.actions.setCoverLetterSucceeded({
      data: response.data,
      errorMessage: null,
      status: 'success'
    }));
  }
};


const clearCoverLetterAction = (accessToken?: string) => {
  // the inside "thunk function"
  return async (dispatch: any, getState: any) => {

    // 1. Save latest version of user edited draft to db.
    if (accessToken) {
      await saveEditsFromLocalStorage(accessToken);
    }

    // 2. Clear cover letter from local storage and redux state.
    await dispatch(currentCoverLetterSlice.actions.clearCoverLetter());
    await clearLocalAPIData('currentCoverLetter');

  }
};


const storeUserEditsLocallyAction = (coverLetter: CoverLetter, userEditedDraft: string) => {
  // store edits locally
  // on complete return 'success' or 'failure'

  // the inside "thunk function"
  return async (dispatch: any, getState: any): Promise<'success' | 'failure'> => {

    const draftUpdatedFromEdit:boolean = coverLetter.user_edited_draft !== userEditedDraft;
    const draftUpdatedFromAI:boolean = coverLetter.ai_final_draft !== userEditedDraft;
    const updatesToBeMade:boolean = draftUpdatedFromAI && draftUpdatedFromEdit;

    // return success if no updates to be made.
    if (!updatesToBeMade) return 'success';

    // update cover letter user edits in local and redux state.
    const editedCoverLetter: CoverLetter = {
      id: coverLetter.id,
      generation_attempts: coverLetter.generation_attempts,
      job_description: coverLetter.job_description,
      ai_final_draft: coverLetter.ai_final_draft,
      user_edited_draft: userEditedDraft
    }

    // Update local storage
    await setLocalData(
      'currentCoverLetter',
      editedCoverLetter);

    // IF MAKES THINGS SLOW
    // DO NOT UPDATE STATE--WE DO NOT WANT A RERENDER.
    await dispatch(currentCoverLetterSlice.actions.setCoverLetterSucceeded({
      data: editedCoverLetter,
      errorMessage: null,
      status: 'success'
    }))
    return 'success';
  }
};

const saveEditsFromLocalStorage = async(accessToken: string): Promise<'success' | 'failure'> => {
  // update status to pending so loaders start.
  const response = await getLocalAPIData(
    'currentCoverLetter');
  const localCoverLetter = response.data;
  if (!localCoverLetter) return 'failure';

  await CoverLetters.put(
    accessToken,
    localCoverLetter.id,
    localCoverLetter.user_edited_draft)
  return 'success';
}


const saveEditsFromLocalStorageAction = (
  accessToken: string) => {
  // the inside "thunk function"
  return async (dispatch: any, getState: any) => {
    // update status to pending so loaders start.
    return saveEditsFromLocalStorage(accessToken);
  }
};

const currentCoverLetterActions = {
    // new cover letter is created; no ai draft is generated
    createCoverLetter: createCoverLetter,
    // updates cover letter attributes that are not ai draft
    updateCoverLetter: updateCoverLetter,
    // new cover letter is created then ai draft is generated
    createCoverLetterWithAIDraft: createCoverLetterThenAIDraft,
    // ai draft is generated for existing cover letter
    updateCoverLetterAIDraft: updateAIDraft,
    // clear cover letter
    newApplication: clearCoverLetterAction,
    signOut: clearCoverLetterAction,
    // load cover letter from local storage on page refresh.
    loadWebApp: loadCurrentCoverLetterAction,
    // save edits from local storage to the db
    updateUserEditedDraft: saveEditsFromLocalStorageAction, // <-- on start new app, on download, on copy, on unmount editor
    // save edits to local storage
    saveUserEditsLocally: storeUserEditsLocallyAction // <-- onchange
}

export default currentCoverLetterActions;



// polling functionality scratch work:


// interface MyFuncResponse {
//   updatedAttempts: number;
//   updatedResponse: string | null;
// }


// const myfunc = async(
//   myAttempts: number,
//   maxAttempts: number): Promise<MyFuncResponse> => {
//   console.log(myAttempts);
//   const updatedAttempts = myAttempts + 1;
//   const updatedResponse = updatedAttempts === maxAttempts ? 'response is set!' : null;
//   sleep(5000);
//   return {
//     'updatedAttempts': updatedAttempts,
//     'updatedResponse': updatedResponse
//   };
// }

  // let myAttempts = 0;
  // const myMaxAttempts = 3;
  // let myResponse: string | null = null;
  // let safety = 0;
  // while (!myResponse && safety < 20) {
  //   safety++;
  //   const myFuncResponse: MyFuncResponse = await myfunc(
  //     myAttempts,
  //     myMaxAttempts
  //   )
  //   myAttempts = myFuncResponse.updatedAttempts;
  //   myResponse = myFuncResponse.updatedResponse;
  // }
  // console.log(myResponse);

  // function should only return response when done polling.