const FONT_CLASSES = {
    displayLarge: 'displayLarge',
    displaySmall: 'displaySmall',
    headlineLarge: 'headlineLarge',
    headlineSmall: 'headlineSmall',
    titleLarge: 'titleLarge',
    titleSmall: 'titleSmall',
    subheadingLarge: 'subheadingLarge',
    subheadingSmall: 'subheadingSmall',
    bodyLarge: 'bodyLarge',
    bodySmall: 'bodySmall',
    labelLarge: 'labelLarge',
    labelSmall: 'labelSmall',
    footnote: 'footnote',
    caption1: 'caption1',
    caption2 : 'caption2 '
}

export default FONT_CLASSES;