import services, { StoreDataResponse, GetOrSetDataResponse } from "./services";


const getOrSetDataTheme = async function(isLightMode: boolean): Promise<GetOrSetDataResponse> {
	const value = isLightMode ? 'light' : 'dark';
	const response = await services._getOrSetData('data-theme', value);
	if (response.data) {
		_setDocumentElementDataTheme(response.data === 'light');
	}
	return response;
}

const setDataTheme = async function(isLightMode: boolean): Promise<StoreDataResponse> {
	const value = isLightMode ? 'light' : 'dark';
	const response = await services._storeData('data-theme', value);
	if (!response.error) {
		_setDocumentElementDataTheme(isLightMode);
	}
	return response;
}

const _setDocumentElementDataTheme = function(isLightMode: boolean): void {
	// Update css color theme.
	const trans = () => {
	  document.documentElement.classList.add("transition");
	  window.setTimeout(() => {
		document.documentElement.classList.remove("transition");
	  }, 1000);
	};
	if (isLightMode) {
	  trans();
	  document.documentElement.setAttribute("data-theme", "dark");
	  
	} else {
	  trans();
	  document.documentElement.setAttribute("data-theme", "light");
	}
	
  }


const localColorMode = {
    getOrSetDataTheme: getOrSetDataTheme,
    setDataTheme: setDataTheme
}

export default localColorMode;