import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../state/redux/hooks';
import signOut from '../../../state/redux/data/globalActionCreators/signOut';
import TextButton from '../TextButton/TextButton';
import Logo from '../Logo/Logo';
import '../../globalStyles/fonts/classes.css';
import '../../globalStyles/colors/vars.css';
import './navbar.css';
import '../../globalStyles/responsiveLayout/containers.css';
import ModeToggler from '../ModeToggler/ModeToggler';
import HamburgerMenuNew from '../HamburgerMenu/HamburgerMenuNew';
import '../HamburgerMenu/hamburgerMenu.css';
import { useEffect } from 'react';


const Navbar = () => {
	const [isVisible, setIsVisible] = useState(true);
	const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
	const dispatch = useAppDispatch();
	const accessToken = useAppSelector(state => state.auth.data?.accessToken);

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		}
	}, [])

	const handleAuthButtonClick = () => {
		if (accessToken) dispatch(signOut());
	}

	const handleScroll = () => {
		const currentScrollPos: number = window.pageYOffset;
		const currentIsVisible: boolean =
			currentScrollPos <= 25 || prevScrollPos > currentScrollPos;
		setPrevScrollPos(currentScrollPos);
		setIsVisible(currentIsVisible);
	};

	const [screenWidth, setScreenWidth] = useState(window.innerWidth);

	useEffect(() => {
		const handleResize = () => setScreenWidth(window.innerWidth);
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<div className={`navbar outer ${isVisible ? 'visible' : 'hidden'}`}>
			<div className='navbar inner'>
				<div className='navbar logo'>
					<a href=''>
						<Logo className='navBar--logoImg'/>
					</a>
				</div>
				{screenWidth <= 666 && <HamburgerMenuNew style={{ }} />}
				<ul className={`navbar links labelLarge ${screenWidth > 666 ? 'desktop' : 'mobile'}`}>
					<li className='navbar linkWrapper'>
						<a href='#home-cover-letter-generator'>
							Get Started
						</a>
						{/* <div className='navbar underline'></div> */}
					</li>
					<li className='navbar linkWrapper'>
						<a href='#home-why-resumakr'>
							Learn More
						</a>
						{/* <div className='navbar underline'></div> */}
					</li>
					<li className='navbar linkWrapper'>
						<a href="https://forms.gle/gUtJ9Q9k8kiTFZLP6" target='_blank'>
							Contact Us
						</a>
						{/* <div className='navbar underline'></div> */}
					</li>
					<div className='navbar linksDivider'></div>
					<li className='navbar linkWrapper'>
						<TextButton
							// fontClass='labelLarge'
							// textColorClass='txtColor--brand'
							fontClass=''
							textColorClass=''
							onClick={handleAuthButtonClick}
							label={accessToken? 'Sign Out' : ''}
						/>
						{/* <div className='navbar underline'></div> */}
					</li>
					<ModeToggler />
					{/* <LinkButton
						className='navbar buttons item'
						label={'Login'}
						type='primary'
						href=''
					/> */}
				</ul>
			</div>
		</div>
	);

};


export default Navbar;


