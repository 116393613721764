import React, { useState } from 'react';
// import COLORS from '../../globalStyles/colors/varConstants';
import { BACKGROUNDS, TEXT_COLORS, SHADOWS, BUTTONS  } from '../../globalStyles/colors/classConstants';
import FONT_CLASSES from '../../globalStyles/fonts/classConstants';
import ModeToggler from '../../common/ModeToggler/ModeToggler';
import CLLMultiSelectButtons from '../../forms/CLLMultiSelectButtons/CLLMultiSelectButtons';
import CLLDateBox from '../../forms/CLLDateBox/CLLDateBox';
import CLLEmailBox from '../../forms/CLLEmailBox';
import CLLShortTextBox from '../../forms/CLLShortTextBox/CLLShortTextBox';
import CLLNameBox from '../../forms/CLLShortTextBox/CLLShortTextBox';
import CLLNumberBox from '../../forms/CLLNumberBox/CLLNumberBox';
import CLLPasswordBox from '../../forms/CLLPasswordBox';
import CLLRadioButtons from '../../forms/CLLRadioButtons/CLLRadioButtons';
import CLLDropdown from '../../forms/CLLDropdown/CLLDropdown';
import CLLInput from '../../forms/CLLInput/CLLInput';
import CLLSearchableDropdown, { Option } from '../../forms/CLLSearchableDropdown/CLLSearchableDropdown';
import HamburgerMenu from '../../common/HamburgerMenu/HamburgerMenu';
// import CLLErrorMessage from '../../forms/CLLErrorMessage';
// import CLLSearchBar from '../../forms/CLLSearchBar/CLLSearchBar';
import '../../globalStyles/colors/vars.css';
import '../../globalStyles/colors/classes.css';
import '../../globalStyles/fonts/classes.css';
import '../../globalStyles/responsiveLayout/containers.css';
import '../../globalStyles/responsiveLayout/grids.css';
import './designSystem.css'
import '../../globalStyles/forms/classes.css';
import SkillsSelector from '../../forms/SkillsSelector/SkillsSelector';
import BasicButton from '../../common/BasicButton/BasicButton';
import ExperiencesForm from '../../forms/ExperiencesForm/ExperiencesForm';
import PositionsForm from '../../forms/PositionsModal/PositionsModal';
import CLLMonthYearInput from '../../forms/CLLMonthYearInput/CLLMonthYearInput';
import ResumeBuilderModal from '../../forms/ResumeBuilderModal/ResumeBuilderModal';
import CLLOnboarding from '../../forms/CLLOnboarding/CLLOnboarding';
import DescriptiveLoader from '../../common/DescriptiveLoader/DescriptiveLoader';
// import EducationForm from '../../forms/EducationForm/EducationForm';
import CLLRequirementsChecklist from '../../forms/CLLRequirementsChecklist/CLLRequirementsChecklist';
import CLLSearchableDropdownWTags from '../../forms/CLLSearchableDropdown/CLLSearchableDropdownWTags';


const DesignSystem = () => {

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const [satisfaction, setSatisfaction] = useState([]);
  // const [skills, setSkills] = useState([]);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [birthday, setBirthday] = useState('MM/DD/YYYY');
  const [qty, setQty] = useState(0);
  const [password, setPassword] = useState('');
  const [hasDog, setHasDog] = useState('none');
  const [cllDropdown, setCllDropdown] = useState(null);
  const [cLLSearchableDropdown, setCLLSearchableDropdown] = useState('');
  const [cllInput, setCllInput] = useState(null);
  const [cllInputBlock, setCllInputBlock] = useState(null);
  const [linkedin, setLinkedin] = useState('');
  const initSkills: Option[] = []
  const [skills, setSkills] = useState(initSkills);
  const [selectedSkills, setSelectedSkills] = useState([]);


  const renderFontClass = (fontClass: string, fontName: string) => {
    // a race condition makes getting the font sizefail to work sometimes...
    let fontSize: string = "undefined";
    const classElements: any = document.getElementsByClassName(fontClass);
    if (classElements.length > 0) {
      const classElement = classElements[0];
      fontSize = window.getComputedStyle(classElement, null).getPropertyValue('font-size');
    }
    return (
      <div className='gridItem oneWhole testingGridItems primaryColor' >
          Font Style Class: {fontName} <br />
          Size: {fontSize} <br />
          {/*Usage Guidelines: {fontStyle.usageNote} <br /> */}
          Sample: <br />
        <div >
          <span className={fontClass} >
           The quick brown fox jumps over the lazy dog
          </span>
        </div>
        <br />
      </div>
    );
  }
  const renderFontClasses = () => {
    const fontClasses: any = FONT_CLASSES;
    return (
      <>
        {Object.keys(fontClasses).map((fontName) => {
          const fontClass = fontClasses[fontName];
          const fontStyle = renderFontClass(fontClass, fontName);
          return fontStyle;
        })}
      </>
    );
  }
  const renderColorClass = (
    suffix: string,
    type: 'background' | 'text' | 'shadow' | 'button'
  ) => {
    let className = type === 'background' ? `bgColor--${suffix}` : '';
    className = type === 'text' ? `txtColor--${suffix}` : className;
    className = type === 'shadow' ? `shadow--${suffix}` : className;
    className = type === 'button' ? `buColor--${suffix} buColor--shadow` : className;

    return (
      <div
        className='gridItem oneThird testingGridItems'>
        <div>
          {className}
        </div>
        <div className={`${className} designSystemColorSq`}>
          { type === 'text' || type === 'button' ? 'text sample' : ''}
        </div>
      </div>
    );
  }
  const renderColorClasses = (type: 'background' | 'text' | 'shadow' | 'button') => {
    let COLORS: any = type === 'background' ? BACKGROUNDS : {};
    COLORS  = type === 'text' ? TEXT_COLORS : COLORS;
    COLORS  = type === 'shadow' ? SHADOWS : COLORS;
    COLORS  = type === 'button' ? BUTTONS : COLORS;
    return (
      <>
        <div className='gridItem oneWhole displayLarge txtColor--textPrimary designSystemColorHeader'>
          {type} Colors
        </div>
        {Object.keys(COLORS).map((name) => {
          return renderColorClass(COLORS[name], type);
        })}
      </>
    );
  }

  const handleSkillsChange = (skills: any) => {
    setSelectedSkills(skills);
  };

  const onSubmitExperience = () => {
    setNextStep();
  }
  const setNextStep = () => {
    const nextStepIndex = currentStep + 1;
    setCurrentStep(nextStepIndex);
  }
  const [currentStep, setCurrentStep] = useState(0);


  return(
    <div className='contentContainer outer testingContainers labelLarge'>
      <div className='contentContainer inner testingContainers'>
        <div className='gridContainer'>
          <div
            className='gridItem oneWhole testingGridItems'>
              gridItem.oneWhole
          </div>
          <div
            className='gridItem oneThird withTwoThirds testingGridItems'>
              gridItem.oneThird
          </div>
          <div
            className='gridItem twoThirds testingGridItems'>
              gridItem.twoThirds
          </div>
          <div
            className='gridItem oneHalf testingGridItems'>
              gridItem.oneHalf
          </div>
          <div
            className='gridItem oneHalf testingGridItems'>
              gridItem.oneHalf
          </div>
          <div
            className='gridItem oneThird testingGridItems'>
              gridItem.oneThird
          </div>
          <div
            className='gridItem oneThird testingGridItems'>
              gridItem.oneThird
          </div>
          <div
            className='gridItem oneThird testingGridItems'>
              gridItem.oneThird
          </div>
          <div
            className='gridItem oneFourth testingGridItems'>
              gridItem.oneFourth
          </div>
          <div
            className='gridItem oneFourth testingGridItems'>
              gridItem.oneFourth
          </div>
          <div
            className='gridItem oneFourth testingGridItems'>
              gridItem.oneFourth
          </div>
          <div
            className='gridItem oneFourth testingGridItems'>
              gridItem.oneFourth
          </div>
          {renderFontClasses()}
          {renderColorClasses('background')}
          {renderColorClasses('text')}
          {renderColorClasses('shadow')}
          {renderColorClasses('button')}
        </div>
        <div
          style={{}}
          className= 'gridItem oneWhole testingGridItems'
        >
          <CLLDropdown
            label="Dropdown Label"
            subLabel="Select"
            onChange={cllDropdown => setCllDropdown(cllDropdown)}
            options={[
              {
                id: 1,
                label: "item 1"
              }, {
                id: 2,
                label: "item 2"
              }, {
                id: 3,
                label: "item 3"
              }, {
                id: 4,
                label: "item 4"
              }, {
                id: 5,
                label: "item 5"
              }
            ]}
          />
          <br></br>
          <CLLSearchableDropdown
            label="Start typing to find tag"
            subLabel="Select up to three tags."
            input={cLLSearchableDropdown}
            onChange={setCLLSearchableDropdown}
            // addOptions={true}
            options={[
              {
                id: 1,
                label: "red"
              }, {
                id: 2,
                label: "blue"
              }, {
                id: 3,
                label: "green"
              }, {
                id: 4,
                label: "orange"
              }, {
                id: 5,
                label: "yellow"
              }
            ]}
          />
          <br></br>
          <CLLSearchableDropdownWTags
            label="Please select your top 12 skills from the options provided."
            subLabel="Start typing to find tag."
            maxSelected={12}
            onChange={setSkills}
            // addOptions={false}
            minSelected={1}
            selectedOptions={skills}
            options={[
              {
                id: 1,
                label: "figma"
              }, {
                id: 2,
                label: "business strategy"
              }, {
                id: 3,
                label: "technical writing"
              }, {
                id: 4,
                label: "user personas"
              }, {
                id: 5,
                label: "roadmaps"
              }, {
                id: 6,
                label: "user research"
              }, {
                id: 7,
                label: "market research"
              }, {
                id: 8,
                label: "OKRs"
              }, {
                id: 9,
                label: "business analytics"
              }, {
                id: 10,
                label: "sketch"
              }, {
                id: 11,
                label: "excel"
              }, {
                id: 12,
                label: "powerpoints"
              }, {
                id: 13,
                label: "public speaking"
              }, {
                id: 14,
                label: "agile SCRUM"
              }
            ]}
          />
          <br></br>
          <CLLShortTextBox
            label='Linkedin'
            placeholder='https://www.linkedin.com/in/your-name'
            onChange={text => setLinkedin(text)}
            oneWord={true}
            minLength={'https://www.linkedin.com/in/'.length + 3}
            maxLength={100}
            autoCapitalize='none'
            initValue='https://www.linkedin.com/in/'
            includedValues={['https://www.linkedin.com/in/',]}
          />
          <br></br>
          <CLLInput
            label='Label'
            subLabel='subLabel'
            onChange={cllInput => setCllInput(cllInput)}
            placeholder='Placeholder Text'
            maxLength={50}
            autoCapitalize='sentences'
            contentType='text'
          />
          <br></br>
          <CLLEmailBox
            onChange={text => setEmail(text)}/>
          <br></br>
          <CLLPasswordBox
            onChange={text => setPassword(text)}/>
          <br></br>
          <CLLInput
            label='Label'
            subLabel='subLabel'
            onChange={cllInput => setCllInputBlock(cllInput)}
            placeholder='This is a text block.'
            rows={5}
          />
            <br></br>
          <HamburgerMenu />
            <br></br>

          {/* <ExperienceForm
            onSubmit={onSubmitExperience}
            submitLabel='Next'
          /> */}
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <SkillsSelector
            id='skills'
            label='Skills Survey'
            subLabel='Tap on the options below to add them to your skillset.'
            options={[
              { value: 'figma', label: 'Figma', id: 'figma'},
              { value: 'python', label: 'Python', id: 'python' },
              { value: 'javascript', label: 'Javascript', id: 'javascript' },
              { value: 'sql', label: 'SQL', id: 'sql' },
              { value: 'User Personas', label: 'User Personas', id: 'userPersonas' },
              { value: 'excel', label: 'Excel', id: 'excel' },
              { value: 'sketch', label: 'Sketch', id: 'sketch' },
              { value: 'Business Strategy', id: 'businessStrategy', label: 'Business Strategy' },
              { value: 'Roadmaps', id: 'roadmaps', label: 'Roadmaps' },
              { value: 'Market Research', id: 'marketResearch', label: 'Market Research' },
              { value: 'Technical Writing', id: 'technicalWriting', label: 'Technical Writing' },
              { value: 'User Research', id: 'userResearch', label: 'User Research' },
            ]}
            defaultValue={[]}
            onChange={handleSkillsChange}
          />
          <br></br>
          <br></br>
          <br></br>
          <CLLMonthYearInput
            onDateChange={handleDateChange}
            label='Date'
            subLabel='Please enter the month and year.'
            showCheckbox={true}
            id=''
            checkboxLabel='Check to disabled input'
          />
          <br></br>
          <br></br>
          <br></br>
          <ResumeBuilderModal
            onSubmit={onSubmitExperience}
            selectedSkills={selectedSkills}
            // onSubmit={onSubmitExperience}
          />
          <br></br>
          <br></br>
          <br></br>
          <DescriptiveLoader
            isLoading={true}
            label=''
          />
          <br></br>
          <br></br>
          <br></br>
          <CLLRequirementsChecklist
            category='Resume'
          />
          <br></br>
          <br></br>
          <CLLRequirementsChecklist
            category='CoverLetter'
          />
          <br></br>
        </div>
        <div
          style={{}}
          className= 'gridItem oneWhole testingGridItems'
        >
          {/*
          THE COMPONENTS BELOW NEED TO BE REFINED BEFORE USE
          */}
          {/* <CLLMultiSelectButtons
            onChange={description => setSatisfaction(description)}
            label="How satisfied are you with our services?"
            instructions="Select the option that best reflects your feelings."
            items={[{id: 'bad', value: 'bad'}, {id: 'neutral', value: 'neutral'}, {id: 'good', value: 'good'}]}
            maxItems={1}/>
          <CLLMultiSelectButtons
            onChange={selectSkills => setSkills(selectSkills)}
            label="What are your top skills?"
            instructions="Select up to 3 options."
            items={[
              {id: 'figma', value: 'figma'},
              {id: 'javascript', value: 'javascript'},
              {id: 'python', value: 'python'},
              {id: 'excel', value: 'excel'},
              {id: 'sql', value: 'sql'}
            ]}
            maxItems={3}/>
          <CLLDateBox
            label={'Your Birthday'}
            onChange={date => setBirthday(date)}
          />
          <CLLNumberBox
            label="How many dogs do you have?"
            onChange={num => setQty(num)}
            min={0} />
          <CLLRadioButtons
            label="Do you have a dog?"
            onChange={hasDog => setHasDog(hasDog)}
            allowOptOut={true}
            defaultValue={hasDog}
            /> */}
        </div>
      </div>
        <ModeToggler />
    </div>
  );
}


export default DesignSystem;