import React, { Component} from 'react';
import Logo from '../Logo/Logo';
import '../../globalStyles/fonts/classes.css';
import '../../globalStyles/colors/vars.css';
import './footer.css';
import '../../globalStyles/responsiveLayout/containers.css';


class Footer extends Component <any, any> {
  render() {

		return (
			<div className='footer--outer'>
				<div className='footer--inner'>
					<div className='footer--content'>
						<div className='footer--marginBottom'>
							<a href=''>
								<Logo className=''/>
							</a>
						</div>
						<div className='footer--linkWrapper'>
							<div className='titleSmall'>
								Social
							</div>
							<div className='link'>
								<a href="https://www.linkedin.com/company/resumakr-solutions/" target='_blank' className="footer--link labelLarge">
									LinkedIn
								</a>
							</div>
							<div className='link'>
								<a href="https://twitter.com/resumakr" target='_blank' className="footer--link labelLarge">
									X
								</a>
							</div>
						</div>
						<div className='footer--linkWrapper'>
							<div className='titleSmall'>
								About Us
							</div>
							<div className='link'>
								<a href="/" className="footer--link labelLarge">
									About
								</a>
							</div>
							<div className='link'>
								<a href="https://forms.gle/gUtJ9Q9k8kiTFZLP6" target='_blank' className="footer--link labelLarge">
									Contact Us
								</a>
							</div>
							<div className='link'>
								<a href="https://www.termsfeed.com/live/7a814c37-c7dc-4e86-bf96-dd35a43fd2b1" target='_blank' className="footer--link labelLarge">
									Privacy Policy
								</a>
							</div>
							{/* <div className='link'>
								<a href="" className="footer--link labelLarge">
									Pricing
								</a>
							</div>
							<div className='link'>
								<a href="" className="footer--link labelLarge">
									Team
								</a>
							</div> */}
						</div>
						<div className='footer--linkWrapper'>
							<div className='titleSmall'>
								Help
							</div>
							<div className='link'>
								<a href="https://forms.gle/gUtJ9Q9k8kiTFZLP6" target='_blank' className="footer--link labelLarge">
									Feedback
								</a>
							</div>
							<div className='link'>
								<a href="https://forms.gle/gUtJ9Q9k8kiTFZLP6" target='_blank' className="footer--link labelLarge">
									Help
								</a>
							</div>
						</div>
					</div>
					<div className='footer--captionWrapper'>
						<div className='link horizontal'>

							{/* <div className='link'>
								<a href="" className="footer--link labelSmall">
									Terms of Service
								</a>
							</div>
							<div className='link'>
								<a href="" className="footer--link labelSmall">
									Cookies Policy
								</a>
							</div> */}
						</div>
						<div className='link horizontal'>
							{/* <div className='link'>
								<a href="https://bmc.link/resumakr" target='_blank' className="footer--link labelSmall">
									Buy us a coffee
								</a>
							</div> */}
						</div>
					</div>
					<div className='spacer'></div>
					<div className='footer--captionWrapper subWrapper'>
						<div className='link horizontal'>
						</div>
						<div className='link horizontal'>
							<div className='footnote'>
									© 2023 Crystal Logic Labs LLC
							</div>
						</div>
					</div>
				</div>
			</div>
		);
  };
};


export default Footer;
