import { ApiResponse } from "../../apiServices/request";

// place inside "thunk creator" function in Action Creator
export const updateStateByAPI = async (
	dispatch: any,
	apiRequest: any,
	startedAction: any,
	succeededAction: any,
	failedAction: any) => {
	// update status to pending so loaders start.
	await dispatch(startedAction());
	try {
		// make an async call in the thunk
		const response: ApiResponse = await apiRequest();
		if (response.status === 200 && response.data) {
			// dispatch an action when we get the response back
			await dispatch(succeededAction({
				data: response.data,
				errorMessage: null,
				status: 'success'
			}));
		} else {
			await dispatch(failedAction({
				data: null,
				errorMessage: response.statusMessage,
				status: 'failure'
			}));
		}
	} catch (err) {
		// If something went wrong, handle it here
		await dispatch(failedAction({
				data: null,
				errorMessage: `Server Error: ${err}`,
				status: 'failure'
		}));
	}
};
