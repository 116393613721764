import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { CoverLetter } from '../../../apiServices/dataTypes/CoverLetter';
// import { RootState } from '../../store';



// Define intiial state
export interface CurrentCoverLetterState {
  data: CoverLetter | null;
  status: 'pending' | 'failure' | 'success';
  errorMessage: string | null;
};
const initialState: CurrentCoverLetterState = {
  data: null,
  errorMessage: null,
  status: 'success'
};

// Define slice using intitial state
const currentCoverLetterSlice = createSlice({
  name: 'currentCoverLetter',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    clearCoverLetter: (prevState) => {
      return initialState;
    },
    setCoverLetterStarted: (prevState) => {
      let state = prevState;
      state.errorMessage = null;
      state.status = 'pending';
      return state;
    },
    setCoverLetterFailed: (prevState) => {
      let state = prevState;
      state.errorMessage = 'Failed to connect to server.';
      state.status = 'failure';
      return state;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    setCoverLetterSucceeded: (prevState, action: PayloadAction<CurrentCoverLetterState>) => {
      return action.payload;
    }
  },
});

export default currentCoverLetterSlice;

// Other code such as selectors can use the imported `RootState` type
// export const selectAuthState = (state: RootState) => state.auth;

