export interface ApiResponse {
	data: any;
	status: number;
	statusMessage: string;
};


const request = async function(
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  endpoint: string,
  body: any,
  headers: any
): Promise<ApiResponse> {
  // IMPORTANT NOTES:
  // POST, PUT, and DELETE requests should pass all params through the body
  // GET requests should pass all params through the header.
  // For endpoints that require authentication pass
  // token in header like this:
  // { Authentication: 'Token <insert token here>' }

  try {
    let reqHeaders = headers || {};
    reqHeaders['Accept'] = 'application/json';
    reqHeaders['Content-Type'] = 'application/json';

    let requestData: any = {
      method: method,
      headers: reqHeaders,
    }


    if (method !== 'GET') {
      // Don't add body to GET requests.
      requestData.body = JSON.stringify(body);
    }

    const response = await fetch(`api/v1/${endpoint}`, requestData);
    const responseJson = await response.json();

    return {
      data: responseJson.data ,
      status: response.status,
      // statusMessage is not in response if 401 unauthorized error thrown.
      statusMessage: responseJson.statusMessage || 'Unauthorized. Please sign in and try again.'
    };
  } catch (error) {
    return {
      data: null,
      status: 500, // server error.
      statusMessage: `Network error. Please make sure you are connected to the internet and that request is correctly set up. Error: ${error}`
    };
  }
};

export { request };
