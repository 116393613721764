import React from 'react';
import CLLInput from '../CLLInput/CLLInput';


interface OnChangeFunction {
	/* return errorMessage. if no errorMessage return empty string '' */
    (value: any, hasActiveError?: boolean): any;
}
interface InputValues {
	onChange: OnChangeFunction;
  placeholder?: string;
  label?: string;
  subLabel?: string;
  /* optional, very specific params: */
  initValue?: string;
  minLength?: number;
  maxLength?: number;
  rows?: number;
}

const CLLLongTextBox = (props: InputValues) => {
	const {
		onChange,
		placeholder,
    label,
    subLabel,
    minLength,
    maxLength,
    initValue,
    rows
	} = props;
  const max = maxLength || 1000;
  const min = minLength || 0;

  const validate = (cllInputValue: string, cllInputInitValue: string):string => {
    /* don't show error message if user has not yet input anything. */
    if (cllInputValue === cllInputInitValue) return '';
    let errorMessage = '';
    // errorMessage = (value.length > max) ? `Must be under ${max} characters in length.` : errorMessage;
    errorMessage = (cllInputValue.length < min) ? `Must be over ${min} characters in length.` : errorMessage;
    return errorMessage;
  };

  return (
    <>
      <CLLInput
        label={label || ''}
        subLabel={subLabel || ''}
        onChange={onChange}
        validate={validate}
        placeholder={placeholder || 'Input your response here'}
        rows={rows}
        maxLength={max}
        minLength={min}
        autoCapitalize='sentences'
        initValue={initValue || ''}
      />
    </>
  );

}

export default CLLLongTextBox;
