import type { ApiResponse } from "../apiServices/request";
import services, { ClearDataResponse, RetrieveDataResponse } from "./services";

const requestDataToString = (data: any):string => {
	return JSON.stringify(data);
}

const stringToRequestData = (str: string):any => {
	return JSON.parse(str);
}


export const getOrSetLocalAPIData = async (key: string, apiRequest: any): Promise<ApiResponse> => {
	// If data stored, return it in the shape of an ApiResponse data type.
	const resp: ApiResponse = await getLocalAPIData(key);
  if (resp.data) return resp;

	// Else set data and return in the shape of Api response data type.
	return await setLocalAPIData(key, apiRequest);
};

export const setLocalAPIData = async (key: string, apiRequest: any): Promise<ApiResponse> => {
    // Else get, store, and return api data.
	const apiResponse: ApiResponse = await apiRequest();
	if (apiResponse.status !== 200) {
		console.log(`error thrown on setLocalAPIData api request`);
		console.log(apiResponse);
	}
	if (apiResponse.data) {
		// 2. if data, store it.
		const storageResponse = await services._storeData(
			key, requestDataToString(apiResponse.data));
		if (storageResponse.error) {
			console.log(`error thrown on setLocalAPI store data `);
			console.log(storageResponse);
		}
	}
	// Return api response.
	return apiResponse;
};

export const getLocalAPIData = async (key: string): Promise<ApiResponse> => {
	// If data stored, return it in the shape of an ApiResponse data type.
	const storage: RetrieveDataResponse = await services._retrieveData(key);

    // If data stored, return as ApiResponse data type -- 200 ok.
	if (storage.data) return {
		data: stringToRequestData(storage.data),
		status: 200,
		statusMessage: "success!"
	}

    // If data not stored, return as Api response data type -- 500 failure.
    return {
        data: null,
        status: 500,
        statusMessage: "failure!"
    }
};

export const clearLocalAPIData = async (key: string): Promise<ClearDataResponse> => {
	return await services._clearData(key);
}