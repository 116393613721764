import { request }  from '../request';
import type { User } from '../dataTypes/ApiDataTypes';

export interface SignUpData {
  user: User;
  accessToken: string;
}

// All api responses should be shaped like this but with request specific data structure.
interface SignUpApiResponse {
  data: SignUpData | null;
  status: number;
  statusMessage: string;
};


const signUp = async function(
  email: string,
  password: string
  ): Promise<SignUpApiResponse> {
  const endpoint = 'signup/';
  const method = 'POST';
  const body = {
    'email': email,
    'password': password
  };
  const headers = {};
  // CASEY TODO:
  // COULD ADD VALIDATION HERE (instead of in the form components themselves...)
  // TO AVOID MAKING UNNECESSARY REQUESTS
  const response: SignUpApiResponse = await request(method, endpoint, body, headers);
  return response
};

export default signUp;


