import React, { Component} from 'react';
import './valueProps.css';
import '../../../globalStyles/fonts/classes.css';
import '../../../globalStyles/colors/classes.css';
import '../../../globalStyles/responsiveLayout/grids.css';
import '../../../globalStyles/responsiveLayout/spacers.css';
import ImgNetwork from './ImgNetwork';



const PropNetwork = () => {
	return (
		<div className=''>
			<div className='valueProps--imgContainer'>
				<ImgNetwork className='valueProps--img'/>
			</div>
			<div className='txtColor--textPrimary valueProps--textContainer'>
				<div className='txtColor--brand displayLarge valueProps--header'>
					Stand Out
				</div>
				<div className='spacerVertical' />
				<div className='subheadingLarge valueProps--subHeader'>
					Networking is the most effective way to get hired -- after all, about 80% of positions are filled without ever reaching a job board. Resumakr helps you <span className='txtColor--brand'> make personal connections and increase your chances of success</span>, by writing engaging, effective messages to hiring managers.
				</div>
				<br />
			</div>

		</div>
	);
};

export default PropNetwork;
