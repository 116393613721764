/* This file must match vars in colors/vars.css.
Separate colors from shadows.
Exclude active and hover states.
It is used to programmatically show examples 
of the classes in our design system example page.*/

export const BACKGROUNDS = {
    brand: 'brand',
    brandLight: 'brandLight',

    bgPrimary: 'bgPrimary',
    bgSecondary: 'bgSecondary',
    bgTertiary: 'bgTertiary',

    info: 'info',
    infoLight: 'infoLight',

    warning: 'warning',
    warningLight: 'warningLight',

    success: 'success',
    successLight: 'successLight',

    error: 'error',
    errorLight: 'errorLight',

    neutralBlack: 'neutralBlack',
    neutralWhite: 'neutralWhite',

    grey01: 'grey01',
    grey02: 'grey02',
    grey03: 'grey03',
    grey04: 'grey04',
    grey05: 'grey05'
}

export const TEXT_COLORS = {
    brand: 'brand',
    brandLight: 'brandLight',

    textPrimary: 'textPrimary',
    textSecondary: 'textSecondary',
    textTertiary: 'textTertiary',

    info: 'info',
    infoLight: 'infoLight',

    warning: 'warning',
    warningLight: 'warningLight',

    success: 'success',
    successLight: 'successLight',

    error: 'error',
    errorLight: 'errorLight',

    neutralBlack: 'neutralBlack',
    neutralWhite: 'neutralWhite',

    grey01: 'grey01',
    grey02: 'grey02',
    grey03: 'grey03',
    grey04: 'grey04',
    grey05: 'grey05'
}

export const SHADOWS = {
    elevation01: 'elevation01',
    elevation02: 'elevation02',
    elevation03: 'elevation03',
    elevation04: 'elevation04',
    elevation05: 'elevation05',
}


export const BUTTONS = {
    brand: 'brand',
    brandLight: 'brandLight',

    info: 'info',
    infoLight: 'infoLight',

    warning: 'warning',
    warningLight: 'warningLight',

    success: 'success',
    successLight: 'successLight',

    error: 'error',
    errorLight: 'errorLight',

    neutralBlack: 'neutralBlack',
    neutralWhite: 'neutralWhite',
}




