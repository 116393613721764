import { request }  from '../request';
import type { Question, Answer } from '../dataTypes/ApiDataTypes';


export interface PostAppQuestionData {
  question_id: string,
  text: string
}

interface AppQuestion {
    question: Question;
    answer: Answer;
}

interface AppQuestionsResponse {
  data: AppQuestion[] | null;
  status: number;
  statusMessage: string;
};


const getAppQuestions = async function(
  accessToken: string): Promise<AppQuestionsResponse> {
  const endpoint = 'appquestions/';
  const method = 'GET';
  const body = {};
  const headers = {
    'Authorization': `Token ${accessToken}`
  }
  const response: AppQuestionsResponse = await request(method, endpoint, body, headers);
  return response
};

const postAppQuestions = async function(
  answers: PostAppQuestionData[],
  accessToken: string
): Promise<AppQuestionsResponse> {
  const endpoint = 'appquestions/';
  const method = 'POST';

  // Prepare body.
  let body:any = {};
  answers.map((answer) => {
    const questionId = answer.question_id;
    const answerText = answer.text;
    body[questionId] = answerText;
    return null;
  });


  const headers = {
    'Authorization': `Token ${accessToken}`
  }
  const response: AppQuestionsResponse = await request(
    method, endpoint, body, headers);
  return response
};

const postSampleAnswers = async function(
  accessToken: string,
  jobDescriptionId: number
): Promise<AppQuestionsResponse> {
  const endpoint = 'appquestionaianswers/';
  const method = 'POST';

  // Prepare body.
  const body: any = {
    'job-description-id': jobDescriptionId
  };

  const headers = {
    'Authorization': `Token ${accessToken}`
  }
  const response: AppQuestionsResponse = await request(
    method, endpoint, body, headers);
  return response
};

const AppQuestions = {
  get: getAppQuestions,
  post: postAppQuestions,
  postSampleAnswers: postSampleAnswers
  // put
  // delete
}

export default AppQuestions;