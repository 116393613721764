import React, { useState, useEffect, useRef } from 'react';
import './cllSearchableDropdown.css';
import '../../globalStyles/fonts/classes.css';
import '../../globalStyles/forms/classes.css';
import '../../globalStyles/colors/vars.css';
import { Function } from '../../../typescriptTypes';

export interface Option {
  id: number | string | null;
  label: string;
}

const CLLSearchableDropdownWTags = (props:
  {
    label: string,
    subLabel: string,
    /* drop down options */
    options: Option[],
    /* max # options that can be selected */
    maxSelected: number,
    /* min # options that can be selected */
    minSelected?: number,
    selectedOptions: Option[],
    onChange: Function,

    /* Add options allows user to add and select
    new option if it does not exist in options list
    default = false*/
    addOptions?: boolean,
  }
): any => {
  // component props:
  const {
    label,
    options,
    subLabel,
    maxSelected,
    minSelected,
    selectedOptions,
    onChange,
    addOptions } = props;
  // component vars:
  let selectedOptionIds: any[] = selectedOptions.map(({ id }) => id);

  // component state - FORM DATA
  const [input, setInput] = useState('');

  // component state - FORM FUNCTIONALITY
  const [showOptions, setShowOptions] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    // component did mount:
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      // component will unmount:
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  // Reg methods:
  const filteredOptions = options.filter(
    (option) =>
      option.label.toLowerCase().includes(input.toLowerCase()) &&
      !selectedOptionIds.includes(option.id)
  );
  const handleAddSelectedOption = (option: Option) => {
    console.log(`max_selected = ${maxSelected}`);
    console.log(`option = `);
    console.log(option);
    // If user can only select 1 option, do not clear the label on select.
    const inputValueOnSelect = (maxSelected === 1) ? option.label : '';

    const reachedMaxSelections = selectedOptions.length >= maxSelected;
    const canMakeMultSelections = maxSelected > 1;
    // Don't add selection if user has reached max selections
    // and can select more than one option.
    if (reachedMaxSelections && canMakeMultSelections) return;
    // Replace old selection with new selection if can select only one option.
    const newOptions = (
      canMakeMultSelections ?
        [...selectedOptions, option] :
        [option]);
    onChange(newOptions);
    setInput(inputValueOnSelect);
    setShowOptions(false);
    const requiredNumSelections: number = minSelected || 1;
    const newError = (newOptions.length < requiredNumSelections ?
      `Must select at least ${requiredNumSelections} option${requiredNumSelections > 1 ? 's' : ''}` :
      '');
    setError(newError);
  };
  const handleOptionClick = (option: Option) => {
    handleAddSelectedOption(option);
  };

  const handleBlur = (value: string) => {
    if (maxSelected === 1 && selectedOptions.length === 0) {
      handleOptionEnter(value);
    }
  }

  const getOptionFromList = (label: string) => {
    const optionsWithMatchingLabels = options.filter((option) => option.label === label)
    return optionsWithMatchingLabels.length > 0 ? optionsWithMatchingLabels[0] : null;
  }
  const optionIsAlreadySelected = (label: string) => {
    const optionsWithMatchingLabels = selectedOptions.filter(
      (option) => option.label.toLowerCase() === label.toLocaleLowerCase())
    return optionsWithMatchingLabels.length > 0 ? optionsWithMatchingLabels[0] : null;
  };
  const handleKeyDown = (event: any) => {
    // Do nothing if enter key was not clicked.
    const value = event.target.value;
    if (event.key === 'Enter') return handleOptionEnter(value);
    if (event.key === 'Tab') return setShowOptions(false);
  }
  const handleOptionEnter = (value: string) => {

    // If option from options list,
    // handle like the option was clicked.
    const optionFromList = getOptionFromList(value)
    if (optionFromList) return handleOptionClick(optionFromList);

    // if can't add options, throw error and clear input value.
    if (!addOptions) {
      setError('Invalid option. Must select value from Dropdown');
      setInput('');
      // if max selected is 1 clear selected options to match input.
      if (maxSelected === 1) {
        onChange([]);
      }
      return;
    }

    // if new option is already selected selected, do nothing.
    if (optionIsAlreadySelected(value)) return;

    // if new option has not yet been selected, select it.
    const newOption: Option = {
      id: value,
      label: value
    }

    // Else save new option to selected options.
    return handleOptionClick(newOption);
  }


  const handleTagRemove = (option: Option) => {
    const newOptions = selectedOptions.filter((selectedOption) => selectedOption !== option);
    onChange(newOptions);
  };

  /* START - code to hide dropdown on click outside the component */
  /* also requires this in render code: <div className="className" ref={dropdownRef}> */
  const dropdownRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (e: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setShowOptions(false);
    }
  };

  /* END - code to hide dropdown on click outside the component */

  // Render methods:
  const renderSubLabel = (): any => {
    // Don't show max items notice if user can only select 1 option or
    // they have not reached max item qty.
    const maxItemsNotice = ((
      (1 < maxSelected) &&
      (maxSelected <= selectedOptions.length)
    ) ?
      `Max number selected (${maxSelected})` :
      null
    )

    return (
      // <div className={`formsQuestionSubLabel ${maxItemsNotice || error ? 'error' : ''} footnote`}>
      <div className={`formsQuestionSubLabel ${maxItemsNotice || error ? 'error' : ''} footnote`}>
        {maxItemsNotice || error || subLabel || ''}
      </div>
    );
  }
  const renderSelectedTags = (): any => {
    // Don't show selected tags if user can only select 1 option.
    if (maxSelected === 1) return;
    return (
      <div className="formsTagsWrapper">
        {selectedOptions.map((option) => (
          <div key={option.id} className="formsTag">
            <span>{option.label}</span>
            <button className="formsTagRemoveButton" onClick={() => handleTagRemove(option)}>
              &times;
            </button>
          </div>
        ))}
      </div>
    );
  }

  return (
    // CASEY TODO - deactivate options when max number selected.
    <div className="formsTextInputWrapper">
      <div className="formsQuestionLabel labelSmall" >
        {label}
      </div>
      {renderSubLabel()}
      {renderSelectedTags()}
      <div className="formsDropdown" ref={dropdownRef}>
        <input
          type="text"
          className="formsTextInput labelLarge"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onFocus={() => setShowOptions(true)}
          // if can only select 1 option, save selected option on exit input.
          onBlur={(e) => handleBlur(e.target.value)}
          placeholder={addOptions ? "Search keyword(s) or enter your own" : "Search keyword(s)"}
          onKeyDown={handleKeyDown}
        />
        <div className='formsIconWrapper'>
          <svg className={`formsDropdownIcon ${showOptions ? 'defaultIcon' : 'activeIcon'}`} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 21L17.5001 17.5M20 11.5C20 16.1944 16.1944 20 11.5 20C6.80558 20 3 16.1944 3 11.5C3 6.80558 6.80558 3 11.5 3C16.1944 3 20 6.80558 20 11.5Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>

        {showOptions && filteredOptions.length > 0 && (
          <ul className="formsDropdownList labelLarge">
            {filteredOptions.map((filteredOption) => (
              <li
                key={filteredOption.id}
                className="formsDropdownListItem"
                onClick={() => handleAddSelectedOption(filteredOption)}
              >
                {filteredOption.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default CLLSearchableDropdownWTags;