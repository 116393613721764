import { request }  from '../request';
import type { Title } from '../dataTypes/ApiDataTypes';


interface GetTitlesApiResponse {
  data: Title[] | null;
  status: number;
  statusMessage: string;
};


const getTitles = async function(): Promise<GetTitlesApiResponse> {
  const endpoint = 'titles/';
  const method = 'GET';
  const body = {};
  const headers = {};
  const response: GetTitlesApiResponse = await request(method, endpoint, body, headers);
  return response
};

const Titles = {
  get: getTitles,
  // post
  // put
  // delete
}

export default Titles;


