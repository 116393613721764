import React from 'react';
import CLLInput from './CLLInput/CLLInput';

interface OnChangeFunction {
	/* return errorMessage. if no errorMessage return empty string '' */
    (value: any, hasActiveError?: boolean): any;
}
interface ValidationFunction {
	/* return errorMessage. if no errorMessage return empty string '' */
    (value: any, initialValue: any): string;
}


interface InputValues {
  onChange: OnChangeFunction;
  label?: string;
  // If isError = true, the input box border is red.
  isError?: boolean;
  hideErrors?: boolean;
}

const CLLPasswordBox = (props: InputValues) => {
  const {
    onChange,
    label,
    // If isError = true, the input box border is red.
    isError,
    hideErrors
	} = props;

  const runValidation = (value: string, initialValue: any): string => {
    /* Don't show errors if hideErrors = true */
    if (hideErrors) return '';

    /* Don't show error, if value is initial value */
    if (value === initialValue) return '';

    /* Run validation function to check for errors if validate is passed */
    let errorMessage = '';
    // if (validate) {
    //   errorMessage = validate(value, initialValue);
    // }

    /* Check that password meets strength requirements. */
    const punctuation = '!, ?, /, ~';
    const numbers = [...Array(10).keys()];

    errorMessage = (value.length >= 8) ? errorMessage : 'Password must be at least 8 chars in length.';
    errorMessage = (
      (numbers.some((num) => (value.includes(`${num}`)))) ?
      errorMessage :
      'Password must contain at least one number.'
    );
    errorMessage = (
      (punctuation.split(',')).some((punc) => value.includes(punc)) ?
      errorMessage :
      `Password must contain at least one of the following characters: ${punctuation}.`
    );


    /* if one or more rule is not met and is thus assigned a
    value of false return the error message */
    return errorMessage;
  };


  return (
    <>
      <CLLInput
        label={label || "Password"}
        onChange={onChange}
        validate={runValidation}
        placeholder="Password (8+ characters)"
        maxLength={40}
        isError={!!isError}
        autoCapitalize="none"
        contentType="password"
      />
    </>
  );
}

export default CLLPasswordBox;
