import Navbar from '../../common/Navbar/Navbar';
import ModeToggler from '../../common/ModeToggler/ModeToggler';
import AboutSection from './AboutSection/AboutSection';
import CoverLetterModal from './CoverLetterModal/CoverLetterModal';
import PropSaveTime from './ValueProps/PropSaveTime';
import PropBeatBots from './ValueProps/PropBeatBots';
import PropNetwork from './ValueProps/PropNetwork';
import Logo from '../../common/Logo/Logo';
import PropWowHiring from './ValueProps/PropWowHiring';
import '../../globalStyles/responsiveLayout/containers.css';
import '../../globalStyles/responsiveLayout/spacers.css';
import '../../globalStyles/colors/classes.css';
import './home.css';
import Footer from '../../common/Footer/Footer';


const Home = () => {
  return (
    <div className='contentContainer outer'>
      <Navbar />
      <div className='contentContainer inner'> 
          <div className='spacerBtwnSections' />
          <AboutSection />
          <div className='spacerBtwnSections' />
      </div>
      <div className='fullWidthWrapper'>
        <div className='contentContainer inner'>
            <div id="home-cover-letter-generator">
              <CoverLetterModal   />  
            </div>
        </div>
      </div>
        <div className='contentContainer inner'>
          <div className='spacerBtwnSections' />
          <div className='spacerBtwnSections' />
          <div className='gridContainer' >
            <div id="home-why-resumakr" className='gridItem oneWhole displayLarge txtColor--textPrimary whySection--header'>
              <span className="homeWhySection-whyMargin">Get hired faster with</span><div className="home-whySection--textGroup"><Logo className='whySection--logo'/></div>
            </div>
            <div className='gridItem oneHalf' >
              <PropSaveTime />
            </div>
            <div className='gridItem oneHalf' >
              <PropBeatBots />
            </div>
            <div className='gridItem oneHalf' >
              <PropNetwork />
            </div>
            <div className='gridItem oneHalf' >
              <PropWowHiring />
            </div>
          </div>
      </div>
      {/* <ModeToggler /> */}
      <Footer />
    </div>
  );
};

export default Home;

