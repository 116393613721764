import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import './textEditor.css'

export interface OnContentChangeFunction {
  (userEditedDraft: string): void
}

export interface OnContentDownloadFunction {
  (): any
}

interface EditorProps {
  initContent?: string;
  readOnly?: boolean;
  onContentChange: OnContentChangeFunction;
  onContentDownload?: OnContentDownloadFunction;
}

const TextEditor = (props: EditorProps) => {
  const { initContent, readOnly, onContentChange, onContentDownload } = props;
  const initEditorRefCurrent: any = null;
  let editorRef = useRef(initEditorRefCurrent);

  const disabled = (readOnly === undefined)? false : readOnly;

  // const log = () => {
  //   if (editorRef.current) {
  //     console.log(editorRef.current.getContent());
  //   }
  // };
  const copyAll = (editor: any) => {
    if (onContentDownload) onContentDownload();
    // add tracking
    editor.execCommand('SelectAll', true, '');
    editor.execCommand('Copy', true, '');

  };
  const downloadFile = () => {
    if (onContentDownload) onContentDownload();
    // add tracking
    var header = "<html xmlns:o='urn:schemas-microsoft-com:office:office' "+
         "xmlns:w='urn:schemas-microsoft-com:office:word' "+
         "xmlns='http://www.w3.org/TR/REC-html40'>"+
         "<head><meta charset='utf-8'></head><body>";
    var footer = "<body></html>";
    let el = '';
    if (editorRef.current) {
      el = editorRef.current.getContent();
    }
    var sourceHTML = header + el + footer;
    var source = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(sourceHTML);
    var fileDownload = document.createElement("a");
    document.body.appendChild(fileDownload);
    fileDownload.href = source;
    fileDownload.download = 'coverletter.doc';
    fileDownload.click();
    document.body.removeChild(fileDownload);
  };

  const setup = (editor: any) =>  {
    editor.ui.registry.addButton('copyall', {
      icon: 'copy',
      tooltip: 'Copy All Text',
      onAction: () => copyAll(editor)
    });
    editor.ui.registry.addButton('downloadword', {
      icon: 'export',
      tooltip: 'Download File',
      onAction: () => downloadFile()
    });
  }
  return (
    <div className='textEditor--wrapper'>
      <Editor
        /* disabled true sets read only */
        disabled={disabled}
        id="textEditorContent"
        /* all of their examples are unprotected so I don't think this has to be an env var */
        apiKey='6946quu8z5wal16dt104vgmlj47ua3sqtjtlk2agm6ewtpeu'
        onInit={(evt, editor) => editorRef.current = editor}
        onEditorChange={ () => onContentChange(editorRef.current.getContent()) }
        initialValue={`<p>${initContent || 'This is the initial content of the editor.'}</p>`}
        init={{
          // promotion hides a tinymce upgrade button
          promotion: false,
          height: 500,
          menu: {
            file: { title: 'File', items: 'downloadword' },
            edit: { title: 'Edit', items: 'undo redo | copyall | searchreplace' },
            view: { title: 'View', items: 'fullscreen' },
            insert: { title: 'Insert', items: 'link | hr pagebreak nonbreaking anchor toc ' },
            format: { title: 'Format', items: 'bold italic underline| forecolor | formats blockformats fontsizes align lineheight | removeformat' },
            tools: { title: 'Tools', items: 'code wordcount' },
            help: { title: 'Help', items: 'help' }
          },
          plugins: [
            'lists', 'link', 'pagebreak', 'wordcount', 'fullscreen',
            'searchreplace', 'preview',
          ],
          toolbar:
            'undo redo | copyall | downloadword | fontsize | bold |  ' +
            'bullist outdent indent | ' +
            'alignleft alignright aligncenter'
          ,
          setup: (editor) => { setup(editor) },
          content_style: "body { font-family: Arial,sans-serif; font-size: 12pt; line-height: 1.5  }"
        }}
      />
      {/* <button onClick={log}>Log editor content</button> */}
    </div>
  );

};


export default TextEditor;




