import { useState, useEffect } from 'react';
import CLLErrorMessage from '../CLLErrorMessage/CLLErrorMessage';
import BasicButton from '../../common/BasicButton/BasicButton';
import SkillsSelector from '../SkillsSelector/SkillsSelector';
import { useAppDispatch, useAppSelector } from '../../../state/redux/hooks';
import './skillsForm.css';

interface OnChangeFunction {
    /* return errorMessage. if no errorMessage return empty string '' */
    (value: any, hasActiveError?: boolean): any;
}

interface OnSubmitFunction {
    (): any;
}

interface SkillsFormProps {
    maxOptions?: number;
    minOptions?: number;
    onSubmit?: () => void;
    submitLabel?: string;
    selectedSkills: string[];
    onSkillsChange: (selectedSkills: string[]) => void;
    onBackButtonClick: () => void;
    onNextButtonClick: () => void;
}

const SkillsForm: React.FC<SkillsFormProps> = ({
    maxOptions,
    minOptions,
    onSubmit,
    submitLabel,
    selectedSkills,
    onSkillsChange,
    onBackButtonClick,
    onNextButtonClick,
}) => {
    const remainingOptions = maxOptions ? selectedSkills.slice(maxOptions) : [];
    

    const handleSkillsChange = (selectedSkills: string[]) => {
        onSkillsChange(selectedSkills);
    };

    const submitDisabled = selectedSkills.length === 0;

    useEffect(() => {
        const onKeyDown = (event: KeyboardEvent) => {
          if (event.key === 'Enter' && !submitDisabled) {
            onNextButtonClick();
          }
        };

        document.addEventListener('keydown', onKeyDown);

        return () => {
            document.removeEventListener('keydown', onKeyDown);
        };
    }, [submitDisabled, onNextButtonClick]);

    return (
        <>
            <div className='signInFormWrapper'>
                <div className='signInFormHeader headlineLarge'>Professional Skills</div>
                <div className='signInFormSubheader bodyLarge'>
                    Speak to the organization's deepest needs by selecting from a list of top skills for this job description.
                </div>
                <br></br>
                <SkillsSelector
                    id='relevantSkills'
                    label='Relevant Skills'
                    subLabel={maxOptions && selectedSkills.length >= maxOptions ? `Max number of skills selected (${maxOptions})` : `Please select up to ${maxOptions} skills from the options provided`}
                    options={[
                        { value: 'Figma', label: 'Figma', id: 'figma' },
                        { value: 'Python', label: 'Python', id: 'python' },
                        { value: 'Javascript', label: 'Javascript', id: 'javascript' },
                        { value: 'SQL', label: 'SQL', id: 'sql' },
                        { value: 'User Personas', label: 'User Personas', id: 'userPersonas' },
                        { value: 'Excel', label: 'Excel', id: 'excel' },
                        { value: 'Sketch', label: 'Sketch', id: 'sketch' },
                        { value: 'Business Strategy', id: 'businessStrategy', label: 'Business Strategy' },
                        { value: 'Roadmaps', id: 'roadmaps', label: 'Roadmaps' },
                        { value: 'Market Research', id: 'marketResearch', label: 'Market Research' },
                        { value: 'Technical Writing', id: 'technicalWriting', label: 'Technical Writing' },
                        { value: 'User Research', id: 'userResearch', label: 'User Research' },
                        { value: 'Operations & Strategy', id: 'operations&Strategy', label: 'Operations & Strategy' },
                        { value: 'Marketing', id: 'marketing', label: 'Marketing' },
                        { value: 'Finance, Legal & Compliance', id: 'financeLegalCompliance', label: 'Finance, Legal & Compliance' },
                        { value: 'Design', id: 'design', label: 'Design' },
                        { value: 'Product', id: 'product', label: 'Product' },
                        { value: 'Data', id: 'data', label: 'Data' },
                        { value: 'Security', id: 'security', label: 'Security' },
                    ]}
                    onChange={handleSkillsChange}
                    maxOptions={maxOptions}
                    minOptions={minOptions}
                    disabledOptions={remainingOptions}
                    selectedOptions={selectedSkills}
                />
                <br></br>
                <br></br>
                <div className='buttonWrapper'>
                    <BasicButton
                        label='Back'
                        onClick={onBackButtonClick}
                        className='grow'
                        type='secondary'
                    />
                    <BasicButton
                        label={submitLabel || 'Next'}
                        onClick={onNextButtonClick}
                        className='grow'
                        type='primary'
                        disabled={submitDisabled}
                    />
                </div>
            </div>
        </>
    );
};

export default SkillsForm;
