import React, { useState, useEffect, useRef } from 'react';
import './cllDropdown.css';
import '../../globalStyles/fonts/classes.css';
import '../../globalStyles/forms/classes.css';
import '../../globalStyles/colors/vars.css';
import { Function } from '../../../typescriptTypes';
import { Plus, Trash } from '../../common/IconLibrary/IconLibrary';

export type Option = {
	id: number | string | null;
	label: string | undefined;
	formattedStartDate?: Date;
}

const initSelectedOption: Option = {
	id: null,
	label: undefined,
};

interface CLLDropdownProps {
	label: string;
	options: Option[];
	subLabel: string;
	onChange: Function;
	selectedOption?: string;
	showAddPositionsButton?: boolean;
	onAddPosition?: () => void; // Callback function for adding a position
	onDeletePosition?: (option: Option) => void;
	onUpdateOptions?: (updatedOptions: Option[]) => void;
}

const CLLDropdown = (props: CLLDropdownProps): JSX.Element => {
	const {
		label,
		options,
		subLabel,
		onChange,
		selectedOption,
		onAddPosition,
		showAddPositionsButton,
		onDeletePosition,
		onUpdateOptions,
	} = props;
	const [selectedOptionState, setSelectedOptionState] = useState<Option>(initSelectedOption);
	const [showOptions, setShowOptions] = useState(false);
	const [selectedOptionId, setSelectedOptionId] = useState<number | string | null>(null);
	const [positions, setPositions] = useState<Option[]>(options);
	const [deletedPositions, setDeletedPositions] = useState<Option[]>([]);



	const handleOptionClick = (option: Option) => {
		if (option.id === selectedOptionId) {
		  onChange(initSelectedOption); // Clear the selected option if it's clicked again
		  setSelectedOptionState(initSelectedOption);
		  setSelectedOptionId(null);
		} else {
		  onChange(option);
		  setSelectedOptionState(option);
		  setSelectedOptionId(option.id);
		}
		setShowOptions(false);
	};


	const handleButtonClick = () => {
		setShowOptions(!showOptions);
	};

	/* Code to hide dropdown on click outside the component */
	const dropdownRef = useRef<HTMLDivElement>(null);
	const handleClickOutside = (e: any) => {
		if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
			setShowOptions(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, []);
	/* End of code to hide dropdown on click outside the component */

	const handleAddPosition = () => {
		if (onAddPosition) {
		  setShowOptions(false); // Minimize the dropdown menu
		  onAddPosition();
		}
	};

	const handleDeletePosition = (option: Option) => {
		if (selectedOptionState.id === option.id) {
		  setSelectedOptionState(initSelectedOption);
		  setSelectedOptionId(null);
		}

		const filteredPositions = positions.filter((pos) => pos.id !== option.id);
		const filteredDeletedPositions = deletedPositions.filter((pos) => pos.id !== option.id);

		setPositions(filteredPositions);
		setDeletedPositions(filteredDeletedPositions);

		if (onDeletePosition) {
		  onDeletePosition(option);
		}
	};






	const renderSubLabel = (): JSX.Element | null => {
		return (
			<div className={`formsQuestionSubLabel footnote ${subLabel ? '' : 'hide'}`}>
				{subLabel || ''}
			</div>
		);
	};

	return (
		<div className='formsTextInputWrapper'>
			<div className='formsQuestionLabel labelSmall'>{label}</div>
			{renderSubLabel()}
			<div className='formsDropdown' ref={dropdownRef}>
				<button
					className={`formsDropdownButton ${selectedOptionState.label ? '' : 'placeholder'
						} labelLarge ${selectedOptionState.id ? 'selected' : ''}`}
					onClick={handleButtonClick}
				>
					{selectedOptionState.label || 'Select an option'}
					<svg
						className={`formsDropdownIcon ${showOptions ? 'show' : 'hide'}`} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M18 15L12 9L6 15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>

				</button>
				{showOptions && (
					<ul className='formsDropdownList'>
						{showAddPositionsButton && (
							<>
								<li
									className='labelLarge addPositionButtonWrapper'
									onClick={handleAddPosition}
								>
									<Plus />
									Add Position
								</li>
							</>
						)}
						{options.map((option) => {
							// Exclude the deleted positions from rendering
							if (deletedPositions.some((pos) => pos.id === option.id)) {
								return null;
							}

							return (
							<div className='formsDropdownListItemWrapper labelLarge'>
								<li
									key={option.id}
									className={`formsDropdownListItem positionsWrapper ${selectedOptionState.id === option.id ? 'selected' : ''
										}`}
									onClick={() => handleOptionClick(option)}
								>
									{option.label}
								</li>
								{showAddPositionsButton && (
									<div className='trashIconWrapper' onClick={() => handleDeletePosition(option)}>
										<Trash />
									</div>
								)}
							</div>
							);
						})}
					</ul>
				)}
			</div>
		</div>
	);
};

export default CLLDropdown;
