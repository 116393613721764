import React, {useState} from 'react';

import './cllErrorMessage.css';
import '../../globalStyles/fonts/classes.css';
import '../../globalStyles/colors/classes.css';
import '../../globalStyles/responsiveLayout/spacers.css'

interface Input {
  errorMessage: string;
}

const CLLErrorMessage= (props: Input) => {
  const { errorMessage } = props;

  return (
    <>
        <div className='spacerVertical' />
        <div className='formsQuestionSubLabel error footnote'>
            {errorMessage}
        </div>
        <div className='spacerVertical' />
    </>
  );
};

export default CLLErrorMessage;
