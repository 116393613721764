import React from 'react';
import BasicButton from '../../common/BasicButton/BasicButton';
import './successModal.css';
import '../../globalStyles/fonts/classes.css';
import '../../globalStyles/forms/classes.css';

interface Props {
    onClose: () => void;
    onReopenPositionsModal: () => void;
}

const SuccessModal: React.FC<Props> = ({ onClose, onReopenPositionsModal }) => {
    const handleDoneClick = () => {
        // Call the onClose function to close the modals
        onClose();
    };

    const handleReopenClick = () => {
        // Call the onReopenPositionsModal function to re-open the PositionsModal
        onReopenPositionsModal();
    };

    return (
        <div className="successModalWrapper">
            <div className="signInFormHeader headlineLarge">Success!</div>
            <div className="signInFormSubheader bodyLarge">
                Your position has been saved successfully.
            </div>
            <br />
            <div className='buttonWrapper'>
                <BasicButton
                    className="grow"
                    type="secondary"
                    label="Add another"
                    onClick={handleReopenClick}
                />
                <BasicButton
                    className="grow"
                    type="primary"
                    label="Done"
                    onClick={handleDoneClick}
                />
            </div>
        </div>
    );
};

export default SuccessModal;
