import React, { useState, useEffect, useRef } from 'react';
import './cllSearchableDropdown.css';
import '../../globalStyles/fonts/classes.css';
import '../../globalStyles/forms/classes.css';
import '../../globalStyles/colors/vars.css';
import { Function } from '../../../typescriptTypes';

export interface Option {
  id: number | string | null;
  label: string;
}

const CLLSearchableDropdown = (props:
  {
    label: string,
    subLabel: string,
    input: string,
    /* drop down options */
    options: Option[],
    onChange: Function,

    /* Add options allows user to add and select
    new option if it does not exist in options list
    default = false*/
    addOptions?: boolean,
  }
): any => {
  // component props:
  const {
    label,
    options,
    subLabel,
    input,
    onChange,
    addOptions } = props;


  // component state - FORM FUNCTIONALITY
  const [showOptions, setShowOptions] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    // component did mount:
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      // component will unmount:
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  // Reg methods:
  const filteredOptions = options.filter(
    (option) =>
      option.label.toLowerCase().includes(input.toLowerCase())
  );

  // const getOptionFromList = (label: string) => {
  //   const optionsWithMatchingLabels = options.filter((option) => option.label === label)
  //   return optionsWithMatchingLabels.length > 0 ? optionsWithMatchingLabels[0] : null;
  // }

  const handleKeyDown = (event: any) => {
    // Do nothing if enter key was not clicked.
    const value = event.target.value;
    if (event.key === 'Enter') return handleOptionEnter(value);
    if (event.key === 'Tab') return setShowOptions(false);
  }
  const handleOptionEnter = (value: string) => {
  }

  const handleOptionClick = (value: string) => {
    onChange(value);
    setShowOptions(false);
  }



  /* START - code to hide dropdown on click outside the component */
  /* also requires this in render code: <div className="className" ref={dropdownRef}> */
  const dropdownRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (e: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setShowOptions(false);
    }
  };

  /* END - code to hide dropdown on click outside the component */

  // Render methods:
  const renderSubLabel = (): any => {

    return (
      <div className={`formsQuestionSubLabel ${error ? 'error' : ''} footnote`}>
        {error || subLabel || ''}
      </div>
    );
  }


  return (
    // CASEY TODO - deactivate options when max number selected.
    <div className="formsTextInputWrapper">
      <div className="formsQuestionLabel labelSmall" >
        {label}
      </div>
      {renderSubLabel()}
      <div className="formsDropdown" ref={dropdownRef}>
        <input
          type="text"
          className="formsTextInput labelLarge"
          value={input}
          onChange={(e) => onChange(e.target.value)}
          onFocus={() => setShowOptions(true)}
          // if can only select 1 option, save selected option on exit input.
          onBlur={(e) => onChange(e.target.value)}
          placeholder={addOptions ? "Search keyword(s) or enter your own" : "Search keyword(s)"}
          onKeyDown={handleKeyDown}
        />
        <div className='formsIconWrapper'>
          <svg className={`formsDropdownIcon ${showOptions ? 'defaultIcon' : 'activeIcon'}`} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 21L17.5001 17.5M20 11.5C20 16.1944 16.1944 20 11.5 20C6.80558 20 3 16.1944 3 11.5C3 6.80558 6.80558 3 11.5 3C16.1944 3 20 6.80558 20 11.5Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>

        {showOptions && filteredOptions.length > 0 && (
          <ul className="formsDropdownList labelLarge">
            {filteredOptions.map((filteredOption) => (
              <li
                key={filteredOption.id}
                className="formsDropdownListItem"
                onClick={() => handleOptionClick(filteredOption.label)}
              >
                {filteredOption.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default CLLSearchableDropdown;