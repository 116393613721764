import React from 'react';
import colorModeActions from '../../../state/redux/data/colorMode/colorModeActionCreator';
import { useAppSelector, useAppDispatch } from '../../../state/redux/hooks';
import './modeToggler.css';


const ModeToggler = () => {
  // Get redux state:
  const isLightMode = useAppSelector(state => state.colorMode.isLightMode);
  // Set up functions to update state in redux:
  const dispatch = useAppDispatch();
  const updateIsLightMode = (newState: boolean) => dispatch(colorModeActions.updateIsLightMode(newState));


  const toggleMode = () => {
    // const { updateIsLightMode, colorMode } = this.props;
    const newState = !isLightMode;
    // Update state and css color theme:
    updateIsLightMode(newState);
  }

  return (
    <div className="modeTogglerOuter dark">
      <input
        className="modeTogglerInner"
        id="toggleColorMode"
        onChange={() => toggleMode()}
        type="checkbox"
        checked={isLightMode}
        name="mode"
      />
      <label htmlFor="toggleColorMode" className="modeTogglerLabel">Toggle</label>
    </div>
  );
  
}
  
export default ModeToggler;
  