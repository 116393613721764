import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { Question } from '../../../apiServices/dataTypes/Question';
import type { Answer } from '../../../apiServices/dataTypes/Answer';
// import { RootState } from '../../store';

interface QA {
  question: Question;
  answer: Answer;
}

// Define intiial state
interface QuestionsState {
  data: QA[] | [];
  status: 'pending' | 'failure' | 'success' | 'success';
  errorMessage: string | null;
};
const initialState: QuestionsState = {
  data: [],
  errorMessage: null,
  status: 'success'
};

// Define slice using intitial state
const appQuestionsSlice = createSlice({
  name: 'appQuestions',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    signOut: (prevState) => {
      return initialState;
    },
    setQuestionsStarted: (prevState) => {
      let state = prevState;
      state.errorMessage = null;
      state.status = 'pending';
      return state;
    },
    setQuestionsFailed: (prevState) => {
      let state = prevState;
      state.errorMessage = 'Failed to connect to server.';
      state.status = 'failure';
      return state;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    setQuestionsSucceeded: (prevState, action: PayloadAction<QuestionsState>) => {
      return action.payload;
    },
  },
});

export default appQuestionsSlice;

// Other code such as selectors can use the imported `RootState` type
// export const selectAuthState = (state: RootState) => state.auth;

