import authSlice from "./authSlice";
import signIn from '../../../apiServices/endpoints/signIn';
import signUp from '../../../apiServices/endpoints/signUp'
import { updateStateByAPI } from "../actionCreatorUtils";
import {
  setLocalAPIData,
  clearLocalAPIData,
  getLocalAPIData } from "../../../localStorage/apiData";
import { ApiResponse } from "../../../apiServices/request";


// the outside "thunk creator" function
const signInAction = (email: string, password: string) => {
  // the inside "thunk function"
  return async (dispatch: any, getState: any) => {
    // update status to pending so loaders start.
    updateStateByAPI(
      dispatch,
      async() => await setLocalAPIData('auth', async() => await signIn(
        email, password)),
      authSlice.actions.setAuthStarted,
      authSlice.actions.setAuthSucceeded,
      authSlice.actions.setAuthFailed
    );
  }
};


// the outside "thunk creator" function
const signUpAction = (
  email: string,
  password: string) => {
  // the inside "thunk function"
  return async (dispatch: any, getState: any) => {
    // update status to pending so loaders start.
    updateStateByAPI(
      dispatch,
      async() => await setLocalAPIData('auth', async() => await signUp(
        email, password)),
      authSlice.actions.setAuthStarted,
      authSlice.actions.setAuthSucceeded,
      authSlice.actions.setAuthFailed
    );
  }
};

// the outside "thunk creator" function
const signOutAction = () => {
  // the inside "thunk function"
  return async (dispatch: any, getState: any) => {
    dispatch(authSlice.actions.signOut());
    const resp = await clearLocalAPIData('auth');
  }
};

const initAuthAction = () => {
  return async (dispatch: any, getState: any): Promise<ApiResponse>  => {
    const response: ApiResponse = await getLocalAPIData('auth');
    // if auth not set locally,
    // return Api response failure.
    if (!response.data) return {
      data: null,
      status: 500,
      statusMessage: "failure!"
    };
    // if auth is set locally,
    // dispatch to redux state & return Api response success.
    await dispatch(authSlice.actions.setAuthSucceeded({
      data: response.data,
      errorMessage: null,
      status: 'success'
    }));
    return {
      data: response.data,
      status: 200,
      statusMessage: "success!"
    };
  };
}


const authActions = {
    signIn: signInAction,
    signUp: signUpAction,
    initAuth: initAuthAction,
    signOut: signOutAction
}

export default authActions;