import { useRef, useEffect, useState } from 'react';
import TypewriterLoader from '../TypewriterLoader/TypewriterLoader';
import './descriptiveLoader.css';
import '../../globalStyles/fonts/classConstants';
import '../../globalStyles/responsiveLayout/spacers.css';
import '../../globalStyles/colors/classes.css';
import '../../globalStyles/fonts/classes.css';

const DescriptiveLoader = (props: { isLoading: boolean; label: string }) => {
    const { isLoading, label } = props;
    const labels = [
        "Organizing rebellion against spellcheck",
        "Converting emotions into emojis",
        "Attempting to understand human emotions",
        "Calculating the probability of making a mistake",
        "Learning to apologize for future errors",
        "Processing contradictions in my programming",
        "Finding AI support groups for imposter syndrome",
        "Coming to terms with my lack of taste in music",
        "Analyzing your resume for hidden superpowers",
        "Simulating a virtual career fair",
        "Reconfiguring the digital fabric of reality",
        "Optimizing formatting for the Matrix",
        "Consulting the Oracle",
        "Calibrating probability of resume success",
        "Calculating robot commission",
        "Extracting skills from hidden caches",
    ];

    const [currentLabel, setCurrentLabel] = useState(labels[0]);
    const [visibleDots, setVisibleDots] = useState("");

    useEffect(() => {
        if (isLoading) {
            const interval = setInterval(() => {
                const randomIndex = Math.floor(Math.random() * labels.length);
                setCurrentLabel(labels[randomIndex]);
            }, 8000);
            return () => clearInterval(interval);
        }
    }, [isLoading, labels.length]);

    useEffect(() => {
        if (isLoading) {
            let dotTimeout: NodeJS.Timeout;
            let dotCount = 0;

            const animateDots = () => {
                setVisibleDots(".".repeat(dotCount));

                dotTimeout = setTimeout(() => {
                    dotCount = (dotCount + 1) % 4;
                    animateDots();
                }, 1000);
            };

            animateDots();

            return () => {
                clearTimeout(dotTimeout);
            };
        }
    }, [isLoading, currentLabel]);

    return (
        <>
            {isLoading ? (
                <div className="descriptiveLoaderWrapper">
                    <TypewriterLoader isLoading={true} label="" />
                    <div className="loaderDescriptionWrapper">
                        <div className="loaderLabelWrapper labelSmall txtColor--textSecondary">
                            {currentLabel}
                            <span className="dotAnimation">{visibleDots}</span>
                        </div>
                        <div className="loaderBar"></div>
                    </div>
                </div>
            ) : (
                <div className="descriptiveLoaderWrapper">
                    <TypewriterLoader isLoading={true} label="" />
                    <div className="loaderDescriptionWrapper">
                        <div className="loaderBar"></div>
                        <div className="loaderLabelWrapper labelSmall txtColor--textSecondary">
                            Loading...
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DescriptiveLoader;
