import { request }  from '../request';
import type { CoverLetter } from '../dataTypes/ApiDataTypes';


export interface CoverLetterResponse {
  data: CoverLetter | null;
  status: number;
  statusMessage: string;
};


const getCoverLetters = async function(id:number, accessToken:string): Promise<CoverLetterResponse> {
  const endpoint = 'coverletters/';
  const method = 'GET';
  const body = {};
  const headers = {
    'Authorization': `Token ${accessToken}`,
    'id': id
  }
  const response: CoverLetterResponse = await request(method, endpoint, body, headers);
  return response
};

/* create new record */
const postCoverLetter = async function(
  jobDescriptionId: number,
  accessToken: string
): Promise<CoverLetterResponse> {
  const endpoint = 'coverletters/';
  const method = 'POST';
  const body = {
    'job-description-id': jobDescriptionId
  }
  const headers = {
    'Authorization': `Token ${accessToken}`
  }
  const response: CoverLetterResponse = await request(method, endpoint, body, headers);
  return response
};

/* update record */
const putCoverLetter = async function(
  accessToken: string,
  id: number,
  userEditedDraft?: string,
  satisfactionRating?: 'BAD' | 'OK' | 'GOOD'
): Promise<CoverLetterResponse> {
  const endpoint = 'coverletters/';
  const method = 'PUT';
  const body = {
    'id': id,
    'user-edited-draft': userEditedDraft || null,
    'satisfaction-rating': satisfactionRating || null
  }
  const headers = {
    'Authorization': `Token ${accessToken}`
  }
  const response: CoverLetterResponse = await request(method, endpoint, body, headers);
  return response
};


const CoverLetters = {
  get: getCoverLetters,
  post: postCoverLetter,
  put: putCoverLetter
  // delete
}

export default CoverLetters;


