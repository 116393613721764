import React from 'react';
// import logoLightMode from './logo-lightmode.svg';
// import logoDarkMode from './logo-darkmode.svg';
// import { useAppSelector } from '../../state/redux/hooks';
import './logo.css'


function Logo(props: {className: string}) {
  const { className } = props;
  // // Get State 
  // const isLightMode = useAppSelector(state => state.colorMode.isLightMode);
  // const logo = isLightMode ? logoLightMode : logoDarkMode;
  
  return (
      <div >
        <svg width="129" viewBox="0 0 129 22" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
        <path d="M4.41225 21.0001H0.15625V1.45605H9.42425C10.3203 1.45605 11.1323 1.60539 11.8603 1.90405C12.5883 2.20272 13.2043 2.63206 13.7083 3.19205C14.2309 3.73339 14.6323 4.38672 14.9123 5.15205C15.1923 5.91739 15.3323 6.76672 15.3323 7.70005C15.3323 9.02539 15.0336 10.1827 14.4363 11.1721C13.8576 12.1614 12.9709 12.8801 11.7762 13.3281L15.6122 21.0001H10.8802L7.46425 13.8321H4.41225V21.0001ZM8.89225 10.2481C9.52692 10.2481 10.0216 10.0894 10.3763 9.77205C10.7496 9.43605 10.9363 8.95072 10.9363 8.31606V7.08405C10.9363 6.44939 10.7496 5.97339 10.3763 5.65605C10.0216 5.32005 9.52692 5.15205 8.89225 5.15205H4.41225V10.2481H8.89225Z" className="primaryFill"/>
        <path d="M24.5213 21.3361C23.4013 21.3361 22.4026 21.1587 21.5253 20.8041C20.6479 20.4307 19.9013 19.9174 19.2853 19.2641C18.6879 18.5921 18.2306 17.7801 17.9133 16.8281C17.6146 15.8761 17.4653 14.8121 17.4653 13.6361C17.4653 12.4787 17.6146 11.4334 17.9133 10.5001C18.2119 9.54806 18.6506 8.73605 19.2293 8.06405C19.8266 7.39205 20.5546 6.87872 21.4133 6.52405C22.2719 6.15072 23.2519 5.96406 24.3533 5.96406C25.5666 5.96406 26.6026 6.16939 27.4613 6.58005C28.3386 6.99072 29.0479 7.54139 29.5893 8.23205C30.1493 8.92272 30.5506 9.72539 30.7933 10.6401C31.0546 11.5361 31.1853 12.4787 31.1853 13.4681V14.7001H21.7493V14.9241C21.7493 15.8947 22.0106 16.6694 22.5333 17.2481C23.0559 17.8081 23.8773 18.0881 24.9973 18.0881C25.8559 18.0881 26.5559 17.9201 27.0973 17.5841C27.6386 17.2294 28.1426 16.8094 28.6093 16.3241L30.6813 18.9001C30.0279 19.6654 29.1693 20.2627 28.1053 20.6921C27.0599 21.1214 25.8653 21.3361 24.5213 21.3361ZM24.4373 9.01605C23.5973 9.01605 22.9346 9.29605 22.4493 9.85605C21.9826 10.3974 21.7493 11.1254 21.7493 12.0401V12.2641H26.9013V12.0121C26.9013 11.1161 26.6959 10.3974 26.2853 9.85605C25.8933 9.29605 25.2773 9.01605 24.4373 9.01605Z" className="primaryFill"/>
        <path d="M39.0959 21.3361C37.6773 21.3361 36.4826 21.1121 35.5119 20.6641C34.5413 20.1974 33.6733 19.5441 32.9079 18.7041L35.3439 16.2401C35.8666 16.8187 36.4453 17.2761 37.0799 17.6121C37.7146 17.9481 38.4333 18.1161 39.2359 18.1161C40.0573 18.1161 40.6266 17.9947 40.9439 17.7521C41.2613 17.4907 41.4199 17.1454 41.4199 16.7161C41.4199 16.0254 40.9253 15.6147 39.9359 15.4841L38.3399 15.2881C35.0359 14.8774 33.3839 13.3654 33.3839 10.7521C33.3839 10.0427 33.5146 9.39872 33.7759 8.82006C34.0559 8.22272 34.4386 7.71872 34.9239 7.30805C35.4279 6.87872 36.0253 6.55205 36.7159 6.32805C37.4253 6.08539 38.2186 5.96406 39.0959 5.96406C39.8613 5.96406 40.5333 6.02006 41.1119 6.13206C41.7093 6.22539 42.2319 6.37472 42.6799 6.58005C43.1466 6.76672 43.5666 7.00939 43.9399 7.30805C44.3319 7.58805 44.7146 7.90539 45.0879 8.26006L42.7079 10.6961C42.2413 10.2294 41.7093 9.86539 41.1119 9.60406C40.5333 9.32406 39.9359 9.18406 39.3199 9.18406C38.6106 9.18406 38.1066 9.29605 37.8079 9.52005C37.5093 9.74405 37.3599 10.0427 37.3599 10.4161C37.3599 10.8081 37.4626 11.1161 37.6679 11.3401C37.8919 11.5454 38.3026 11.6947 38.8999 11.7881L40.5519 12.0121C43.7813 12.4414 45.3959 13.9161 45.3959 16.4361C45.3959 17.1454 45.2466 17.7987 44.9479 18.3961C44.6493 18.9934 44.2199 19.5161 43.6599 19.9641C43.1186 20.3934 42.4559 20.7294 41.6719 20.9721C40.9066 21.2147 40.0479 21.3361 39.0959 21.3361Z" className="primaryFill"/>
        <path d="M56.9013 18.5361H56.7613C56.6306 18.9094 56.4533 19.2641 56.2293 19.6001C56.024 19.9361 55.7533 20.2347 55.4173 20.4961C55.0813 20.7574 54.68 20.9627 54.2133 21.1121C53.7653 21.2614 53.2426 21.3361 52.6453 21.3361C51.1333 21.3361 49.9853 20.8414 49.2013 19.8521C48.436 18.8441 48.0533 17.4067 48.0533 15.5401V6.30005H52.1973V15.1761C52.1973 16.0721 52.356 16.7627 52.6733 17.2481C52.9906 17.7334 53.532 17.9761 54.2973 17.9761C54.6146 17.9761 54.932 17.9294 55.2493 17.8361C55.5666 17.7427 55.8466 17.6121 56.0893 17.4441C56.332 17.2574 56.528 17.0334 56.6773 16.7721C56.8266 16.5107 56.9013 16.2027 56.9013 15.8481V6.30005H61.0453V21.0001H56.9013V18.5361Z" className="primaryFill"/>
        <path d="M62.9316 21.0003V6.30027H67.0756V8.79227H67.2436C67.505 8.00827 67.953 7.34561 68.5876 6.80427C69.2223 6.24427 70.0903 5.96427 71.1916 5.96427C72.1996 5.96427 73.0583 6.21627 73.7676 6.72027C74.477 7.22427 74.9903 7.96161 75.3076 8.93227H75.3916C75.5223 8.52161 75.7183 8.13894 75.9796 7.78427C76.2596 7.41094 76.5956 7.09361 76.9876 6.83227C77.3796 6.57094 77.8183 6.36561 78.3036 6.21627C78.789 6.04827 79.3116 5.96427 79.8716 5.96427C81.2903 5.96427 82.373 6.46827 83.1196 7.47627C83.885 8.48427 84.2676 9.92161 84.2676 11.7883V21.0003H80.1236V12.1243C80.1236 10.2576 79.4516 9.32427 78.1076 9.32427C77.4916 9.32427 76.9316 9.50161 76.4276 9.85627C75.9236 10.2109 75.6716 10.7523 75.6716 11.4803V21.0003H71.5276V12.1243C71.5276 10.2576 70.8556 9.32427 69.5116 9.32427C69.213 9.32427 68.9143 9.37094 68.6156 9.46427C68.3356 9.53894 68.0743 9.66961 67.8316 9.85627C67.6076 10.0429 67.421 10.2763 67.2716 10.5563C67.141 10.8176 67.0756 11.1256 67.0756 11.4803V21.0003H62.9316Z" className="brandFill"/>
        <path d="M98.8152 21.0003C98.0685 21.0003 97.4432 20.7669 96.9392 20.3003C96.4538 19.8336 96.1458 19.2083 96.0152 18.4243H95.8472C95.6232 19.3763 95.1285 20.1043 94.3632 20.6083C93.5978 21.0936 92.6552 21.3363 91.5352 21.3363C90.0605 21.3363 88.9312 20.9443 88.1472 20.1603C87.3632 19.3763 86.9712 18.3403 86.9712 17.0523C86.9712 15.5029 87.5312 14.3549 88.6512 13.6083C89.7898 12.8429 91.3298 12.4603 93.2712 12.4603H95.5952V11.5363C95.5952 10.8269 95.4085 10.2669 95.0352 9.85627C94.6618 9.42694 94.0365 9.21227 93.1592 9.21227C92.3378 9.21227 91.6845 9.38961 91.1992 9.74427C90.7138 10.0989 90.3125 10.5003 89.9952 10.9483L87.5312 8.76427C88.1285 7.88694 88.8752 7.20561 89.7712 6.72027C90.6858 6.21627 91.9178 5.96427 93.4672 5.96427C95.5578 5.96427 97.1258 6.42161 98.1712 7.33627C99.2165 8.25094 99.7392 9.58561 99.7392 11.3403V17.7803H101.111V21.0003H98.8152ZM93.0752 18.4523C93.7658 18.4523 94.3538 18.3029 94.8392 18.0043C95.3432 17.7056 95.5952 17.2203 95.5952 16.5483V14.8123H93.5792C91.9552 14.8123 91.1432 15.3629 91.1432 16.4643V16.8843C91.1432 17.4256 91.3112 17.8269 91.6472 18.0883C91.9832 18.3309 92.4592 18.4523 93.0752 18.4523Z" className="brandFill"/>
        <path d="M103.893 0.280273H108.037V12.5163H108.205L110.025 9.80027L112.965 6.30027H117.557L112.657 11.9283L118.117 21.0003H113.189L109.885 14.7843L108.037 16.8563V21.0003H103.893V0.280273Z" className="brandFill"/>
        <path d="M120.053 21.0003V6.30027H124.197V9.49227H124.337C124.411 9.08161 124.542 8.68961 124.729 8.31627C124.915 7.92427 125.158 7.57894 125.457 7.28027C125.774 6.98161 126.147 6.74827 126.577 6.58027C127.006 6.39361 127.51 6.30027 128.089 6.30027H128.817V10.1643H127.781C126.567 10.1643 125.662 10.3229 125.065 10.6403C124.486 10.9576 124.197 11.5456 124.197 12.4043V21.0003H120.053Z" className="brandFill"/>
        </svg>
      </div>
  );

};

export default Logo;




